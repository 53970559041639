import { createAsyncThunk } from '@reduxjs/toolkit';
import CategoriesService from 'src/services/CategoriesService';

export const fetchEncumbranceType = createAsyncThunk(
  'info/encumbranceType',
  async (_, thunkAPI) => {
    try {
      return await CategoriesService.getEncumbranceType();
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить данные');
    }
  },
);

export const fetchAllInfo = createAsyncThunk(
  'info/fetchAll',
  async (widthIcon: boolean, thunkAPI) => {
    try {
      const data = await CategoriesService.getAllInfo();
      return { data, widthIcon };
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить данные');
    }
  },
);
