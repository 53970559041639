import { setImportExcelData, setIsExcelUpload } from 'src/store/common';
import { Store } from 'react-notifications-component';
import { BASE_URL } from 'src/config';
import { LatLngExpression } from 'leaflet';
import { DashboardPolygonsFilterType, NoticeType } from 'src/config/types';
import ExcelService from 'src/services/ExcelService';
import proj4 from 'proj4';

export const notice = (data: NoticeType) =>
  Store.addNotification({
    ...data,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });

export const isToday = (date: Date): boolean => {
  const d = new Date();
  return (
    date.getDate() === d.getDate() &&
    date.getMonth() === d.getMonth() &&
    date.getFullYear() === d.getFullYear()
  );
};

export const getSrc = (img: File | string) => {
  if (typeof img === 'string') {
    return `${BASE_URL}${img}`;
  }

  return URL.createObjectURL(img as File);
};

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'kB', 'mB', 'gB', 'tB', 'pB', 'eB', 'zB', 'yB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const declOfNum = (n: number, text_forms: string[]) => {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 == 1) {
    return text_forms[0];
  }
  return text_forms[2];
};

export const isEmpty = obj => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const isFiltersEmpty = obj => !Object.keys(obj).find(key => obj?.[key]?.length);

export const isCustomFilterEmpty = (filter: DashboardPolygonsFilterType) =>
  !filter?.square?.from &&
  !filter?.square?.to &&
  !filter?.price?.to &&
  !filter?.price?.from &&
  !filter?.address &&
  !filter?.cadaster_number;

export const paramsToQuery = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join('&');

export const isMacOs = () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

export const isDark = (colorInHex: string): boolean => (getBrightness(colorInHex) || 0) < 128;

export const getBrightness = (colorInHex: string): number | null => {
  const rgb = hexToRgb(colorInHex);

  if (!rgb) return null;

  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
};

export const hexToRgb = (colorInHex: string): { r: number; g: number; b: number } | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorInHex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const coordsToArray = coords => coords?.map(el => [el?.lat, el?.lng]) || [[]];

export const getCenter = (coords: LatLngExpression[]): LatLngExpression => {
  let x = 0;
  let y = 0;

  coords?.forEach(el => {
    x += el[0];
    y += el[1];
  });

  return [x / coords.length, y / coords.length];
};

export const getCoordsWithLast = coords => {
  return [...coords, coords[0]];
};

export const colonToDash = val => val.toString().replaceAll(':', '-');

export const stringToCoords = (str: string): LatLngExpression => {
  const arr = str.split(',');
  return [+arr?.[0], +arr?.[1]];
};

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator?.['msMaxTouchPoints'] > 0
  );
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return dateString;
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // месяцы от 0 до 11
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const numberWithSpaces = (number: string | number): string => {
  const parts = number.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join('.');
};

export const coordsToGPS = (coords: LatLngExpression): LatLngExpression | undefined => {
  if (!coords[0] || !coords[1]) return;

  return proj4('EPSG:3857', 'EPSG:4326', coords);
};

export const arrHeight = arr => {
  if (arr.filter(i => i.constructor.name === 'Array').length != 0) {
    return 1 + arrHeight([].concat(...arr.filter(i => i.constructor.name === 'Array')));
  } else {
    return 0;
  }
};

export const coordsToWGS84 = (coords: LatLngExpression): LatLngExpression =>
  proj4('EPSG:3857').forward(coords);

export const fetchRetry = (
  url: string,
  delay: number,
  tries: number,
  fetchOptions: RequestInit = {},
): Promise<Response> => {
  const onError = err => {
    const triesLeft = tries - 1;
    if (!triesLeft) {
      throw err;
    }
    return sleep(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions));
  };

  return fetch(url, fetchOptions).catch(onError);
};

export const checkIsCn = (str: string) => !isNaN(+str?.replaceAll(':', '')?.replaceAll('/', ''));

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(String((R * (100 + percent)) / 100));
  G = parseInt(String((G * (100 + percent)) / 100));
  B = parseInt(String((B * (100 + percent)) / 100));

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const checkUploading = async dispatch => {
  try {
    const res = await ExcelService.checkImportLoad();

    if (!res?.data?.shown && res.data?.complete) {
      dispatch(setImportExcelData(res.data));
    }

    if (!res?.data || res.data?.complete) {
      dispatch(setIsExcelUpload(false));
      return;
    }

    dispatch(setIsExcelUpload(true));
    setTimeout(() => {
      checkUploading(dispatch);
    }, 2000);
  } catch (e) {
    console.log(e.message);
  }
};

export const download = (path: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;
  anchor.target = '_blank';

  document.body.appendChild(anchor);

  anchor.click();

  document.body.removeChild(anchor);
};
