import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapState } from 'src/store/map/types';
import { LatLngExpression } from 'leaflet';
import { schemaTileUrl } from 'src/config/data';

const initialState: MapState = {
  mainTileUrl: schemaTileUrl,
  flyTo: null,
  selectedLayers: [2],
  isMobileButtonsOpened: true,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMainTileUrl: (state, action: PayloadAction<string[]>) => {
      state.mainTileUrl = action.payload;
    },
    setFlyTo: (state, action: PayloadAction<LatLngExpression | null>) => {
      state.flyTo = action.payload;
    },
    setSelectedLayers: (state, action: PayloadAction<number[]>) => {
      state.selectedLayers = action.payload;
    },
    setIsMobileButtonsOpened: (state, action: PayloadAction<boolean>) => {
      state.isMobileButtonsOpened = action.payload;
    },
  },
  extraReducers: {},
});

export const { setMainTileUrl, setFlyTo, setSelectedLayers, setIsMobileButtonsOpened } =
  mapSlice.actions;

const reducer = mapSlice.reducer;
export default reducer;
