import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setTooltip } from 'src/store/common';
import cn from 'classnames';

interface AppToolTip {
  text?: string | ReactNode;
  onClick?: () => void;
  checkWidth?: boolean;
  noTruncate?: boolean;
  width?: string;
  classNames?: string;
  children?: ReactNode;
}

export const AppToolTip: FC<AppToolTip> = ({
  text,
  onClick,
  width,
  checkWidth,
  noTruncate,
  classNames,
  children,
}) => {
  const { width: winWidth } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLDivElement>(null);

  const [active, setActive] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!checkWidth) {
      setActive(true);
      return;
    }

    if (refText?.current) {
      setActive(
        refText.current.offsetWidth < refText.current.scrollWidth ||
          refText.current.offsetHeight < refText.current.scrollHeight,
      );
    }
  }, [text, refText, checkWidth]);

  const removeTooltip = () => dispatch(setTooltip(null));

  useEffect(() => {
    removeTooltip();
  }, [winWidth]);

  useEffect(() => {
    window.addEventListener('orientationchange', removeTooltip);
    return () => {
      window.removeEventListener('orientationchange', removeTooltip);
    };
  }, []);

  useEffect(() => {
    if (tooltipVisible) {
      window.addEventListener('mousemove', handleMouseMove);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [tooltipVisible]);

  const handleMouseMove = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setTooltipVisible(false);
      removeTooltip();
    }
  };

  const onMouseEnterHandler = () => {
    if (!text || !ref?.current || !active) return;
    const boundingClient = ref.current.getBoundingClientRect();

    const left = boundingClient.left + ref.current.offsetWidth / 2;
    const top = boundingClient.top + ref.current.offsetHeight + 6;

    dispatch(setTooltip({ text, top, left }));
    setTooltipVisible(true);
  };

  const onMouseLeaveHandler = () => {
    setTooltipVisible(false);
    removeTooltip();
  };

  return (
    <div
      onClick={onClick}
      className={cn(
        { 'w-full': !Boolean(classNames?.includes('w-')) },
        'relative',
        children ? classNames : '',
      )}
      ref={ref}
      style={{ width }}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {children || (
        <div ref={refText} className={cn({ truncate: !Boolean(noTruncate) }, classNames)}>
          {text}
        </div>
      )}
    </div>
  );
};
