import { FC } from 'react';
import { Button } from 'src/components/ui/Button';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppSelector } from 'src/hooks/useRedux';

import cn from 'classnames';

interface DashboardEditPanelProps {
  isAllChecked: boolean;
  count: number;
  onClick: () => void;
}

export const DashboardEditPanel: FC<DashboardEditPanelProps> = ({
  isAllChecked,
  count,
  onClick,
}) => {
  const { checkedPolygons } = useAppSelector(state => state.polygons);

  const { width } = useWindowDimensions();
  const isOne = checkedPolygons?.length === 1;

  return (
    <div
      className={cn(
        {
          '-bottom-24': !Boolean(checkedPolygons.length),
          '-bottom-0.5': Boolean(checkedPolygons.length),
        },
        'fixed transition-full center px-5',
      )}
      style={{ width: `calc(100vw - ${width >= 1024 ? 132 : 0}px)` }}
    >
      <div
        className={
          'w-full bg-white shadow-blacks-700 rounded-t-lg flex items-center justify-between ' +
          'p-4 t-lg:px-16 t-lg:px-8 gap-4 dark:bg-blue-800'
        }
      >
        <div className="flex items-center gap-2 dark:text-white text-xs t-lg:text-base">
          <div className="font-gothampro-400">Выбрано объектов:</div>
          <div className="font-gothampro-500">{isAllChecked ? count : checkedPolygons.length}</div>
        </div>
        <Button variant="blue" size={width >= 1024 ? 'big' : 'small'} onClick={onClick}>
          {`Редактировать${!isOne ? ' все' : ''}`}
        </Button>
      </div>
    </div>
  );
};
