import { FC, Fragment } from 'react';
import { getLandPlotValue } from 'src/utils/helpers';
import { ObjectInfoAttrsType } from 'src/config/types';
import { landPlotsFields } from 'src/config/data';
import { useAppSelector } from 'src/hooks/useRedux';

import { ReactComponent as RedIcon } from 'src/assets/icons/kit/red_icon.svg';
import cn from 'classnames';
import { formatDate } from 'src/utils';

interface ObjectInfoItemListProps {
  attrs: ObjectInfoAttrsType;
}

export const ObjectInfoItemList: FC<ObjectInfoItemListProps> = ({ attrs }) => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);

  const formattedAttrs = {
    ...attrs,
    date_cost: attrs.date_cost ? formatDate(attrs.date_cost) : attrs.date_cost,
    application_date: attrs.application_date
      ? formatDate(attrs.application_date)
      : attrs.application_date,
    cc_date_entering: attrs.cc_date_entering
      ? formatDate(attrs.cc_date_entering)
      : attrs.cc_date_entering,
  };

  return (
    <>
      {selectedPolygon?.reestrInfo?.info ? (
        <div className="font-gothampro-600 border-b border-grey-400 dark:border-b-blue-400 px-5 py-2 text-right flex justify-end items-center">
          <div className="rounded-full text-xs flex items-center bg-amber-400 px-2">
            <RedIcon className="m-2" />
            {selectedPolygon?.reestrInfo?.info}
          </div>
        </div>
      ) : null}

      {landPlotsFields.map((field, i) => {
        const value = getLandPlotValue(field, formattedAttrs);

        if (!value.length || value === ' ' || value === '  ()') {
          return <Fragment key={`object-info-item-${i}`} />;
        }

        return (
          <div
            key={`object-info-item-${i}`}
            className={cn(
              { 'bg-grey-rgba-200 dark:bg-blue-1000': i % 2 },
              'px-5 py-2.5 border-b border-grey-400 dark:border-b-blue-400 flex justify-between',
              'text-extra-xs t-lg:text-xs gap-4',
            )}
          >
            <div className="font-gothampro-400 text-grey-200 dark:text-white">{field.name}</div>
            <div className="font-gothampro-600 w-fit text-right dark:text-white">{value}</div>
          </div>
        );
      })}
    </>
  );
};
