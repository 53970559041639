import { LoginType } from 'src/config/types';
import { request } from 'src/services/Api/Fetch';
import TokenService from 'src/services/TokenService';

const login = (params: LoginType) => {
  return request('/api-auth/token/', params, 'POST');
};

const logout = () => {
  const refresh = TokenService.getLocalRefreshToken();

  const params = {
    refresh,
  };

  return request(`/api-auth/token/logout/`, params, 'POST');
};

const refresh = () => {
  const refresh = TokenService.getLocalRefreshToken();

  const params = {
    refresh,
  };

  return request('/api-auth/token/refresh/', params, 'POST');
};

const getUser = () => {
  return request('/api-auth/token/user/');
};

const resetPassword = () => {
  return request('/api-auth/password_reset/', {}, 'POST');
};

const AuthService = { login, logout, refresh, getUser, resetPassword };

export default AuthService;
