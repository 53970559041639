import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setMainTileUrl } from 'src/store/map';
import { mixTileUrl, schemaTileUrl, sputnikTileUrl } from 'src/config/data';
import { AppToolTip } from 'src/components/ui/AppToolTip';
import { ReactComponent as LazerIcon } from 'src/assets/icons/kit/lazer.svg';
import { ReactComponent as MapSchemaIcon } from 'src/assets/icons/kit/map-schema.svg';
import { ReactComponent as MapMixIcon } from 'src/assets/icons/kit/map-mixed.svg';
import cn from 'classnames';

interface MapSchemaSwitchProps {
  classNames?: string;
}

export const MapSchemaSwitch: FC<MapSchemaSwitchProps> = ({ classNames }) => {
  const dispatch = useAppDispatch();
  const { mainTileUrl } = useAppSelector(state => state.map);

  const styles = 'w-7 h-7 rounded-full center';

  const handleChangeMapSchema = (url: string[]) => {
    dispatch(setMainTileUrl(url));
  };

  const isSputnik = mainTileUrl === sputnikTileUrl;
  const isSchema = mainTileUrl === schemaTileUrl;
  const isSchemaMix = mainTileUrl === mixTileUrl;

  return (
    <div className={cn('rounded-full flex gap-1.5 cursor-pointer mr-2', classNames)}>
      <div
        className={cn(styles, {
          'bg-orange-100 dark:bg-blue-100': isSputnik,
          'dark:bg-blue-200': !isSputnik,
        })}
        onClick={() => handleChangeMapSchema(sputnikTileUrl)}
      >
        <AppToolTip text="Спутник" classNames="flex justify-center">
          <LazerIcon
            className={cn({ 'fill-grey-200 ': !isSputnik, 'fill-white': isSputnik }, 'w-5 h-5')}
          />
        </AppToolTip>
      </div>

      <div
        className={cn(styles, {
          'bg-orange-100 dark:bg-blue-100': isSchema,
          'dark:bg-blue-200': !isSchema,
        })}
        onClick={() => handleChangeMapSchema(schemaTileUrl)}
      >
        <AppToolTip text="Карта" classNames="flex justify-center">
          <MapSchemaIcon
            className={cn({ 'fill-grey-200 ': !isSchema, 'fill-white': isSchema }, 'w-4 h-4')}
          />
        </AppToolTip>
      </div>
      <div
        className={cn(styles, {
          'bg-orange-100 dark:bg-blue-100': isSchemaMix,
          'dark:bg-blue-200': !isSchemaMix,
        })}
        onClick={() => handleChangeMapSchema(mixTileUrl)}
      >
        <AppToolTip text="Гибрид" classNames="flex justify-center">
          <MapMixIcon
            className={cn({ 'fill-grey-200 ': !isSchemaMix, 'fill-white': isSchemaMix }, 'w-4 h-4')}
          />
        </AppToolTip>
      </div>
    </div>
  );
};
