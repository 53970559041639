import { FC, useEffect, useRef } from 'react';
import {
  getPolygonCategories,
  getPolygonName,
  getSubStatusIcon,
  handleSelectPolygon,
} from 'src/utils/helpers';
import { AppToolTip } from 'src/components/ui/AppToolTip';
import { ZoneTag } from 'src/components/ui/ZoneTag';
import { PolygonType } from 'src/config/types';
import { useAuth } from 'src/hooks/useAuth';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import cn from 'classnames';

interface PolygonProps {
  polygon: PolygonType;
  withObserver?: boolean;
  loadMore?: () => void;
}

export const Polygon: FC<PolygonProps> = ({ polygon, withObserver, loadMore }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { width } = useWindowDimensions();

  const categories = useAppSelector(state => state.categories);
  const { selectedPolygon } = useAppSelector(state => state.polygons);
  const { category, status, blockStatus, subStatus } = getPolygonCategories(polygon, categories);

  const ref = useRef<HTMLDivElement>(null);

  const observer = withObserver
    ? new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          handleLoadMore();
        }
      })
    : null;

  const handleLoadMore = () => {
    if (observer && withObserver && ref?.current) {
      observer.unobserve(ref.current);
      loadMore && loadMore();
    }
  };

  useEffect(() => {
    if (observer && withObserver && ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (observer && withObserver && ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const getUniq = (polygon: PolygonType | null) =>
    `${polygon?.id || 0}-${polygon?.cadaster_number || 0}`;

  const isSelected = getUniq(polygon) === getUniq(selectedPolygon);

  const square = polygon?.square || polygon?.properties?.square || 0;
  const polygonSquare = `${Math.floor(square > 1000 ? square / 1000 : square)} ${
    square > 1000 ? 'км' : 'м'
  }`;

  return (
    <div
      ref={ref}
      onClick={async () => {
        await handleSelectPolygon(
          polygon?.properties
            ? polygon
            : {
                ...polygon,
                properties: { ...polygon },
              },
          width,
          dispatch,
        );
      }}
      className={cn(
        {
          'border-b border-grey-400 dark:border-blue-700': !isSelected,
          'border-y border-orange-100 dark:border-white bg-orange-rgba-100 dark:bg-blue-700':
            isSelected,
          'cursor-pointer': !isSelected,
        },
        'px-4 py-5 transition-full hover:bg-orange-rgba-100 dark:hover:bg-blue-700',
      )}
    >
      <div className="flex gap-2 mb-4">
        <AppToolTip
          checkWidth
          width="80%"
          classNames="text-black-100 font-gothampro-400 text-sm dark:text-white truncate"
          text={getPolygonName(polygon)}
        />

        <div
          className={
            'ml-auto text-grey-500 text-xs text-end whitespace-nowrap ' +
            'font-gothampro-600 dark:text-white'
          }
        >
          {polygonSquare}
          <sup>2</sup>
        </div>
      </div>

      <div className="w-full flex gap-2 flex-wrap items-center">
        {category ? (
          <ZoneTag
            fullWidth
            category={{
              ...category,
              color: user?.is_colorpicker ? category.color : '#FF5500',
            }}
          />
        ) : null}

        {status && <ZoneTag fullWidth category={status} />}

        {subStatus ? (
          <div
            className={
              'rounded text-extra-xs flex text-white border px-2 py-1.5 ' +
              'font-gothampro-500 truncate'
            }
            style={{ borderColor: status?.color, color: status?.color }}
          >
            {subStatus.name}
            {getSubStatusIcon(status?.color || '#000', subStatus.icon || 'home')}
          </div>
        ) : null}

        {blockStatus ? <ZoneTag fullWidth category={blockStatus} /> : null}
      </div>
    </div>
  );
};
