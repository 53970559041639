import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import {
  createClusterCustomIcon,
  renderMarker,
} from 'src/components/Map/LeafletMapMarkerCluster/helpers';
import { PolygonType } from 'src/config/types';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import MarkerClusterGroup from 'react-leaflet-cluster';

interface LeafletMapMarkerClusterProps {
  polygons: PolygonType[];
}

export const LeafletMapMarkerCluster: FC<LeafletMapMarkerClusterProps> = ({ polygons }) => {
  const { statuses, blockStatuses } = useAppSelector(state => state.categories);
  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);

  const { width } = useWindowDimensions();
  const dispatch = useAppDispatch();

  const markersList = useMemo(
    () => (
      <>{polygons?.map((polygon, i) => renderMarker(polygon, width, i, dispatch, lassoFilter))}</>
    ),
    [polygons, lassoFilter],
  );

  return (
    <>
      <MarkerClusterGroup
        iconCreateFunction={cluster => createClusterCustomIcon(cluster, statuses, blockStatuses)}
        showCoverageOnHover={false}
        animate={false}
        spiderfyOnMaxZoom={false}
        disableClusteringAtZoom={16}
      >
        {markersList}
      </MarkerClusterGroup>
    </>
  );
};
