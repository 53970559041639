import { FC } from 'react';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { PolygonType } from 'src/config/types';
import { Input } from 'src/components/ui/Input';
import { SelectNew } from 'src/components/ui/SelectNew';
import { useAppSelector } from 'src/hooks/useRedux';
import { CheckboxIsEdit } from './CheckboxIsEdit';

interface DashboardEncumbrancesEdit {
  count: number;
  setValue: UseFormSetValue<any>;
  control: Control<PolygonType>;
  register: UseFormRegister<PolygonType>;
}

export const DashboardEncumbrancesEdit: FC<DashboardEncumbrancesEdit> = ({
  count,
  setValue,
  control,
  register,
}) => {
  const categories = useAppSelector(state => state.categories);

  const textStyles = 'font-gothampro-400 text-sm text-grey-500 w-28 min-w-[7rem] mr-2 t-lg:mr-5';
  const lineStyles = 'flex items-center mb-2 t-lg:mb-5';

  const options = categories?.encumbranceType.map(el => ({ value: el.id, label: el.name }));

  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div key={`encumbrances-${i}`} className="py-8 border-t border-grey-2600">
          <div className={lineStyles}>
            <div className={textStyles}>Обременение</div>
            <Input
              classNames="mr-2 t-lg:mr-5"
              data={{ name: `encumbrances[${i}].encumbrance`, placeholder: 'Обременение' }}
              register={register}
            />

            <div className={textStyles}>Вид обременения</div>
            <SelectNew
              placeholder="Вид"
              options={options}
              name={`encumbrances[${i}].encumbrance_type`}
              control={control}
            />
          </div>

          <div className={lineStyles}>
            <div className={textStyles}>Дата возникновения</div>
            <Input
              classNames="mr-2 t-lg:mr-5"
              type="date"
              onDateChange={date => setValue(`encumbrances[${i}].date_occurrence`, date)}
              data={{
                name: `encumbrances[${i}].date_occurrence`,
                placeholder: 'Дата возникновения',
              }}
              register={register}
            />

            <div className={textStyles}>Дата прекращения</div>
            <Input
              type="date"
              onDateChange={date => setValue(`encumbrances[${i}].date_termination`, date)}
              data={{
                name: `encumbrances[${i}].date_termination`,
                placeholder: 'Дата прекращения',
              }}
              register={register}
            />
          </div>

          <div className={lineStyles}>
            <div className={textStyles}>В пользу кого</div>
            <Input
              data={{ name: `encumbrances[${i}].favor_whom`, placeholder: 'В пользу кого' }}
              register={register}
            />
          </div>

          <div className={lineStyles}>
            <div className={textStyles}>Документы-основания</div>
            <Input
              classNames="mr-2 t-lg:mr-5"
              data={{
                name: `encumbrances[${i}].foundation_documents`,
                placeholder: 'Документы-основания',
              }}
              register={register}
            />

            <div className={textStyles}>Площадь части (кв. м)</div>
            <Input
              type="number"
              data={{
                name: `encumbrances[${i}].area_of_part`,
                placeholder: 'Площадь части (кв. м)',
              }}
              register={register}
            />
          </div>

          <div className={lineStyles}>
            <div className={textStyles}>Комментарий</div>
            <Input
              data={{ name: `encumbrances[${i}].comments`, placeholder: 'Комментарий' }}
              register={register}
            />
          </div>

          <div className="flex">
            <div className="ml-auto">
              <CheckboxIsEdit name={`encumbrances[${i}].is_removed`} control={control} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
