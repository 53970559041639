import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchReestrList, fetchSearchAddressList } from 'src/store/reestr/actions';
import { ReestrState } from 'src/store/reestr/types';
import { AddressListItem, ReestrListType } from 'src/config/types';

const initialState: ReestrState = {
  searchAddressList: [],
  isLoadingSearchAddressList: false,
  reestrList: [],
  isLoadingReestrList: false,
  error: '',
};

const reestrSlice = createSlice({
  name: 'reestr',
  initialState,
  reducers: {
    resetSearchAddressLIst: state => {
      state.searchAddressList = [];
    },
  },
  extraReducers: {
    [fetchReestrList.pending.type]: state => {
      state.isLoadingReestrList = true;
      state.error = '';
      state.reestrList = [];
    },
    [fetchReestrList.fulfilled.type]: (
      state,
      action: PayloadAction<{ features: ReestrListType[] }>,
    ) => {
      state.isLoadingReestrList = false;
      state.error = '';
      state.reestrList = action.payload?.features;
    },
    [fetchReestrList.rejected.type]: (state, action: PayloadAction<string>) => {
      // state.isLoadingReestrList = false;
      state.error = action.payload;
    },
    [fetchSearchAddressList.pending.type]: state => {
      state.isLoadingSearchAddressList = true;
      state.error = '';
      state.searchAddressList = [];
    },
    [fetchSearchAddressList.fulfilled.type]: (
      state,
      action: PayloadAction<{ results: AddressListItem[]; feature?: { attrs?: { cn?: string } } }>,
    ) => {
      state.isLoadingSearchAddressList = false;
      state.error = '';
      state.searchAddressList = action.payload?.results;

      if (action.payload?.feature?.attrs?.cn) {
        state.searchAddressList = [
          {
            value: action.payload.feature.attrs.cn,
            title: action.payload.feature.attrs.cn,
          },
        ];
      }
    },
    [fetchSearchAddressList.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSearchAddressList = false;
      state.error = action.payload;
    },
  },
});

export const { resetSearchAddressLIst } = reestrSlice.actions;

const reducer = reestrSlice.reducer;
export default reducer;
