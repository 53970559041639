import { FC } from 'react';
import { LoginForm } from 'src/components/Forms/LoginForm';

export const LoginPage: FC = () => {
  return (
    <div className="w-full h-full px-4 center">
      <LoginForm />
    </div>
  );
};
