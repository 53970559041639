import { FC, useEffect, useState } from 'react';
import { DarkModeSwitch } from 'src/components/DarkModeSwitch';
import { MapSchemaSwitch } from 'src/components/MapSchemaSwitch';
import { FavouritesButton } from 'src/components/FavouritesButton';
import { useAuth } from 'src/hooks/useAuth';
import { ClusterChangeButton } from 'src/components/ClusterChangeButton';
import { useAppSelector } from 'src/hooks/useRedux';

import cn from 'classnames';

interface HeadLoaderProps {
  isMap: boolean;
}

export const HeadLoader: FC<HeadLoaderProps> = ({ isMap }) => {
  const { isLoadingAllInfo } = useAppSelector(state => state.categories);
  const { isLoadingReestrList } = useAppSelector(state => state.reestr);
  const { isLoadingReestrPolygons, isLoadingSelectedPolygon } = useAppSelector(
    state => state.polygons,
  );

  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(
      isLoadingAllInfo ||
        isLoadingReestrList ||
        isLoadingReestrPolygons ||
        isLoadingSelectedPolygon,
    );
  }, [isLoadingAllInfo, isLoadingReestrList, isLoadingReestrPolygons, isLoadingSelectedPolygon]);

  return (
    <>
      <div
        className={cn(
          { 'opacity-0': isLoading, 'z-10': !isLoading },
          'flex items-center gap-4 t-lg:absolute left-2.5 d-xl:left-8',
        )}
      >
        <div className="flex t-lg:ml-0 gap-1.5">
          <DarkModeSwitch />
          {isMap ? (
            <>
              <MapSchemaSwitch />
              {user?.isLeader || user?.isManager ? <FavouritesButton /> : null}
              <ClusterChangeButton />
            </>
          ) : null}
        </div>
      </div>
      <div
        className={cn(
          { 'opacity-0': !isLoading },
          'flex items-center absolute t-lg:top-5 d-xl:top-7 t-lg:left-2.5 d-xl:left-8 transition-full',
        )}
      >
        <div className="font-gothampro-500 dark:text-white animate-load">загрузка...</div>
      </div>
    </>
  );
};
