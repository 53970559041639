import { BASE_EXCEL_URL } from 'src/config';
import TokenService from 'src/services/TokenService';
import axios from 'axios';

export const ApiExcel = axios.create({
  baseURL: BASE_EXCEL_URL,
});

ApiExcel.interceptors.request.use(
  config => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
