import { FC, useEffect, useMemo, useState } from 'react';
import { LeafletMapPolygons } from 'src/components/Map/LeafletMapPolygons';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { LeafletRuler } from 'src/components/Map/LeafletRuler';
import { LeafletMapLayers } from 'src/components/Map/LeafletMapLayers';
import { setFlyTo } from 'src/store/map';
import { LeafletMapMyPosition } from 'src/components/Map/LeafletMapMyPosition';
import { LeafletMapSelectedPolygonTooltip } from 'src/components/Map/LeafletMapSelectedPolygonTooltip';
import { LeafletMapMarkerCluster } from 'src/components/Map/LeafletMapMarkerCluster';
import { LeafletMapFavoritePolygonPopUp } from 'src/components/Map/LeafletMapFavoritePolygonPopUp';
import { LeafletMapMarkers } from 'src/components/Map/LeafletMapMarkers';
import { LeafletMapEditControl } from 'src/components/Map/LeafletMapEditControl';
import { fetchSelectedPolygon } from 'src/store/polygons/actions';
import { useAuth } from 'src/hooks/useAuth';
import { isDrawing } from 'src/utils/helpers';
import { LeafletMapLasso } from 'src/components/Map/LeafletMapLasso';
import { sleep } from 'src/utils';
import { polygonFieldsForMap, ruDrawOptions, ruEditOptions } from 'src/config/data';
import { TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { setReestrPolygons } from 'src/store/polygons';
import { getFilteredPolygons } from 'src/selectors/polygonSelectors';
import L, { LatLngExpression } from 'leaflet';
import PolygonsService from 'src/services/PolygonsService';

import './styles.css';

export const LeafletMap: FC = () => {
  const dispatch = useAppDispatch();
  const mapHook = useMap();
  const { user } = useAuth();
  const { selectedLayers, flyTo, mainTileUrl } = useAppSelector(state => state.map);
  const { isClustersActive } = useAppSelector(state => state.common);
  const { selectedPolygon, favoritePolygons, reestrPolygons, reestrPolygonsId } = useAppSelector(
    state => state.polygons,
  );
  const filteredPolygons = useAppSelector(state => getFilteredPolygons(state));

  const [mapData, setMapData] = useState<{ zoom: number; bounds?: LatLngExpression }>({ zoom: 15 });
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [clustersTrigger, setClustersTrigger] = useState(true);

  useEffect(() => {
    if (!isClustersActive) {
      return;
    }

    (async () => {
      setClustersTrigger(false);
      await sleep(100);
      setClustersTrigger(true);
    })();
  }, [filteredPolygons]);

  useEffect(() => {
    if (L?.['drawLocal']?.draw && L?.['drawLocal']?.edit) {
      L['drawLocal'].draw = ruDrawOptions;
      L['drawLocal'].edit = ruEditOptions;
    }
  }, []);

  useEffect(() => {
    if (!flyTo || !mapHook) return;
    (async () => {
      mapHook.setView(flyTo, 16);
      await sleep(100);
      mapHook.setView([flyTo[0], flyTo[1] + 0.0001], 16);
      setMapData(prev => ({ ...prev, zoom: 16 }));
      dispatch(setFlyTo(null));
    })();
  }, [flyTo]);

  const handleGetPolygons = async (pos: LatLngExpression) => {
    const posToGet = {
      xmin: pos['_southWest'].lng,
      ymin: pos['_southWest'].lat,
      xmax: pos['_northEast'].lng,
      ymax: pos['_northEast'].lat,
    };

    try {
      abortController && abortController.abort();
      const controller = new AbortController();

      setAbortController(controller);

      const response = await PolygonsService.getAllReestrPolygons(
        {
          fields: polygonFieldsForMap,
          coords: posToGet,
          ids: reestrPolygonsId,
        },
        controller.signal,
      );

      const polygonsList = response?.features;

      if (polygonsList?.length) {
        dispatch(
          setReestrPolygons({
            polygons: [...reestrPolygons, ...polygonsList],
            ids: [...reestrPolygonsId, ...polygonsList.map(({ id }) => id || 0)],
          }),
        );
      }
      setAbortController(null);
    } catch (e) {
      console.log(e);
    }
  };

  useMapEvents({
    click: async e => {
      await sleep(100);
      const favoriteForm = document.querySelector('#favorite-form');
      const lassoActive = document.querySelector('.leaflet-lasso-close');

      if ((favoriteForm && favoriteForm.contains(e?.originalEvent?.target as Node)) || lassoActive)
        return;

      if (isDrawing() || !selectedLayers?.length) return;

      await dispatch(
        fetchSelectedPolygon({ coords: [e.latlng.lat, e.latlng.lng], selectedLayers }),
      );
    },
    moveend: async e => {
      const zoom = e.target.getZoom();
      const bounds = e.target.getBounds();
      setMapData({ zoom, bounds });
      await handleGetPolygons(bounds);
    },
  });

  const checkNewForm = useMemo(
    () =>
      !!favoritePolygons.find(
        polygon =>
          polygon?.properties?.cadaster_number === selectedPolygon?.cadaster_number ||
          polygon?.id === selectedPolygon?.id,
      ) ||
      !!reestrPolygons.find(
        polygon => polygon?.properties?.cadaster_number === selectedPolygon?.cadaster_number,
      ),
    [selectedPolygon, favoritePolygons, reestrPolygons],
  );

  return (
    <>
      <LeafletMapLayers />
      <LeafletMapPolygons polygons={filteredPolygons} bounds={mapData.bounds} />
      <LeafletMapEditControl />
      <LeafletRuler />
      <LeafletMapMyPosition />
      <LeafletMapLasso />

      {mainTileUrl.map((tileUrl, i) => {
        return <TileLayer key={`tile-url-${i}`} maxZoom={19} url={tileUrl} />;
      })}

      {!checkNewForm && !selectedPolygon?.id && user?.isLeader ? (
        <LeafletMapFavoritePolygonPopUp />
      ) : null}
      {mapData.zoom >= 16 ? <LeafletMapSelectedPolygonTooltip /> : null}
      {isClustersActive && clustersTrigger ? (
        <LeafletMapMarkerCluster polygons={filteredPolygons} />
      ) : null}
      {!isClustersActive && mapData.zoom >= 16 ? (
        <LeafletMapMarkers polygons={filteredPolygons} />
      ) : null}
    </>
  );
};
