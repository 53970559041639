import { request } from 'src/services/Api/Fetch';
import { Api } from 'src/services/Api/Api';

const getAllFiles = () => {
  return request(`/api/upload_file/`);
};

const saveFile = (params: FormData) => {
  return Api.post(`/api/upload_file/`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const deleteFileById = (params: { id: number }) => {
  return request(`/api/upload_file/${params.id}`, {}, 'DELETE');
};

const FilesService = {
  getAllFiles,
  saveFile,
  deleteFileById,
};

export default FilesService;
