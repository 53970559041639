import { FC, useEffect, useState } from 'react';
import { Button } from 'src/components/ui/Button';
import { setLassoFilter } from 'src/store/filters';
import { calcLassoButtonPosition } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { download, notice } from 'src/utils';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import { Polygon } from 'react-leaflet';
import { BASE_EXCEL_URL } from 'src/config';
import { LassoControl, LassoHandlerFinishedEvent } from 'leaflet-lasso';
import { ExcelExportType } from 'src/config/types';
import ExcelService from 'src/services/ExcelService';

import MarkerIcon from 'src/assets/icons/marker.svg';
import L from 'leaflet';
import 'leaflet-lasso';
import './styles.css';

export const LeafletMapLasso: FC = () => {
  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [lasso, setLasso] = useState<LassoControl>();
  const myMap = useMap();

  myMap.on('lasso.finished', (event: LassoHandlerFinishedEvent) => {
    const temp: number[] = [];

    event.layers.forEach(el => {
      if ((el?.options?.['className'] || '').includes('fill-')) {
        temp.push(+el.options['className'].replace('fill-', ''));
      }
    });

    const map = document.querySelector('.my-map');
    if (!map) return;

    map.classList.add('leaflet-lasso-close');

    dispatch(setLassoFilter({ polygonIds: temp, positions: event.latLngs }));
  });

  myMap.on('lasso.enabled', () => {
    const map = document.querySelector('.leaflet-lasso-close');
    if (!map) return;
    dispatch(setLassoFilter({}));

    map.classList.remove('leaflet-lasso-close');
  });

  useEffect(() => {
    const lasso = L.control.lasso();
    lasso.addTo(myMap);
    setLasso(lasso);
  }, []);

  useEffect(() => {
    if (lassoFilter?.positions?.length) return;

    lasso?.disable();
  }, [lassoFilter]);

  const icon = new L.Icon({
    iconUrl: MarkerIcon,
    iconRetinaUrl: MarkerIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
    className: 'hidden',
  });

  const getArrInString = arr => arr?.map(el => el?.toString()) || [];
  const handleGetExcel = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const params: ExcelExportType = {
      columns: {
        block: true,
        category: true,
        cadaster_number: true,
        address: true,
        square: true,
        type_owner: true,
        owner: true,
        status: true,
        substatus: true,
        cost: true,
        pzz: true,
        master_plan: true,
      },
      filter: {
        block_status: [],
        category: [],
        type_owner: [],
        owner: [],
        status: [],
        substatus: [],
        id: getArrInString(lassoFilter.polygonIds),
      },
    };

    try {
      const res = await ExcelService.exportExcel(params);

      if (res?.data?.error && res?.data?.success) {
        notice({
          title: 'Ошибка!',
          message: 'Импорт или экспорт уже выполняется ',
          type: 'danger',
        });
      }

      if (!res?.data?.file) return;

      download(`${BASE_EXCEL_URL}/${res.data.file}`, 'excel.xlsx');
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {lassoFilter?.positions ? (
        <Marker position={calcLassoButtonPosition(lassoFilter.positions)} icon={icon}>
          <Tooltip interactive opacity={1} direction="top" permanent className="lasso-popup">
            <Button variant="blue" size="big" onClick={handleGetExcel}>
              Экспортировать
            </Button>
          </Tooltip>
        </Marker>
      ) : null}

      {lassoFilter?.positions?.length ? (
        <Polygon
          positions={lassoFilter.positions}
          pathOptions={{
            color: '#00C3FF',
            weight: 2,
          }}
        />
      ) : null}
    </>
  );
};
