import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { renderMarker } from 'src/components/Map/LeafletMapMarkerCluster/helpers';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { PolygonType } from 'src/config/types';

import MarkerClusterGroup from 'react-leaflet-cluster';

interface LeafletMapMarkerProps {
  polygons: PolygonType[];
}

export const LeafletMapMarkers: FC<LeafletMapMarkerProps> = ({ polygons }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const lassoFilter = useAppSelector(state => state.filters.lassoFilter);

  const markersList = useMemo(
    () => (
      <>{polygons?.map((polygon, i) => renderMarker(polygon, width, i, dispatch, lassoFilter))}</>
    ),
    [polygons, lassoFilter],
  );

  return (
    <>
      <MarkerClusterGroup disableClusteringAtZoom={1}>{markersList}</MarkerClusterGroup>
    </>
  );
};
