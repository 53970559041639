import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import './styles.css';
import 'src/utils/leafletRuler';

export const LeafletRuler: FC = () => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;

    // @ts-ignore
    L.control.ruler().addTo(map);
  }, [map]);

  return null;
};
