import { useMemo, useState } from 'react';

import { createDate, createMonth, getMonthName, getWeekDayName, getYearName } from '../utils';
import { DayType, MonthType } from '../utils/types';

export type UseCalendarParams = {
  date?: Date;
  locale?: string;
};

export const useCalendar = ({ date, locale }: UseCalendarParams) => {
  const currentDay = createDate({ date, locale });
  const [selectedMonth, setSelectedMonth] = useState<MonthType>(
    createMonth({ date: new Date(currentDay.year, currentDay.monthIndex) }),
  );

  const monthsNames = getMonthName();
  const yearsNames = getYearName();

  const weekDaysNames = useMemo(() => getWeekDayName(locale), [locale]);

  const calendarDays = useMemo(() => {
    const DAYS_IN_WEEK = 7;

    const firstDay = selectedMonth.days[0];
    const lastDay = selectedMonth.days[selectedMonth.days.length - 1];

    const shiftIndex = 1;

    const numberOfPrevDays =
      firstDay.dayNumberInWeek - 1 - shiftIndex < 0
        ? DAYS_IN_WEEK - (2 - firstDay.dayNumberInWeek)
        : firstDay.dayNumberInWeek - 1 - shiftIndex;

    const numberOfNextDays =
      DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex > 6
        ? DAYS_IN_WEEK - lastDay.dayNumberInWeek - (DAYS_IN_WEEK - shiftIndex)
        : DAYS_IN_WEEK - lastDay.dayNumberInWeek + shiftIndex;

    const totalCalendarDays = selectedMonth.days.length + numberOfPrevDays + numberOfNextDays;

    const result: Array<DayType | undefined> = [];

    for (let i = 0; i < numberOfPrevDays; i += 1) {
      result[i] = undefined;
    }

    for (let i = numberOfPrevDays; i < totalCalendarDays - numberOfNextDays; i += 1) {
      result[i] = selectedMonth.days[i - numberOfPrevDays];
    }

    for (let i = totalCalendarDays - numberOfNextDays; i < totalCalendarDays; i += 1) {
      result[i] = undefined;
    }

    return result;
  }, [selectedMonth]);

  const changeYear = (year: number) => {
    setSelectedMonth(createMonth({ date: new Date(year, selectedMonth.monthIndex), locale }));
  };

  const changeMonth = (monthIndex: number) => {
    setSelectedMonth(createMonth({ date: new Date(selectedMonth.year, monthIndex), locale }));
  };

  return {
    state: { weekDaysNames, monthsNames, yearsNames, selectedMonth, calendarDays },
    functions: { changeYear, changeMonth },
  };
};
