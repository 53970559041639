import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { Loader } from 'src/components/ui/Loader';
import { Polygon } from 'src/components/Zones/Polygon';
import { FetchReestrPolygonsParamsParams, PolygonType } from 'src/config/types';
import { polygonTableFieldsForMap } from 'src/config/data';
import { useAuth } from 'src/hooks/useAuth';
import { setFilteredPolygonsCount } from 'src/store/polygons';
import { isFiltersEmpty } from 'src/utils';
import { getMyFilters } from 'src/utils/helpers';
import PolygonsService from 'src/services/PolygonsService';
import { getFilteredDrawPolygons } from 'src/selectors/polygonSelectors';

export const ZonePolygonsList: FC = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth();
  const { polygonsFilter, listSearch } = useAppSelector(state => state.filters);
  const filteredDrawPolygons = useAppSelector(state => getFilteredDrawPolygons(state));

  const [polygons, setPolygons] = useState<PolygonType[]>([]);
  const [isLoadingPolygons, setIsLoadingPolygons] = useState(true);
  const [page, setPage] = useState(1);

  const getPolygons = async (params: FetchReestrPolygonsParamsParams, add?: boolean) => {
    setIsLoadingPolygons(true);
    if (!add) {
      setPolygons([]);
    }

    try {
      const response = await PolygonsService.getAllReestrPolygons(params);

      if (response.detail) return;

      dispatch(setFilteredPolygonsCount(response?.count || 0));

      if (add) {
        setPolygons(prev => [...prev, ...response.results.features]);
      } else {
        setPolygons(response.results.features);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsLoadingPolygons(false);
    }
  };

  const loadMore = async () => {
    const newPage = page + 1;
    await getPolygons(
      {
        fields: polygonTableFieldsForMap,
        page: newPage,
        ...(!isFiltersEmpty(polygonsFilter) && { filter: getMyFilters(polygonsFilter).join('') }),
      },
      true,
    );
    setPage(newPage);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    setPage(1);
    getPolygons({
      fields: polygonTableFieldsForMap,
      page: 1,
      ...(listSearch && { search: listSearch }),
      ...(!isFiltersEmpty(polygonsFilter) && { filter: getMyFilters(polygonsFilter).join('') }),
    }).then();
  }, [user, polygonsFilter, listSearch]);

  return (
    <>
      {isLoadingPolygons && !polygons.length ? (
        <div className="w-full h-full flex items-center justify-center py-10">
          <Loader classNames="w-20 h-20" />
        </div>
      ) : null}

      {!isLoadingPolygons && !polygons?.length ? (
        <div
          className={
            'py-10 w-full text-center text-sm t-lg:text-base ' +
            'font-gothampro-400 dark:text-white'
          }
        >
          Не найдено
        </div>
      ) : null}

      {polygons?.length &&
        [...filteredDrawPolygons, ...polygons]?.map((polygon, i) => (
          <Polygon
            key={`zone-item-${i}`}
            polygon={polygon}
            withObserver={polygons.length - 3 === i}
            loadMore={loadMore}
          />
        ))}
    </>
  );
};
