import { RouteType } from 'src/config/types';
import { LoginPage } from 'src/pages/LoginPage';
import { MapPage } from 'src/pages/MapPage';
import { DashboardPage } from 'src/pages/DashboardPage';
import { DashboardPolygonPage } from 'src/pages/DashboardPolygonPage';
import { UserPage } from 'src/pages/UserPage';

export const routes: RouteType[] = [
  {
    path: '/',
    element: <LoginPage />,
  },
];

export const protectedRoutes: RouteType[] = [
  {
    path: '/map',
    element: <MapPage />,
    icon: 'map',
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    icon: 'list',
  },
  {
    path: '/dashboard/:polygonId',
    element: <DashboardPolygonPage />,
  },
  {
    path: '/user',
    element: <UserPage />,
    icon: 'user',
  },
];
