import { FC } from 'react';
import { setSelectedPolygon } from 'src/store/polygons';
import { removeQueryParams } from 'src/utils/helpers';
import { useAppDispatch } from 'src/hooks/useRedux';
import { useAuth } from 'src/hooks/useAuth';
import { ReactComponent as ExitIcon } from 'src/assets/icons/kit/exit.svg';

export const Footer: FC = () => {
  const dispatch = useAppDispatch();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    dispatch(setSelectedPolygon({ polygon: null }));
    removeQueryParams();
  };

  return (
    <div className="h-11 min-h-11 flex px-2.5 d-xl:px-8 items-center w-[105px] absolute right-7">
      <div
        onClick={handleLogout}
        className="flex cursor-pointer gap-2 items-center ml-auto bg-red-100 h-8 px-5 rounded  "
      >
        <div className="font-gothampro-500 text-white text-sm">Выход</div>
        <ExitIcon className="w-4 h-4 fill-white" />
      </div>
    </div>
  );
};
