import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { setIsMobileButtonsOpened } from 'src/store/map';
import { calculateFiltersCount } from 'src/utils/helpers';
import { FilterPolygons } from 'src/components/FIlterPolygons';
import { LayersList } from 'src/components/LayersList';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { MapIcon } from 'src/components/MapIcon';
import { InfoData } from 'src/components/InfoData';
import { MapButtonType } from 'src/config/types';

import { ReactComponent as FilterIcon } from 'src/assets/icons/kit/filter.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as MapPinIcon } from 'src/assets/icons/kit/map-pin.svg';
import { ReactComponent as LayersIcon } from 'src/assets/icons/kit/layers.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/kit/info.svg';
import cn from 'classnames';

interface MapButtonsMobileProps {
  moveToMyLocation?: () => void;
}

export const MapButtonsMobile: FC<MapButtonsMobileProps> = ({ moveToMyLocation }) => {
  const { polygonsFilter } = useAppSelector(state => state.filters);
  const { isMobileButtonsOpened } = useAppSelector(state => state.map);

  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const buttonsRef = useRef<HTMLDivElement>(null);

  const [activeButton, setActiveButton] = useState<MapButtonType | undefined>();

  useClickOutside(buttonsRef, () => setActiveButton(undefined));

  const iconClassName = 'w-5 h-5 fill-white';

  const mobileButtons: MapButtonType[] = [
    {
      id: 1,
      icon: <FilterIcon className={iconClassName} />,
      color: '#FF5500',
      label: 'Фильтры',
      count: calculateFiltersCount(polygonsFilter),
      content: (
        <FilterPolygons classNames="w-full" handleClose={() => setActiveButton(undefined)} />
      ),
    },
    {
      id: 2,
      icon: <InfoIcon className={iconClassName} />,
      color: '#0009FF',
      label: 'Информация',
      content: <InfoData />,
    },
    {
      id: 3,
      icon: <LayersIcon className={iconClassName} />,
      color: '#7C10DA',
      label: 'Слои',
      content: (
        <div className="max-h-[60vh] overflow-auto">
          <LayersList />
        </div>
      ),
    },
    {
      id: 4,
      icon: <MapPinIcon className={iconClassName} />,
      onClick: () => {
        moveToMyLocation && moveToMyLocation();
        setActiveButton(undefined);
      },
      color: '#0009FF',
      hidden: !moveToMyLocation,
    },
  ];

  const handleCloseMobileButtons = () => {
    dispatch(setIsMobileButtonsOpened(!isMobileButtonsOpened));
    const leafletButtons = document.querySelector('.leaflet-control-container');

    if (!leafletButtons) return;

    leafletButtons.className = cn({ hidden: isMobileButtonsOpened }, 'leaflet-control-container');
  };

  useEffect(() => {
    if (isMobileButtonsOpened) return;

    const leafletButtons = document.querySelector('.leaflet-control-container');

    if (!leafletButtons) return;

    leafletButtons.className = cn({ hidden: true }, 'leaflet-control-container');
  }, [width]);

  const handleSetActiveButton = (button: MapButtonType) => {
    if (button.onClick) {
      setActiveButton(undefined);
      button.onClick();
      return;
    }

    if (activeButton?.id === button.id) {
      setActiveButton(undefined);
    } else {
      setActiveButton(button);
    }
  };

  return (
    <>
      <div className="top-2.5 absolute left-4 z-2300">
        <MapIcon
          onClick={handleCloseMobileButtons}
          isBlur={false}
          icon={
            <ArrowIcon
              className={cn(
                {
                  'rotate-90': !isMobileButtonsOpened,
                  '-rotate-90': isMobileButtonsOpened,
                },
                'stroke-path-white w-5 h-5',
              )}
            />
          }
          color="#0D2C49"
        />
      </div>
      {isMobileButtonsOpened ? (
        <>
          <div className="absolute top-14 left-4 z-2200 flex gap-1" ref={buttonsRef}>
            <div className="flex flex-col gap-2.5">
              {mobileButtons.map(button => {
                if (button.hidden) {
                  return <Fragment key={`mobile-map-button-${button.id}`} />;
                }

                return (
                  <MapIcon
                    onClick={() => handleSetActiveButton(button)}
                    key={`mobile-map-button-${button.id}`}
                    isActive={activeButton?.id === button.id}
                    isBlur={activeButton ? activeButton?.id !== button.id : false}
                    {...button}
                  />
                );
              })}
            </div>
            {activeButton?.content ? (
              <div
                className="bg-white dark:bg-blue-300 h-fit rounded-md -mt-12 max-w-[40rem] p-3"
                style={{ width: 'calc(100vw - 76px)' }}
              >
                <div className="flex justify-between pb-3">
                  <div className="text-sm dark:text-white font-gothampro-500">
                    {activeButton.label}
                  </div>
                  <div
                    className="w-5 h-5 border border-black dark:border-white cursor-pointer rounded-full center"
                    onClick={() => setActiveButton(undefined)}
                  >
                    <CloseIcon className="w-2 h-2 dark:fill-white" />
                  </div>
                </div>
                <div className="overflow-auto max-h-[80vh]">{activeButton.content}</div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};
