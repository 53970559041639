import { FC } from 'react';
import { Search } from 'src/components/ui/Search';
import { ZonePolygonsList } from 'src/components/Zones/ZonePolygonsList';
import { useAppDispatch } from 'src/hooks/useRedux';
import { setListSearch } from 'src/store/filters';

export const Zones: FC = () => {
  const dispatch = useAppDispatch();
  const handleChangeSearch = (search: string) => {
    dispatch(setListSearch(search));
  };

  return (
    <div className="w-full dark:bg-blue-500 min-h-full">
      <div className="px-4 py-2.5 border-b border-grey-1100">
        <Search
          variant="small"
          onChange={handleChangeSearch}
          placeholder="Найти объекты..."
          classNames="w-full"
        />
      </div>

      <ZonePolygonsList />
    </div>
  );
};
