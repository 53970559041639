import { FC, useEffect, useState } from 'react';
import { setFlyTo } from 'src/store/map';
import { useLocation } from 'react-router';
import { useAppDispatch } from 'src/hooks/useRedux';
import { Marker } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

import mapPinIcon from 'src/assets/icons/map-pin.svg';

export const LeafletMapMyPosition: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [currentPosition, setCurrentPosition] = useState<LatLngExpression>();

  const size = 72;
  const myPositionIcon = L.icon({
    iconUrl: mapPinIcon,
    iconSize: [size, size],
    iconAnchor: [size / 2, size / 1.2],
  });

  useEffect(() => {
    if (!location.search.includes('cn')) {
      navigator.geolocation.getCurrentPosition(position => {
        const coords: LatLngExpression = [position.coords.latitude, position.coords.longitude];
        setCurrentPosition(coords);
        dispatch(setFlyTo(coords));
      });
    }
  }, []);

  if (!currentPosition) {
    return <></>;
  }

  return <Marker icon={myPositionIcon} position={currentPosition} />;
};
