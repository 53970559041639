import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonState } from 'src/store/common/types';
import { ImportExcelDataType, ToolTipType, UserType } from 'src/config/types';

const initialState: CommonState = {
  resetFormTrigger: false,
  polygonEdit: false,
  isFavouritesActive: false,
  isClustersActive: false,
  openedDropBlocks: {},
  darkMode: true,
  isLoaderFullActive: false,
  isExcelUpload: false,
  isExcelUploadVisible: true,
  user: null,
  isLoadingUser: true,
  isNewPolygonFormOpen: false,
  isBurgerOpen: false,
  toolTip: null,
  importExcelData: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setResetForm: (state, action: PayloadAction<boolean>) => {
      state.resetFormTrigger = action.payload;
    },
    setPolygonEdit: (state, action: PayloadAction<boolean>) => {
      state.polygonEdit = action.payload;
    },
    setIsFavouritesActive: (state, action: PayloadAction<boolean>) => {
      state.isFavouritesActive = action.payload;
    },
    setIsClustersActive: (state, action: PayloadAction<boolean>) => {
      state.isClustersActive = action.payload;
    },
    setOpenedDropBlock: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.openedDropBlocks = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    setLoaderFull: (state, action: PayloadAction<boolean>) => {
      state.isLoaderFullActive = action.payload;
    },
    setIsExcelUpload: (state, action: PayloadAction<boolean>) => {
      state.isExcelUpload = action.payload;
    },
    setIsExcelUploadVisible: (state, action: PayloadAction<boolean>) => {
      state.isExcelUploadVisible = action.payload;
    },
    setImportExcelData: (state, action: PayloadAction<ImportExcelDataType | null>) => {
      state.importExcelData = action.payload;
    },
    setUser: (state, action: PayloadAction<UserType | null>) => {
      state.user = action.payload;
    },
    setIsNewPolygonFormOpen: (state, action: PayloadAction<boolean>) => {
      state.isNewPolygonFormOpen = action.payload;
    },
    setIsBurgerOpen: (state, action: PayloadAction<boolean>) => {
      state.isBurgerOpen = action.payload;
    },
    setIsLoadingUser: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUser = action.payload;
    },
    setTooltip: (state, action: PayloadAction<ToolTipType | null>) => {
      state.toolTip = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setResetForm,
  setPolygonEdit,
  setIsFavouritesActive,
  setIsClustersActive,
  setOpenedDropBlock,
  setDarkMode,
  setLoaderFull,
  setIsExcelUpload,
  setIsExcelUploadVisible,
  setImportExcelData,
  setUser,
  setIsNewPolygonFormOpen,
  setIsBurgerOpen,
  setIsLoadingUser,
  setTooltip,
} = commonSlice.actions;

const reducer = commonSlice.reducer;
export default reducer;
