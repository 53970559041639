import { combineReducers, configureStore } from '@reduxjs/toolkit';
import commonReducer from './common';
import mapReducer from './map';
import categoriesReducer from './categories';
import polygonsReducer from './polygons';
import reestrReducer from './reestr';
import filtersReducer from './filters';

const rootReducer = combineReducers({
  common: commonReducer,
  map: mapReducer,
  categories: categoriesReducer,
  polygons: polygonsReducer,
  reestr: reestrReducer,
  filters: filtersReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
