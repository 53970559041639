import { Api } from 'src/services/Api/Api';
import { request } from 'src/services/Api/Fetch';

const getReestrList = (params: { text: string }) => {
  return request(`/reestr/?text=${params.text}`);
};

const redirect = (params: { coords: string; cadastral_number: string }) => {
  return request(
    `/api-layer/redirect/?coords=${params.coords}&cadastral_number=${params.cadastral_number}`,
  );
};

const getReestrInfo = (params: { cn: string }) => {
  return request(`/get_info/?cadastral_number=${params.cn}`);
};

const searchInfo = (params: { text: string }) => {
  return request(`/search/?text=${params.text}`);
};

const searchText = (params: { text: string }) => {
  return request(`/text_search/?text=${params.text}`);
};

const searchCordOrCad = (params: { query: string }) => {
  return request(`/search_cord_or_cad/?query=${params.query}`);
};

const click = (params: { text: string }) => {
  return request(`/click/?text=${params.text}`);
};

const getCoords = (params: { x: number; y: number }) => {
  return request(`/get_cord/?x=${params.x}&y=${params.y}`);
};

const svgFromPng = (params: FormData) => {
  return Api.post(`/convert_png/`, params, { headers: { 'Content-Type': 'multipart/form-data' } });
};

const getGeoJsonPolygon = (params: { cadastral_number: string }) => {
  return request(`/get_geojson_polygon/?cadastral_number=${params.cadastral_number}`);
};

// здания,сооружения +
const getBuildings = (params: { text: string }) => {
  return request(`/get_building/?text=${params.text}`);
};

// земельные участки
const getLand = (params: { text: string }) => {
  return request(`/get_land/?text=${params.text}`);
};

// Границы +
const getBorders = (params: { text: string }) => {
  return request(`/get_borders/?text=${params.text}`);
};

// зоны с особыми +
const getSpecialZone = (params: { text: string }) => {
  return request(`/get_special_zone/?text=${params.text}`);
};

// территориальные зоны +
const getTerritoryZone = (params: { text: string }) => {
  return request(`/get_territory_zone/?text=${params.text}`);
};

// зоны и территории
const getZoneAndTerritory = (params: { text: string }) => {
  return request(`/get_zone_and_territory/?text=${params.text}`);
};

const ReestrService = {
  getGeoJsonPolygon,
  getLand,
  redirect,
  searchText,
  svgFromPng,
  getBorders,
  getSpecialZone,
  getTerritoryZone,
  getZoneAndTerritory,
  getReestrList,
  getReestrInfo,
  searchInfo,
  searchCordOrCad,
  click,
  getCoords,
  getBuildings,
};

export default ReestrService;
