import { FC, Fragment, useState } from 'react';
import { AppToolTip } from 'src/components/ui/AppToolTip';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useAuth } from 'src/hooks/useAuth';
import { Button } from 'src/components/ui/Button';
import { fetchSelectedPolygon } from 'src/store/polygons/actions';
import { ZoneTag } from 'src/components/ui/ZoneTag';
import { Loader } from 'src/components/ui/Loader';
import {
  setFavoritePolygons,
  setNotSavedDrawFavoritePolygonId,
  setSelectedPolygon,
} from 'src/store/polygons';
import { setIsNewPolygonFormOpen, setOpenedDropBlock } from 'src/store/common';
import { setFlyTo } from 'src/store/map';
import { getCenter } from 'src/utils';
import { geoJSONToCoords, removeQueryParams } from 'src/utils/helpers';
import { PolygonType } from 'src/config/types';
import PolygonsService from 'src/services/PolygonsService';
import parse from 'html-react-parser';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

export const ZonesFavorite: FC = () => {
  const { favoritePolygons, isLoadingFavoritePolygons, drawPolygons } = useAppSelector(
    state => state.polygons,
  );
  const { statuses } = useAppSelector(state => state.categories);
  const { selectedLayers } = useAppSelector(state => state.map);

  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState<PolygonType>();

  const handleCloseRemoveModal = () => setIsOpenRemoveModal(undefined);

  const handleDeletePolygon = async (polygon: PolygonType) => {
    if (!polygon) return;

    try {
      await PolygonsService.removeFavorite({ id: +(polygon.id || '') });
      const newList = favoritePolygons.filter(pol => pol.id !== polygon.id);
      dispatch(setFavoritePolygons(newList));
    } catch (error) {
      console.log(error.message);
    } finally {
      handleCloseRemoveModal();
    }
  };

  const handleSelectZone = async polygon => {
    if (polygon?.properties?.cadaster_number) {
      await dispatch(
        fetchSelectedPolygon({ cn: polygon.properties.cadaster_number, selectedLayers }),
      );
      return;
    }

    const savedPolygon = drawPolygons.find(pol => pol.favoritePolygonId === polygon.id);

    let newPolygon: PolygonType;

    const center = getCenter(geoJSONToCoords(polygon.geometry)[0] as any);

    if (savedPolygon) {
      newPolygon = savedPolygon;
    } else {
      user?.isManager && dispatch(setNotSavedDrawFavoritePolygonId(polygon.id));
      newPolygon = {
        ...polygon,
        id: user?.isManager ? undefined : polygon.id,
        center: `${center[0]},${center[1]}`,
      };
    }

    removeQueryParams();
    dispatch(setOpenedDropBlock({ info: false }));
    dispatch(setIsNewPolygonFormOpen(true));
    dispatch(setFlyTo(center));
    dispatch(setSelectedPolygon({ polygon: newPolygon, reset: true }));
  };

  return (
    <>
      <div className="w-full dark:bg-blue-500 min-h-full pb-40">
        {isLoadingFavoritePolygons ? (
          <div className="w-full h-full flex items-center justify-center py-10">
            <Loader classNames="w-20 h-20" />
          </div>
        ) : null}

        {!favoritePolygons.length && !isLoadingFavoritePolygons ? (
          <div
            className={
              'py-10 text-center w-full text-center text-sm t-lg:text-base ' +
              'font-gothampro-400 dark:text-white'
            }
          >
            Не найдено
          </div>
        ) : null}

        {!isLoadingFavoritePolygons && favoritePolygons.length
          ? favoritePolygons.map((polygon, i) => {
              const status = statuses.find(status => status.id === polygon?.properties?.status);

              if (!status) {
                return <Fragment key={`zone-favorite-${i}`} />;
              }

              return (
                <div
                  key={`zone-favorite-${i}`}
                  className={
                    'border-b border-grey-400 dark:border-blue-700 px-4 py-2.5 cursor-pointer ' +
                    'transition-full hover:bg-orange-rgba-100 dark:hover:bg-blue-700'
                  }
                  onClick={async () => {
                    await handleSelectZone(polygon);
                  }}
                >
                  {user?.isLeader ? (
                    <CloseIcon
                      onClick={e => {
                        e?.stopPropagation();
                        setIsOpenRemoveModal(polygon);
                      }}
                      className="w-3 h-3 fill-grey-500 cursor-pointer ml-auto"
                    />
                  ) : null}
                  <AppToolTip
                    checkWidth
                    classNames="text-black-100 font-gothampro-400 text-sm dark:text-white mb-4 line-clamp-2 mb-2"
                    text={polygon?.properties?.name || polygon?.properties?.cadaster_number}
                  />
                  <div className="text-extra-xs font-gothampro-400 text-grey-500 mb-0.5">
                    Комментарий
                  </div>
                  <div
                    className={
                      'p-2 rounded-md bg-grey-1900 border border-grey-1900 text-extra-xs ' +
                      'font-gothampro-400 mb-2 dark:border-blue-200 dark:text-white dark:bg-blue-300'
                    }
                  >
                    <div className="w-full h-auto">
                      {parse(polygon?.properties?.comments || '')}
                    </div>
                  </div>
                  <ZoneTag
                    isActive
                    noShadow
                    category={status}
                    classNames="mx-auto w-full max-w-full text-center"
                  />
                </div>
              );
            })
          : null}
      </div>

      {isOpenRemoveModal ? (
        <div className="fixed w-full h-full flex center bg-black-rgba-200 z-2000 inset-0 p-4">
          <div
            className={
              'w-full max-w-[30rem] bg-white rounded-xl p-4 flex flex-col gap-5 t-sm:gap-8 ' +
              'dark:bg-blue-300'
            }
          >
            <CloseIcon
              className="ml-auto cursor-pointer w-4 h-4 dark:fill-white"
              onClick={handleCloseRemoveModal}
            />
            <div
              className={
                'font-gothampro-400 t-sm:text-lg text-black-100 text-center t-sm:max-w-[80%] ' +
                'mx-auto dark:text-white'
              }
            >
              {`Вы действительно хотите удалить полигон ${
                isOpenRemoveModal?.cadaster_number || isOpenRemoveModal.name || ''
              }?`}
            </div>
            <div className="flex gap-4 w-fit mx-auto">
              <Button variant="grey" onClick={handleCloseRemoveModal}>
                Отмена
              </Button>
              <Button variant="blue" onClick={() => handleDeletePolygon(isOpenRemoveModal)}>
                Удалить
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
