import { FC, useState } from 'react';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { getSrc } from 'src/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { PolygonImageType } from 'src/config/types';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './styles.css';

interface DashboardSingleCarouselProps {
  images: PolygonImageType[];
  classNames?: string;
}

export const DashboardSingleCarousel: FC<DashboardSingleCarouselProps> = ({
  images,
  classNames,
}) => {
  const { width } = useWindowDimensions();

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const getArrow = (type: 'custom-next' | 'custom-prev') => (
    <div className={cn({ flex: type === 'custom-next' }, 'min-w-[2.5rem] transition-full', type)}>
      <div
        className={cn(
          { 'ml-auto': type === 'custom-next' },
          'center w-6 h-11 bg-grey-2400 hover:bg-grey-500 dark:bg-blue-700 rounded cursor-pointer',
        )}
      >
        <ArrowIcon
          className={cn(
            {
              '-rotate-90': type === 'custom-prev',
              'rotate-90': type === 'custom-next',
            },
            'w-4 h-4 stroke-path-white',
          )}
        />
      </div>
    </div>
  );

  return (
    <div className="w-full flex flex-col gap-4">
      <div className={cn('w-full', classNames)}>
        <Swiper
          spaceBetween={16}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full h-full rounded-lg"
        >
          {images.map((image, i) => (
            <SwiperSlide key={`carousel-single-main-${i}`}>
              <img src={getSrc(image.image)} alt="" className="block w-full h-full object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="h-16 flex items-center">
        {getArrow('custom-prev')}
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={width <= 768 ? 8 : 16}
          slidesPerView={width >= 1440 ? 5 : width >= 1024 ? 7 : width >= 520 ? 5 : 3}
          freeMode
          watchSlidesProgress
          navigation={{
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="w-full h-full navigate-slider"
        >
          {images.map((image, i) => (
            <SwiperSlide key={`carousel-single-nav-${i}`}>
              <img src={getSrc(image.image)} alt="" className="block w-full h-full object-cover" />
            </SwiperSlide>
          ))}
        </Swiper>
        {getArrow('custom-next')}
      </div>
    </div>
  );
};
