import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import cn from 'classnames';

import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';

interface CheckboxIsEditProps {
  name: string;
  control: Control<any>;
}

export const CheckboxIsEdit: FC<CheckboxIsEditProps> = ({ name, control }) => {
  const {
    field: { value, ...rest },
  } = useController({
    name,
    control,
  });

  return (
    <label className="flex cursor-pointer items-center">
      <div
        className={cn(
          { 'bg-green-600': Boolean(value) },
          'flex items-center justify-center w-6 h-6 border border-green-100 rounded',
        )}
      >
        <CheckIcon className={cn({ 'opacity-0': !Boolean(value) }, 'w-4 h-4 fill-white')} />
      </div>
      <div className="font-gothampro-400 text-sm dark:text-white ml-2.5">
        {value ? 'Обременение снято' : 'Снять обременение'}
      </div>
      <input type="checkbox" className="hidden" {...rest} />
    </label>
  );
};
