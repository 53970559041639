import { FC, useState } from 'react';
import { setSelectedLayers } from 'src/store/map';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { layersListByGroups } from 'src/config/data';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

export const LayersList: FC = () => {
  const { selectedLayers } = useAppSelector(state => state.map);

  const [openedBlocks, setOpenedBlocks] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  const handleOpenBlock = (i: number) => {
    let opened: number[] = [];
    if (openedBlocks.includes(i)) {
      opened = openedBlocks.filter(el => el !== i);
    } else {
      opened = [...openedBlocks, i];
    }

    setOpenedBlocks(opened);
  };

  const handleSelect = (id: number) => {
    let layers: number[];
    if (selectedLayers.includes(id)) {
      layers = selectedLayers.filter(el => el !== id);
    } else {
      layers = [...selectedLayers, id];
    }

    dispatch(setSelectedLayers(layers));
  };

  return (
    <>
      {layersListByGroups.map((layerGroup, i) => {
        return (
          <div className="w-full" key={`layer-group-${i}`}>
            {layerGroup.name ? (
              <div
                className={
                  'px-2.5 py-4 flex items-center justify-between bg-grey-1300 dark:bg-grey-1400 ' +
                  'border-b border-white-100'
                }
                onClick={() => handleOpenBlock(i)}
              >
                <div className="text-xs font-gothampro-500 dark:text-white">{layerGroup.name}</div>
                <ArrowIcon
                  className={cn(
                    { 'rotate-180': !openedBlocks.includes(i) },
                    'w-4 h-4 dark:stroke-path-white transition-full',
                  )}
                />
              </div>
            ) : null}

            <div className={cn({ hidden: !openedBlocks.includes(i) && layerGroup.name })}>
              {layerGroup.list.map((layer, n) => {
                const isActive = selectedLayers.includes(layer.id);
                return (
                  <div
                    key={`layer-item-${n}`}
                    className={cn(
                      { 't-lg:border-t': !i, 'border-b': i !== layersListByGroups.length - 1 },
                      'border-white-100 p-2.5 hover:bg-grey-1300 dark:hover:bg-blue-700',
                      'flex justify-between cursor-pointer',
                    )}
                    onClick={() => handleSelect(layer.id)}
                  >
                    <div className="text-xs font-gothampro-400 dark:text-white">{layer.label}</div>
                    <div
                      className={cn(
                        'min-w-[2rem] w-8 h-4 rounded-full bg-grey-1100 dark:bg-blue-300 flex ' +
                          'border border-grey-1100 dark:border-blue-300 ' +
                          'shadow-greys-300 dark:shadow-blues-300',
                      )}
                    >
                      <div
                        className={cn(
                          { 'opacity-0': isActive },
                          'w-full square rounded-full bg-grey-1500 dark:bg-blue-700 transition-full',
                        )}
                      />
                      <div
                        className={cn(
                          { 'opacity-0': !isActive },
                          'w-full square rounded-full bg-orange-100 dark:bg-blue-100 transition-full',
                        )}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
