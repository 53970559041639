import { FC, Fragment } from 'react';
import { numberWithSpaces } from 'src/utils';
import { Input } from 'src/components/ui/Input';
import { PolygonType } from 'src/config/types';

import cn from 'classnames';

interface DashboardSingleBannerProps {
  isEdit: boolean;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  polygon: PolygonType;
  classNames?: string;
}

export const DashboardSingleBanner: FC<DashboardSingleBannerProps> = ({
  isEdit,
  register,
  polygon,
  classNames,
}) => {
  const address = polygon?.address || '';
  const coords = polygon.center?.split(',') || [];

  const predictCost = polygon?.predict_cost || '';
  const expositionCost = polygon?.exposition_cost || '';

  const arr = [
    { name: 'Прогнозируемая стоимость', value: predictCost, field: 'predict_cost' },
    { name: 'Цена для экспозиции', value: expositionCost, field: 'exposition_cost' },
  ];

  return (
    <div className={cn('w-full', classNames)}>
      <div className="w-full p-2.5 t-lg:p-5 bg-white-200 dark:bg-blue-800 rounded-lg">
        <div className="dark:text-white font-gothampro-500 text-lg t-lg:text-3xl">
          Объект, {polygon.cadaster_number}
        </div>

        <div
          className={cn(
            { 'mb-3 t-lg:mb-7': predictCost || expositionCost || isEdit },
            'text-xs t-lg:text-base font-gothampro-400 text-grey-2700 dark:text-blue-900',
          )}
        >{`${address ? `${address}, ` : ''}${coords[0]}°, ${coords[1]}°`}</div>

        <div className="flex flex-col gap-2 t-lg:gap-4">
          {!isEdit
            ? arr.map((el, i) =>
                el?.value ? (
                  <div
                    key={`polygon-custom-price-${i}`}
                    className="w-full flex justify-between dark:text-white items-center"
                  >
                    <div className="text-xs t-lg:text-base font-gothampro-400">{el.name}</div>
                    <div className="font-gothampro-500 text-base t-lg:text-2xl">
                      {numberWithSpaces(el.value)}
                    </div>
                  </div>
                ) : (
                  <Fragment key={`polygon-custom-price-${i}`} />
                ),
              )
            : null}

          {isEdit
            ? arr.map((el, i) => (
                <div
                  key={`polygon-custom-price-${i}`}
                  className={
                    'w-full flex flex-col m-md:flex-row justify-between dark:text-white ' +
                    'm-md:items-center gap-1 t-sm:gap-4'
                  }
                >
                  <div className="text-xs t-lg:text-base font-gothampro-400">{el.name}</div>
                  <div className="w-full m-md:w-1/2">
                    <Input
                      data={{ name: el.field, placeholder: el.name, required: false }}
                      register={register}
                      type="number"
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      {/*<div className="w-full flex gap-7">*/}
      {/*  <Button variant="blue" size="big" classNames="w-1/2">*/}
      {/*    Согласовать*/}
      {/*  </Button>*/}
      {/*  <Button variant={darkMode ? 'grey' : 'blueOutline'} size="big" classNames="w-1/2">*/}
      {/*    Отклонить*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </div>
  );
};
