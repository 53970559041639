import { FC } from 'react';
import { CategoryType, HistoryModificationItemType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';
import { ZoneTag } from 'src/components/ui/ZoneTag';
import parse from 'html-react-parser';

import { ReactComponent as SmallListIcon } from 'src/assets/icons/kit/small-list.svg';
import { ReactComponent as ChatIcon } from 'src/assets/icons/kit/chat.svg';
import { ReactComponent as UserIcon } from 'src/assets/icons/kit/user.svg';
import { ReactComponent as KeyIcon } from 'src/assets/icons/kit/key.svg';
import { ReactComponent as PathIcon } from 'src/assets/icons/kit/path.svg';
import { ReactComponent as ClusterIcon } from 'src/assets/icons/kit/cluster.svg';
import { ReactComponent as LayersIcon } from 'src/assets/icons/kit/layers.svg';
import { ReactComponent as MapIcon } from 'src/assets/icons/kit/map.svg';

interface DashboardSingleHistoryItemProps extends HistoryModificationItemType {
  isLast?: boolean;
  isComments?: boolean;
}

const dictionary = {
  comments: 'комментарий',
  status: 'статус',
  substatus: 'подстатус',
  category: 'группу',
  owner: 'правообладателя',
  type_owner: 'вид права',
  block_status: 'направление',
  pzz: 'ПЗЗ',
  master_plan: 'генеральный план',
};

export const DashboardSingleHistoryItem: FC<DashboardSingleHistoryItemProps> = ({
  changes,
  field,
  isLast,
  isComments,
}) => {
  const { statuses, blockStatuses, categories, owners, typeOwners } = useAppSelector(
    state => state.categories,
  );

  const iconClassName = 'fill-grey-3200 w-3 h-3';

  const icons = {
    status: <SmallListIcon className={iconClassName} />,
    comments: <ChatIcon className={iconClassName} />,
    owner: <UserIcon className={iconClassName} />,
    type_owner: <KeyIcon className={iconClassName} />,
    block_status: <PathIcon className={iconClassName} />,
    category: <ClusterIcon className={iconClassName} />,
    pzz: <LayersIcon className={iconClassName} />,
    master_plan: <MapIcon className={iconClassName} />,
  };

  const list = {
    status: statuses,
    category: categories,
    owner: owners,
    type_owner: typeOwners,
    block_status: blockStatuses,
  };

  const getTime = (date: Date) => {
    const hour = date.getHours().toString();
    const min = date.getMinutes().toString();

    return `${hour.padStart(2, '0')}:${min.padStart(2, '0')}`;
  };

  const getCategory = (value: string, list: CategoryType[]) =>
    list.find(el => el?.id?.toString() === value?.toString());

  if (field === 'substatus' || field === 'geometry') {
    return <></>;
  }

  return (
    <div className="flex mb-3 relative">
      {!isLast && !isComments && (
        <div
          className="border-l border-grey-500 left-4 absolute top-8"
          style={{ height: 'calc(100% - 20px)' }}
        />
      )}

      {isComments ? (
        changes.map(({ changed_at, new_value, old_value }) => (
          <div
            key={`change-${changed_at}`}
            className={
              'dark:text-white font-gothampro-400 text-xs ml-10 rounded mb-3 p-3 ' +
              'border border-grey-3300'
            }
          >
            {new_value ? parse(new_value) : parse(old_value)}
          </div>
        ))
      ) : (
        <>
          <div
            className={
              'flex items-center justify-center w-8 h-8 flex-shrink-0 border border-grey-3200 ' +
              'rounded-full mr-2'
            }
          >
            {icons[field] || '-'}
          </div>
          <div className="pt-1">
            {changes.map(({ changed_at, new_value, old_value }) => {
              let oldCategory: CategoryType | undefined;
              let newCategory: CategoryType | undefined;

              const categoryList = list?.[field];

              if (categoryList) {
                oldCategory = getCategory(old_value, categoryList);
                newCategory = getCategory(new_value, categoryList);
              }

              return (
                <div
                  key={`change-${changed_at}`}
                  className="flex items-center text-grey-2700 flex-wrap gap-y-2 font-gothampro-400 text-sm mb-3"
                >
                  {field === 'comments' ? (
                    <div className="mr-2">изменил комментарий</div>
                  ) : (
                    <>
                      <div className="mr-2">{old_value ? 'изменил' : 'установил'}</div>
                      <div className="mr-2">{dictionary[field]}</div>
                      {old_value && <div className="mr-2">с</div>}

                      {old_value && (
                        <div className="mr-2 dark:text-white">
                          {oldCategory ? (
                            <ZoneTag
                              fitWidth
                              category={oldCategory}
                              classNames="pointer-events-none"
                            />
                          ) : (
                            parse(old_value)
                          )}
                        </div>
                      )}

                      {old_value && <div className="mr-2">на</div>}

                      <div className="mr-2 dark:text-white">
                        {newCategory ? (
                          <ZoneTag
                            fitWidth
                            category={newCategory}
                            classNames="pointer-events-none"
                          />
                        ) : (
                          parse(new_value)
                        )}
                      </div>
                    </>
                  )}

                  <div>{getTime(new Date(changed_at))}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
