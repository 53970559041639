import { FC, useEffect } from 'react';
import { setSelectedPolygon, setSelectedPolygonGeometry } from 'src/store/polygons';
import { setResetForm } from 'src/store/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { coordsToArray, getCenter, getCoordsWithLast, stringToCoords } from 'src/utils';
import { useAuth } from 'src/hooks/useAuth';
import { coordsToGeoJSON } from 'src/utils/helpers';
import { FeatureGroup, useMap } from 'react-leaflet';
import { initialPolygon } from 'src/config/data';
import { PolygonType } from 'src/config/types';
import { EditControl } from 'react-leaflet-draw';
import { setFlyTo } from 'src/store/map';

import L from 'leaflet';

export const LeafletMapEditControl: FC = () => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);
  const { polygonEdit } = useAppSelector(state => state.common);
  const { isBurgerOpen } = useAppSelector(state => state.common);

  const dispatch = useAppDispatch();

  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const map = useMap();

  const onCreate = async e => {
    const { layer } = e;

    const latLngs = layer?.getLatLngs()?.[0];
    const square = L?.['GeometryUtil']?.geodesicArea(latLngs);

    const coords = getCoordsWithLast(coordsToArray(latLngs));
    const center = getCenter(coords);
    const newPolygon: PolygonType = {
      ...initialPolygon,
      square,
      center: `${center[0]},${center[1]}`,
      geometry: coordsToGeoJSON([coords]),
    };

    dispatch(setSelectedPolygon({ polygon: newPolygon, reset: true }));
    dispatch(setResetForm(true));
    dispatch(setFlyTo(stringToCoords(`${center[0]},${center[1]}` || '')));
    map.removeLayer(layer);
  };

  const onEdit = e => {
    e?.layers?.eachLayer(a => {
      const latLngs = a?.getLatLngs()?.[0];
      // @ts-ignore
      const square = L?.GeometryUtil?.geodesicArea(latLngs);
      const coordinates = coordsToArray(latLngs);

      dispatch(
        setSelectedPolygonGeometry({
          geometry: coordsToGeoJSON([[...coordinates, coordinates[0]]]),
          square,
        }),
      );
    });
  };

  useEffect(() => {
    const buttons = document.querySelector('.leaflet-top.leaflet-right') as HTMLDivElement;
    const buttonDraw = document.querySelector('.leaflet-top.leaflet-left') as HTMLDivElement;

    if (buttons) {
      buttons.style.top = isBurgerOpen ? '318px' : '';
    }
    if (buttonDraw) {
      buttonDraw.style.top = isBurgerOpen ? '318px' : '';
    }
  }, [isBurgerOpen]);

  return (
    <FeatureGroup>
      {!selectedPolygon && !user?.isUser ? (
        <EditControl
          position={width >= 1024 ? 'topright' : 'topleft'}
          onCreated={onCreate}
          onEdited={onEdit}
          draw={{
            polygon: polygonEdit
              ? false
              : {
                  allowIntersection: false,
                  shapeOptions: { color: 'blue' },
                  showLength: true,
                  metric: ['km', 'm'],
                },
            rectangle: false,
            circle: false,
            polyline: false,
            marker: false,
            circlemarker: false,
          }}
          edit={{ remove: false, edit: false }}
        />
      ) : null}
    </FeatureGroup>
  );
};
