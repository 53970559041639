import { FC } from 'react';
import { NewFavoritePolygonForm } from 'src/components/Forms/NewFavoritePolygonForm';
import { stringToCoords } from 'src/utils';
import { useAppSelector } from 'src/hooks/useRedux';
import { Marker, Tooltip } from 'react-leaflet';

import L from 'leaflet';
import MarkerIcon from 'src/assets/icons/marker.svg';

export const LeafletMapFavoritePolygonPopUp: FC = () => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);

  if (!selectedPolygon) {
    return <></>;
  }

  const myIcon = L.icon({
    iconUrl: MarkerIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
  });

  return (
    <Marker
      icon={myIcon}
      position={stringToCoords(
        selectedPolygon?.center || selectedPolygon?.properties?.center || '',
      )}
    >
      <Tooltip interactive opacity={1} direction="top" permanent className="favorite-popup">
        <NewFavoritePolygonForm />
      </Tooltip>
    </Marker>
  );
};
