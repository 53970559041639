import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { ProtectedContainer } from 'src/containers/ProtectedContainer';
import { useAppSelector } from 'src/hooks/useRedux';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoadingUser } = useAppSelector(state => state.common);
  const { user } = useAuth();

  if (!user && !isLoadingUser) {
    return <Navigate to={`/${window.location.search || ''}`} />;
  }

  return <ProtectedContainer>{children}</ProtectedContainer>;
};
