import { FC, Fragment } from 'react';
import { useAppSelector } from 'src/hooks/useRedux';
import { Modal } from 'src/components/ui/Modal';
import { isToday } from 'src/utils';
import { monthsRU } from 'src/components/DashboardSIngle/DashboardSingleHistory/constants';
import { DashboardSingleHistoryItem } from 'src/components/DashboardSIngle/DashboardSingleHistoryItem';

interface DashboardSingleHistoryModal {
  type: 'comments' | 'all';
  onClose: () => void;
}

export const DashboardSingleHistoryModal: FC<DashboardSingleHistoryModal> = ({ type, onClose }) => {
  const { history } = useAppSelector(state => state.polygons);

  const getDate = (date: Date) => {
    const day = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${day} ${monthsRU[month]} ${year}`;
  };

  const text = type === 'all' ? 'История изменений' : 'История комментариев';

  return (
    <Modal onClose={onClose}>
      <div className="font-gothampro-500 dark:text-white mb-6">{text}</div>

      <div className="flex flex-col gap-8 max-h-[80dvh] overflow-auto pr-2 mt-6">
        {!history?.length ? (
          <div className="dark:text-white font-gothampro-400 w-full text-center py-10 text-sm">
            {`${text} пуста`}
          </div>
        ) : null}

        {history.map(({ date, changes }) => {
          const checkDate = new Date(date);
          const today = isToday(checkDate);
          let hasComment = false;

          if (type === 'comments') {
            changes.forEach(({ modifications }) => {
              modifications.forEach(({ field }) => {
                if (field === 'comments') {
                  hasComment = true;
                }
              });
            });
          }

          if (type === 'comments' && !hasComment) {
            return <Fragment key={`day-${date}`} />;
          }

          return (
            <div key={`day-${date}`}>
              <div className="dark:text-white font-gothampro-500 mb-3">
                {today ? 'Сегодня' : getDate(checkDate)}
              </div>
              {changes.map(({ modifications, user }) => {
                const userArr = user.toUpperCase().split(' ');
                const initials = `${userArr[0][0]}${userArr[1][0]}`;

                return (
                  <Fragment key={`user-${date}-${user}`}>
                    <div className="flex items-center mb-3">
                      <div
                        className={
                          'w-8 h-8 flex-shrink-0 flex items-center justify-center ' +
                          'bg-orange-100 rounded-full mr-2.5'
                        }
                      >
                        <div className="text-white font-gothampro-500 text-xs">{initials}</div>
                      </div>
                      <div className="font-gothampro-400 dark:text-white">{user}</div>
                    </div>
                    {modifications.map((item, i) =>
                      type === 'comments' && item.field !== 'comments' ? (
                        <Fragment key={`modification-${date}-${user}-${item.field}`} />
                      ) : (
                        <DashboardSingleHistoryItem
                          key={`modification-${date}-${user}-${item.field}`}
                          {...item}
                          isComments={type === 'comments'}
                          isLast={modifications.length - 1 === i}
                        />
                      ),
                    )}
                  </Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
