import { FC } from 'react';
import { ZoneTag } from 'src/components/ui/ZoneTag';
import { PolygonsFilterType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';

interface TypeOwnersFilterProps {
  handleSelect: (id: number, filterName: keyof PolygonsFilterType) => void;
  tempFilter: PolygonsFilterType;
}

export const TypeOwnersFilter: FC<TypeOwnersFilterProps> = ({ handleSelect, tempFilter }) => {
  const { typeOwners } = useAppSelector(state => state.categories);

  return (
    <div className="flex flex-wrap gap-2">
      {typeOwners.map((typeOwner, i) => (
        <ZoneTag
          key={`type-owner-filter-${typeOwner.id}`}
          isActive={tempFilter?.typeOwners?.includes(typeOwner?.id || 0)}
          index={i}
          fullWidth
          onClick={() => typeOwner?.id && handleSelect(typeOwner.id, 'typeOwners')}
          category={typeOwner}
        />
      ))}
    </div>
  );
};
