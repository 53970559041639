import { FC, useEffect, useRef } from 'react';
import { CheckBox } from 'src/components/ui/CheckBox';
import { ZoneTag } from 'src/components/ui/ZoneTag';
import { numberWithSpaces } from 'src/utils';
import { AppToolTip } from 'src/components/ui/AppToolTip';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { getPolygonCategories, getSubStatusIcon } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { PolygonType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';
import { dashboardTableItems } from 'src/config/data';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

interface DashboardTableItemProps {
  isChecked: boolean;
  setChecked: (polygon: PolygonType, isChecked: boolean) => void;
  polygon: PolygonType;
  withObserver?: boolean;
  loadMore?: () => void;
}

export const DashboardTableItem: FC<DashboardTableItemProps> = ({
  isChecked,
  setChecked,
  polygon,
  withObserver,
  loadMore,
}) => {
  const categories = useAppSelector(state => state.categories);
  const { width } = useWindowDimensions();
  const { user } = useAuth();
  const ref = useRef<HTMLDivElement>(null);

  const observer = withObserver
    ? new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          handleLoadMore();
        }
      })
    : null;

  const handleLoadMore = () => {
    if (observer && withObserver && ref?.current) {
      observer.unobserve(ref.current);
      loadMore && loadMore();
    }
  };

  useEffect(() => {
    if (observer && withObserver && ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (observer && withObserver && ref?.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const getText = (text: string) => (
    <div className="font-gothampro-500 dark:text-white text-ultra-xs t-lg:text-xs whitespace-pre-wrap">
      {text}
    </div>
  );

  const { blockStatus, status, subStatus, category, owner, typeOwner } = getPolygonCategories(
    polygon,
    categories,
  );

  const handleSetChecked = () => setChecked(polygon, isChecked);

  const zoneTagSize = width >= 1024 ? 'big' : 'small';

  return (
    <Link
      to={`/dashboard/${polygon.id}`}
      className={cn(
        {
          'bg-grey-2200 dark:bg-blue-700 hover:bg-grey-2300 hover:dark:bg-blue-200': !isChecked,
          'bg-grey-2300 dark:bg-blue-200': isChecked,
        },
        'min-h-[2rem] h-[2rem] t-lg:min-h-[3.5rem] t-lg:h-[3.5rem] py-1 flex items-center group',
      )}
    >
      {/* 0 */}
      <div className={dashboardTableItems[0].className} ref={ref}>
        <CheckBox isActive={isChecked} onClick={handleSetChecked} />
      </div>

      {/* 1 */}
      <div className={dashboardTableItems[1].className}>
        {getText(polygon.cadaster_number || '')}
      </div>

      {/* 2 */}
      <div className={dashboardTableItems[2].className}>
        {blockStatus ? <ZoneTag size={zoneTagSize} fullWidth category={blockStatus} /> : null}
      </div>

      {/* 3 */}
      <div className={dashboardTableItems[3].className}>
        {category ? (
          <ZoneTag
            size={zoneTagSize}
            isActive
            noShadow
            whiteText
            classNames="max-w-[7rem] t-lg:max-w-[10rem]"
            category={{ ...category, color: user?.is_colorpicker ? category.color : '#FF5500' }}
          />
        ) : null}
      </div>

      {/* 4 */}
      <div className={dashboardTableItems[4].className}>
        <AppToolTip
          checkWidth
          text={polygon.address}
          noTruncate
          classNames="dark:text-white text-ultra-xs t-lg:text-xs font-gothampro-400 line-clamp-3"
        />
      </div>

      {/* 5 */}
      <div className={dashboardTableItems[5].className}>
        {getText(`${numberWithSpaces(polygon?.square || '')} кв. м.` || '')}
      </div>

      {/* 6 */}
      <div className={dashboardTableItems[6].className}>{getText(typeOwner?.name || '')}</div>

      {/* 7 */}
      <div className={dashboardTableItems[7].className}>{getText(owner?.name || '')}</div>

      {/* 8 */}
      <div className={cn(dashboardTableItems[8].className, 'flex items-center gap-1')}>
        {status ? (
          <ZoneTag size={zoneTagSize} fullWidth isActive whiteText noShadow category={status} />
        ) : null}
        {subStatus && status ? (
          <div
            className={
              'rounded text-ultra-xs t-lg:text-extra-xs flex items-center text-white px-2 py-1.5 ' +
              'font-gothampro-500 truncate'
            }
            style={{ color: status.color }}
          >
            {getSubStatusIcon(status.color || '#000', subStatus.icon || 'home')}
            {subStatus.name}
          </div>
        ) : null}
      </div>

      {/* 9 */}
      <div className={dashboardTableItems[9].className}>
        {getText(`${numberWithSpaces(polygon.reestrInfo?.feature?.attrs?.cad_cost || '')} ₽` || '')}
      </div>

      {/* 10 */}
      <div className={dashboardTableItems[10].className}>
        <AppToolTip
          checkWidth
          text={polygon.master_plan || ''}
          noTruncate
          classNames="dark:text-white text-ultra-xs t-lg:text-xs font-gothampro-400 line-clamp-3"
        />
      </div>

      {/* 11 */}
      <div className={dashboardTableItems[11].className}>
        <AppToolTip
          checkWidth
          text={polygon.pzz || ''}
          noTruncate
          classNames="dark:text-white text-ultra-xs t-lg:text-xs font-gothampro-400 line-clamp-3"
        />
      </div>

      {/* 12 */}
      <div className={dashboardTableItems[12].className}>
        <div
          className={cn(
            {
              'bg-grey-2500 dark:bg-blue-800': isChecked,
              'bg-grey-2400 dark:bg-transparent': !isChecked,
            },
            'w-5 h-8 t-lg:h-11 rounded-l center group-hover:bg-blue-100',
          )}
        >
          <ArrowIcon className="w-3 h-3 t-lg:w-5 t-lg:h-5 stroke-path-white rotate-90 -mr-0.5" />
        </div>
      </div>
    </Link>
  );
};
