import { Fragment, FC } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { useAppSelector } from 'src/hooks/useRedux';
import { layersList } from 'src/config/data';
import { useMap } from 'react-leaflet';

export const LeafletMapLayers: FC = () => {
  const { selectedLayers } = useAppSelector(state => state.map);
  const mapHook = useMap();

  return (
    <>
      {layersList.map((layer, i) => {
        if (!layer.url) {
          return <Fragment key={`map-wms-layer-${i}`} />;
        }

        if (!selectedLayers.includes(layer.id)) {
          return <Fragment key={`map-wms-layer-${i}`} />;
        }

        const zoom = mapHook.getZoom();

        if (layer.zoomVisible.from < zoom || layer.zoomVisible.to > zoom) {
          return <Fragment key={`map-wms-layer-${i}`} />;
        }

        return (
          <WMSTileLayer
            maxZoom={19}
            zIndex={layer.id === 2 ? 2 : 1}
            tileSize={1024}
            key={`map-wms-layer-${i}`}
            url={layer.url}
            opacity={layer.opacity}
            format=""
          />
        );
      })}
    </>
  );
};
