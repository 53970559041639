import { MouseEvent, FC, ReactNode } from 'react';
import cn from 'classnames';

interface ButtonProps {
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  variant: 'blue' | 'blueOutline' | 'grey' | 'orange';
  size?: 'big' | 'small' | 'base';
  as?: 'button' | 'div';
  classNames?: string;
  children?: ReactNode;
}

export const Button: FC<ButtonProps> = ({
  onClick,
  variant,
  size = 'small',
  as = 'button',
  classNames,
  children,
}) => {
  const variantsStyles = {
    blue: 'text-white bg-blue-100',
    blueOutline: 'text-blue-100 border border-blue-100 bg-white-200',
    grey: 'text-white bg-grey-500 dark:bg-blue-200',
    orange: 'text-white bg-orange-100',
  };

  const handleClick = e => {
    onClick && onClick(e);
  };

  return as === 'button' ? (
    <button
      className={cn(
        {
          'rounded-md py-1.5 t-lg:py-2 px-2.5 t-lg:px-3 text-extra-xs': size === 'small',
          'rounded-md t-lg:py-3 py-2 t-lg:px-4 px-2.5 t-lg:text-sm text-xs': size === 'big',
          'rounded p-2': size === 'base',
        },
        'font-gothampro-500 cursor-pointer',
        variantsStyles[variant],
        classNames,
      )}
      onClick={handleClick}
    >
      {children}
    </button>
  ) : (
    <div
      className={cn(
        {
          'py-1.5 t-lg:py-2 px-2.5 t-lg:px-3 text-extra-xs': size === 'small',
          't-lg:py-3 py-2 t-lg:px-4 px-2.5 t-lg:text-sm text-xs': size === 'big',
        },
        'rounded-md font-gothampro-500 cursor-pointer',
        variantsStyles[variant],
        classNames,
      )}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
