import {
  DashboardPolygonsFilterType,
  LayersType,
  PolygonSelectsType,
  PolygonsFilterType,
  PolygonType,
} from 'src/config/types';
import { MapContainerProps } from 'react-leaflet';
import { BASE_URL } from 'src/config/index';

export const mapData: MapContainerProps = {
  maxBoundsViscosity: 1.0,
  zoomControl: false,
  zoom: 16,
  maxZoom: 19,
  minZoom: 3,
  center: [55.74730858490201, 37.60705947875977],
};

export const polygonFieldsForMap: (keyof PolygonType)[] = [
  'id',
  'category',
  'owner',
  'status',
  'block_status',
  'type_owner',
  'substatus',
  'address',
  'cadaster_number',
  'square',
  'geometry',
  'center',
];

export const polygonTableFieldsForMap: (keyof PolygonType)[] = [
  'id',
  'category',
  'owner',
  'status',
  'block_status',
  'type_owner',
  'substatus',
  'address',
  'cadaster_number',
  'square',
  'reestrInfo',
  'center',
  'master_plan',
  'pzz',
];

export const filterKeys = {
  categories: 'category',
  statuses: 'status',
  subStatuses: 'substatus',
  blockStatuses: 'block_status',
  typeOwners: 'type_owner',
  owners: 'owner',
};

export const initialPolygonSelects: PolygonSelectsType = {
  category: null,
  status: null,
  subStatus: null,
  blockStatus: null,
  owner: null,
  typeOwner: null,
};

export const polygonSelectFields = [
  'name',
  'status',
  'category',
  'block_status',
  'owner',
  'substatus',
  'type_owner',
];

export const dashboardTableItems: {
  label: string;
  className: string;
  filter?: keyof PolygonsFilterType;
  dashboardFilter?: keyof DashboardPolygonsFilterType;
  sort?: keyof PolygonType;
}[] = [
  {
    label: 'checkBox',
    className:
      'px-1.5 t-lg:px-2.5 min-w-[2rem] w-[2rem] t-lg:min-w-[3rem] t-lg:w-[3rem] flex justify-end',
  },
  {
    label: 'Кадастровый номер',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8.5rem] t-lg:min-w-[14rem] w-[8.5rem] t-lg:w-[14rem]',
    sort: 'cadaster_number',
    dashboardFilter: 'cadaster_number',
  },
  {
    label: 'Направление',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8rem] t-lg:min-w-[15rem] w-[8rem] t-lg:w-[15rem]',
    filter: 'blockStatuses',
    sort: 'block_status',
  },
  {
    label: 'Группа',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8rem] t-lg:min-w-[11rem] w-[8rem] t-lg:w-[11rem]',
    filter: 'categories',
    sort: 'category',
  },
  {
    label: 'Адрес',
    className:
      'px-1.5 t-lg:px-2.5 min-w-[12rem] w-[12rem] t-lg:min-w-[12rem] t-lg:w-[12rem] d-lg:min-w-[16rem] d-lg:w-full',
    dashboardFilter: 'address',
  },
  {
    label: 'Площадь',
    className: 'px-1.5 t-lg:px-2.5 min-w-[6rem] t-lg:min-w-[10rem] w-[6rem] t-lg:w-[10rem]',
    sort: 'square',
    dashboardFilter: 'square',
  },
  {
    label: 'Вид права',
    className: 'px-1.5 t-lg:px-2.5 min-w-[6rem] t-lg:min-w-[10rem] w-[6rem] t-lg:w-[10rem]',
    filter: 'typeOwners',
    sort: 'type_owner',
  },
  {
    label: 'Правообладатель',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8rem] t-lg:min-w-[13rem] w-[8rem] t-lg:w-[13rem]',
    filter: 'owners',
    sort: 'owner',
  },
  {
    label: 'Статус',
    className: 'px-1.5 t-lg:px-2.5 min-w-[11rem] t-lg:min-w-[18rem] w-[11rem] t-lg:w-[18rem]',
    filter: 'statuses',
    sort: 'status',
  },
  {
    label: 'Стоимость',
    className: 'px-1.5 t-lg:px-2.5 min-w-[6rem] t-lg:min-w-[10rem] w-[6rem] t-lg:w-[10rem]',
    dashboardFilter: 'price',
  },
  {
    label: 'Ген план',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8.5rem] t-lg:min-w-[14rem] w-[8.5rem] t-lg:w-[14rem]',
    sort: 'master_plan',
    dashboardFilter: 'master_plan',
  },
  {
    label: 'ПЗЗ',
    className: 'px-1.5 t-lg:px-2.5 min-w-[8.5rem] t-lg:min-w-[14rem] w-[8.5rem] t-lg:w-[14rem]',
    sort: 'pzz',
    dashboardFilter: 'pzz',
  },
  { label: 'arrow', className: 'min-w-[1rem] w-[1rem] t-lg:min-w-[1.25rem] t-lg:w-[1.25rem]' },
];

export const sputnikTileUrl: string[] = [
  'http://4.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/png8?app_id=g7UuRR708Tsut4YSnDLy&token=fVzaDAdRK62zo3CuNcPtDg&lg=RUS',
];
export const schemaTileUrl: string[] = ['https://c.tile.openstreetmap.org/{z}/{x}/{y}.png?sw=1'];
export const mixTileUrl: string[] = [
  'http://4.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=g7UuRR708Tsut4YSnDLy&token=fVzaDAdRK62zo3CuNcPtDg&lg=RUS',
];

export const initialPolygon: PolygonType = {
  name: '',
  status: 0,
  comments: '',
  square: 0,
  center: '',
  geometry: '',
};

export const ruDrawOptions = {
  toolbar: {
    actions: {
      title: 'Cancel drawing',
      text: 'Отмена',
    },
    finish: {
      title: 'Finish drawing',
      text: 'Завершить',
    },
    undo: {
      title: 'Delete last point drawn',
      text: 'Удалить последнюю точку',
    },
    buttons: {
      polyline: 'Нарисовать линию',
      polygon: 'Нарисовать полигон',
      rectangle: 'Нарисовать квадрат',
      circle: 'Нарисовать круг',
      marker: 'Нарисовать метку',
      circlemarker: 'Нарисовать круглую метку',
    },
  },
  handlers: {
    circle: {
      tooltip: {
        start: 'Нажмите и ведите для создания круга.',
      },
      radius: 'Радиус',
    },
    circlemarker: {
      tooltip: {
        start: 'Нажмите на карту для установки круглой метки.',
      },
    },
    marker: {
      tooltip: {
        start: 'Нажмите на карту для установки метки.',
      },
    },
    polygon: {
      tooltip: {
        start: '',
        cont: '',
        end: '',
      },
    },
    polyline: {
      error: '<strong>Error:</strong> shape edges cannot cross!',
      tooltip: {
        start: 'Нажмите, чтобы начать рисовать линию.',
        cont: 'Нажмите, чтобы продолжить рисовать линию.',
        end: 'Нажмите на последнюю точку, что бы закончить рисовать линию.',
      },
    },
    rectangle: {
      tooltip: {
        start: 'Нажмите и ведите для создания прямоугольника.',
      },
    },
    simpleshape: {
      tooltip: {
        end: 'Кликните, чтобы закончить рисование.',
      },
    },
  },
};

export const ruEditOptions = {
  toolbar: {
    actions: {
      save: {
        title: 'Save changes',
        text: 'Сохранить',
      },
      cancel: {
        title: 'Cancel editing, discards all changes',
        text: 'Отмена',
      },
      clearAll: {
        title: 'Clear all layers',
        text: 'Удалить все',
      },
    },
    buttons: {
      edit: 'Редактировать',
      editDisabled: 'Недоступно',
      remove: 'Удалить слой',
      removeDisabled: 'Недоступно',
    },
  },
  handlers: {
    edit: {
      tooltip: {
        text: 'Перетаскивайте маркеры для редактирования объектов.',
        subtext: 'Нажмите кнопку Отмена, чтобы отменить изменения.',
      },
    },
    remove: {
      tooltip: {
        text: 'Нажмите на объект, который нужно удалить.',
      },
    },
  },
};

export const categoryFields = [
  { field: 'blockStatus', label: 'Направление' },
  { field: 'category', label: 'Группа объекта' },
  { field: 'owner', label: 'Имя правообладателя' },
  { field: 'typeOwner', label: 'Вид права' },
  { field: 'status', label: 'Статус' },
  { field: 'subStatus', label: 'Подстатус' },
];

export const landPlotsFields = [
  { name: 'Кадастровый номер', arr: ['cn'] },
  { name: 'Статус', arr: ['statecd'] },
  { name: 'Наименование', arr: ['name'] },
  { name: 'Категория земель', arr: ['category_type'] },
  { name: 'Кадастровая стоимость', arr: ['cad_cost', '^', 'cad_unit'] },
  { name: 'Площадь', arr: ['area_value', '^', 'area_unit', '^' + '(', 'area_type', ')'] },
  { name: 'Адрес', arr: ['address'] },
  { name: 'Назначение', arr: ['purpose'] },
  { name: 'Общая этажность', arr: ['floors'] },
  { name: 'Подземная этажность', arr: ['underground_floors'] },
  { name: 'Завершение строительства', arr: ['year_built'] },
  { name: 'Ввод в эксплуатацию', arr: ['year_used'] },
  { name: 'Кадастровый квартал', arr: ['kvartal_cn'] },
  { name: 'ВРИ по документу', arr: ['util_by_doc'] },
  { name: 'Дата определения КС', arr: ['date_cost'] },
  { name: 'Дата внесения сведений о КС', arr: ['cc_date_entering'] },
  { name: 'Идентификатор', arr: ['id'] },
  { name: 'Наименование', arr: ['name_zone'] },
];

export const objectInfoTranslate = {
  year_used: 'Год ввода в эксплуатацию',
  year_built: 'Год постройки',
  area_dev_unit: 'Единица разработки площади',
  oks_type: 'Тип ОКС (Объект капитального строительства)',
  purpose: 'Назначение',
  underground_floors: 'Подземные этажи',
  area_dev_type: 'Тип разработки площади',
  address: 'Адрес',
  application_date: 'Дата заявки',
  area_type: 'Тип площади',
  area_unit: 'Единица площади',
  area_value: 'Значение площади',
  cad_cost: 'Кадастровая стоимость',
  cad_unit: 'Кадастровая единица',
  category_type: 'Тип категории',
  cc_date_entering: 'Дата ввода CC',
  cn: 'Кадастровый номер',
  date_cost: 'Дата стоимости',
  fp: 'Форма собственности',
  id: 'Идентификатор',
  is_big: 'is_big',
  kvartal: 'Квартал',
  kvartal_cn: 'Кадастровый номер квартала',
  parcel: 'Участок',
  parcel_build: 'parcel_build',
  parcel_tour: 'parcel_tour',
  parcel_type: 'Тип участка',
  statecd: 'Код состояния',
  util_by_doc: 'Использование по документу',
  children: 'children',
  cc_date_approval: 'Дата утверждения категории земель',
  parcel_build_attrs: 'parcel_build_attrs',
  parcel_tour_attrs: 'parcel_tour_attrs',
  rifr: 'Свободен от прав третьих лиц',
  rifr_cnt: 'Контактное лицо',
  rifr_dep: 'Орган власти',
  rights_reg: 'Зарегистрированы права (да/нет)',
  rifr_dep_info: 'rifr_dep_info',
  sale: 'Принято решение о проведении торгов',
  sale_cnt: 'Контактное лицо',
  sale_date: 'Дата проведения торгов',
  sale_dep: 'Орган власти',
  sale_dep_uo: 'sale_dep_uo',
  sale_doc_date: 'sale_doc_date',
  sale_doc_num: 'sale_doc_num',
  sale_doc_type: 'sale_doc_type',
  sale_price: 'Начальная цена',
  floors: 'Этажи',
  purpose_name: 'Назначение',
  rayon: 'Район',
  reg_date: 'Дата регистрации',
  acnum: 'Номер счета',
  number_zone: 'Номер зоны',
  json_documents: 'Документы зоны',
  name_zone: 'Название зоны',
  rayon_ch: 'Код района',
  subtype: 'Подтип',
  cozone: 'Код зоны',
  rec_date: 'Дата записи',
  reestr_number_id: 'Номер в реестре',
  description: 'Описание',
  rayon_id: 'ID района',
  rayon_cn: 'Код района',
  name: 'Название',
  type: 'Тип',
};

export const layersList: LayersType[] = [
  {
    id: 1,
    imageLink: 'ZONESSelected',
    imageShow: [0, 1, 2, 6],
    label: 'Зоны с особыми условиями использования территории',
    url: `${BASE_URL}/api-layer/area_special/`,
    layerInfo: [
      { name: 'Зоны охраны природных объектов', color: '#c1ffa0' },
      { name: 'Зоны охраны искусственных объектов', color: '#9ad682' },
      { name: 'Зоны защиты населения', color: '#7ab26c' },
      { name: 'Прочие зоны с особыми условиями использования территории', color: '#6f9a5a' },
    ],
    zoomVisible: { from: 19, to: 11 },
  },
  {
    id: 2,
    imageLink: 'CadastreSelected',
    imageShow: [6, 7, 8, 9],
    label: 'Земельные участки',
    url: `${BASE_URL}/api-layer/land_plots/`,
    layerInfo: [
      { name: 'Земельные участки ', borderColor: '#fab8b6' },
      {
        name: 'Земельные участки, подлежащие образованию в соответствии с утвержденным проектом межевания территории ',
        borderColor: '#6ba3e8',
      },
    ],
    zoomVisible: { from: 19, to: 15 },
  },
  {
    id: 3,
    imageLink: 'CadastreSelected',
    imageShow: [0, 1, 2, 3, 4, 5],
    label: 'Здания, сооружения, объекты незавершенного строительства',
    url: `${BASE_URL}/api-layer/builds_structures/`,
    layerInfo: [
      { name: 'Здания', color: '#df7f7e' },
      { name: 'Сооружения', color: '#edbebd' },
      { name: 'Объекты незавершенного строительства', color: '#c02a2a' },
    ],
    zoomVisible: { from: 19, to: 15 },
  },
  {
    id: 4,
    imageLink: '',
    imageShow: [],
    label: 'Земельные участки, здания, сооружения',
    url: `${BASE_URL}/api-layer/land_structures/`,
    // url: 'special_objects',
    layerInfo: [],
    zoomVisible: { from: 19, to: 8 },
  },
  {
    id: 5,
    imageLink: '',
    imageShow: [],
    label: '',
    url: ``,
    layerInfo: [],
    zoomVisible: { from: 19, to: 8 },
  },
  {
    id: 6,
    imageLink: '',
    imageShow: [],
    label: '',
    url: ``,
    layerInfo: [],
    zoomVisible: { from: 19, to: 15 },
  },
  {
    id: 7,
    imageLink: 'BordersGKNSelected',
    imageShow: [0, 1, 5, 2, 6, 3, 7, 4],
    label: 'Государственная граница РФ',
    url: `${BASE_URL}/api-layer/borders/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
  },
  {
    id: 8,
    imageLink: 'ZONESSelected',
    imageShow: [0, 1, 2, 6],
    label: 'Территориальные зоны',
    url: `${BASE_URL}/api-layer/territorial_zones/`,
    // url: 'territorial_zones',
    layerInfo: [
      { name: 'Жилые зоны', color: '#ded1e8', borderColor: '#baa2c9' },
      { name: 'Общественно-деловые зоны', color: '#d1bfe0', borderColor: '#baa2c9' },
      {
        name: 'Производственные зоны, зоны инженерной и транспортной инфраструктуры',
        color: '#c7b0d9',
        borderColor: '#baa2c9',
      },
      {
        name: 'Зоны сельскохозяйственного использования',
        color: '#b9a1d1',
        borderColor: '#baa2c9',
      },
      { name: 'Зоны рекреационного назначения', color: '#af92c9', borderColor: '#baa2c9' },
      { name: 'Зоны особо охраняемых территорий', color: '#a686c4', borderColor: '#baa2c9' },
      { name: 'Зоны специального назначения', color: '#9779ba', borderColor: '#baa2c9' },
      { name: 'Зоны размещения военных объектов', color: '#8b6cb1', borderColor: '#baa2c9' },
      { name: 'Иные зоны', color: '#835aac', borderColor: '#baa2c9' },
    ],
    zoomVisible: { from: 19, to: 11 },
  },
  {
    id: 9,
    imageLink: '',
    imageShow: [],
    label: 'Зоны и территории',
    url: `${BASE_URL}/api-layer/zones/`,
    layerInfo: [
      { name: 'Особые экономические зоны', color: '#ecc0d4', borderColor: '#f4b1dc' },
      {
        name: 'Территории объектов культурного наследия',
        color: '#e29db9',
        borderColor: '#f4b1dc',
      },
      { name: 'Территории опережающего социально-экономического развития', color: '#db7da0' },
      { name: 'Зоны территориального развития', color: '#d76b8f' },
      { name: 'Игорные зоны', color: '#d3627f' },
      { name: 'Особо охраняемые природные территории', color: '#90b862', borderColor: '#82ad5f' },
      { name: 'Охотничьи угодья', color: '#f2e0a7', borderColor: '#ccb687' },
      { name: 'Лесничества', color: '#7cc263', borderColor: '#76b45f' },
      { name: 'Лесопарки', color: '#6e9d5b' },
      { name: 'Береговые линии или водные объекты', color: '#84a6ce' },
      { name: 'Публичные сервитуты', color: '#f6d4d6', borderColor: '#7398c9' },
      { name: 'Береговые линии или водные объекты', borderColor: '#7f7fff' },
    ],
    zoomVisible: { from: 19, to: 11 },
  },
  {
    id: 10,
    imageLink: '',
    imageShow: [],
    label: 'Границы субъектов РФ',
    url: `${BASE_URL}/api-layer/border_subject/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
  },
  {
    id: 11,
    imageLink: '',
    imageShow: [],
    label: 'Границы муниципальных районов и городских округов',
    url: `${BASE_URL}/api-layer/border_raion/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
  },
  {
    id: 12,
    imageLink: '',
    imageShow: [],
    label: 'Границы сельских и городских поселений',
    url: `${BASE_URL}/api-layer/border_city/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
  },
  {
    id: 13,
    imageLink: '',
    imageShow: [],
    label: 'Границы населенных пунктов',
    url: `${BASE_URL}/api-layer/border_man/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
  },
  {
    id: 14,
    imageLink: '',
    imageShow: [],
    label: 'Красные линии',
    url: `${BASE_URL}/api-layer/red_line/`,
    // url: 'red_line',
    layerInfo: [{ name: 'Красные линии', borderColor: '#5d0037' }],
    zoomVisible: { from: 19, to: 15 },
  },
  {
    id: 15,
    imageLink: '',
    imageShow: [],
    label: 'Форма собственности ЗУ',
    url: `${BASE_URL}/api-layer/thematic_type_ownership/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
  {
    id: 16,
    imageLink: '',
    imageShow: [],
    label: 'Разрешенное использование ЗУ',
    url: `${BASE_URL}/api-layer/thematic_cadastre_permitted/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
  {
    id: 17,
    imageLink: '',
    imageShow: [],
    label: 'Кадастровая стоимость ЗУ',
    url: `${BASE_URL}/api-layer/thematic_cadastre_price/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
  {
    id: 18,
    imageLink: '',
    imageShow: [],
    label: 'Кадастровая стоимость ЗУ за 1 м2',
    url: `${BASE_URL}/api-layer/thematic_cadastre_price_meter/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
  {
    id: 19,
    imageLink: '',
    imageShow: [],
    label: 'Срок давности выгрузки сведений из ГКН',
    url: `${BASE_URL}/api-layer/thematic_prescription/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
  {
    id: 20,
    imageLink: '',
    imageShow: [],
    label: 'Категории земель',
    url: `${BASE_URL}/api-layer/thematic_land_category/`,
    layerInfo: [],
    zoomVisible: { from: 19, to: 1 },
    opacity: 0.7,
  },
];

export const layersListByGroups: { name: string; list: LayersType[] }[] = [
  { name: '', list: [layersList[1]] },
  {
    name: 'Кадастровые сведения',
    list: [layersList[2], layersList[7], layersList[0], layersList[8]],
  },
  {
    name: 'Тематические карты',
    list: [
      layersList[14],
      layersList[15],
      layersList[16],
      layersList[17],
      layersList[18],
      layersList[19],
    ],
  },
  {
    name: 'Границы',
    list: [layersList[6], layersList[9], layersList[10], layersList[11], layersList[12]],
  },
  { name: '', list: [layersList[13]] },
];

export const reestrDict = {
  area_type: {
    '001': 'Площадь застройки',
    '002': 'Общая площадь',
    '003': 'Общая площадь без лоджии',
    '004': 'Общая площадь с лоджией',
    '005': 'Жилая площадь',
    '007': 'Основная площадь',
    '008': 'Декларированная площадь',
    '009': 'Уточненная площадь',
    '010': 'Фактическая площадь',
    '011': 'Вспомогательная площадь',
    '012': 'Площадь помещений общего пользования без лоджии',
    '013': 'Площадь помещений общего пользования с лоджией',
    '014': 'Прочие технические помещения без лоджии',
    '015': 'Прочие технические помещения с лоджией',
    '020': 'Застроенная площадь',
    '021': 'Незастроенная площадь',
    '022': 'Значение площади отсутствует',
  },
  area_unit: {
    '003': 'мм',
    '004': 'см',
    '005': 'дм',
    '006': 'м',
    '008': 'км',
    '009': 'Мм',
    '047': 'морск. м.',
    '050': 'мм²',
    '051': 'см²',
    '053': 'дм²',
    '055': 'м²',
    '058': 'тыс. м²',
    '059': 'га',
    '061': 'км²',
    '109': 'а',
    '359': 'сут.',
    '360': 'нед.',
    '361': 'дек.',
    '362': 'мес.',
    '364': 'кварт.',
    '365': 'полугод.',
    '366': 'г.',
    '383': 'руб.',
    '384': 'тыс. руб.',
    '385': 'млн. руб.',
    '386': 'млрд. руб.',
    '1000': 'неопр.',
    '1001': 'отсутств.',
    '1002': 'руб. за м²',
    '1003': 'руб. за а',
    '1004': 'руб. за га',
    '1005': 'иные',
  },
  cad_unit: {
    '003': 'мм',
    '004': 'см',
    '005': 'дм',
    '006': 'м',
    '008': 'км',
    '009': 'Мм',
    '047': 'морск. м.',
    '050': 'мм²',
    '051': 'см²',
    '053': 'дм²',
    '055': 'м²',
    '058': 'тыс. м²',
    '059': 'га',
    '061': 'км²',
    '109': 'а',
    '359': 'сут.',
    '360': 'нед.',
    '361': 'дек.',
    '362': 'мес.',
    '364': 'кварт.',
    '365': 'полугод.',
    '366': 'г.',
    '383': 'руб.',
    '384': 'тыс. руб.',
    '385': 'млн. руб.',
    '386': 'млрд. руб.',
    '1000': 'неопр.',
    '1001': 'отсутств.',
    '1002': 'руб. за м²',
    '1003': 'руб. за а',
    '1004': 'руб. за га',
    '1005': 'иные',
  },
  category_type: {
    '003001000000': 'Земли сельскохозяйственного назначения',
    '003002000000': 'Земли поселений (земли населенных пунктов)',
    '003003000000':
      'Земли промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, земли для обеспечения космической деятельности, земли обороны, безопасности и земли иного специального назначения',
    '003004000000': 'Земли особо охраняемых территорий и объектов',
    '003005000000': 'Земли лесного фонда',
    '003006000000': 'Земли водного фонда',
    '003007000000': 'Земли запаса',
    '003008000000': 'Категория не установлена',
  },
  purpose: {
    '204001000000': 'Нежилое здание',
    '204002000000': 'Жилой дом',
    '204003000000': 'Многоквартирный дом',
  },
  statecd: {
    '01': 'Ранее учтенный',
    '02': '-??-',
    '03': 'Условный',
    '04': 'Внесенный',
    '05': 'Временный(Удостоверен)',
    '06': 'Учтенный',
    '07': 'Снят с учета',
    '08': 'Аннулированный',
  },
};
