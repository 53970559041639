import { FC } from 'react';
import { DarkModeSwitch } from 'src/components/DarkModeSwitch';
import { useAppSelector } from 'src/hooks/useRedux';

import loadDarkGif from 'src/assets/gifs/load-dark.gif';
import loadLightGif from 'src/assets/gifs/load-light.gif';

export const LoaderFull: FC = () => {
  const { darkMode } = useAppSelector(state => state.common);

  return (
    <div className="fixed z-2600 inset-0 w-full h-full bg-grey-100 dark:bg-blue-300">
      <div className="px-4 t-lg:px-8 py-4 gap-3 t-lg:gap-4 flex">
        <DarkModeSwitch />
      </div>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="square w-[16rem] t-lg:w-[24rem] d-lg:w-[32rem]">
          <img
            src={darkMode ? loadDarkGif : loadLightGif}
            className="w-full h-full object-contain"
            alt=""
          />
        </div>
        <div
          className={
            'w-fit px-5 text-sm t-lg:text-lg dark:text-white font-gothampro-500 text-center'
          }
        >
          Пожалуйста подождите, идет загрузка данных
        </div>
      </div>
    </div>
  );
};
