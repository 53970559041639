import { FC, Fragment } from 'react';
import { EncumbrancesType } from 'src/config/types';
import { useAppSelector } from 'src/hooks/useRedux';

interface DashboardEncumbrancesListProps {
  data: EncumbrancesType;
}

export const DashboardEncumbrancesList: FC<DashboardEncumbrancesListProps> = ({ data }) => {
  const { encumbranceType } = useAppSelector(state => state.categories);

  const type = encumbranceType.find(el => el.id === data?.encumbrance_type);

  const statLine = (label: string, value: string) =>
    value ? (
      <div
        key={`stat-line-${label}`}
        className={
          'text-xs t-lg:text-base flex flex-col m-md:flex-row m-md:items-center gap-1 m-md:gap-2 ' +
          'font-gothampro-400 mb-2.5'
        }
      >
        <div className="text-grey-500">{label}:</div>
        <div className="dark:text-white m-md:text-right m-md:ml-auto">{value}</div>
      </div>
    ) : (
      <Fragment key={`stat-line-${label}`} />
    );

  const transformDate = (date?: string) => {
    if (!date) {
      return '-';
    }

    return date.split('-').reverse().join('.');
  };

  return (
    <div className="rounded-lg w-full dark:bg-blue-800 bg-white p-2.5 t-lg:p-5 mb-5">
      {statLine('Обременение', data?.encumbrance || '-')}
      {statLine('Вид обременения', type?.name || '-')}
      {statLine('Дата возникновения', transformDate(data?.date_occurrence))}
      {statLine('Дата прекращения', transformDate(data?.date_termination))}
      {statLine('В пользу кого', data?.favor_whom || '-')}
      {statLine('Документы-основания', data?.foundation_documents || '-')}
      {statLine('Площадь части (кв. м)', data?.area_of_part?.toString() || '-')}
      {statLine('Комментарий', data?.comments || '-')}
      {statLine('Действует', data?.is_removed ? 'нет' : 'да')}
    </div>
  );
};
