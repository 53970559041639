import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FiltersState } from 'src/store/filters/types';
import { DashboardPolygonsFilterType, PolygonsFilterType } from 'src/config/types';
import { LatLng } from 'leaflet';

const initialState: FiltersState = {
  polygonsFilter: {},
  dashboardPolygonsFilter: {},
  lassoFilter: {},
  listSearch: '',
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setPolygonsFilter: (state, action: PayloadAction<PolygonsFilterType>) => {
      state.polygonsFilter = action.payload;
    },
    setDashboardPolygonsFilter: (state, action: PayloadAction<DashboardPolygonsFilterType>) => {
      state.dashboardPolygonsFilter = action.payload;
    },
    setLassoFilter: (
      state,
      action: PayloadAction<{
        polygonIds?: number[];
        positions?: LatLng[];
      }>,
    ) => {
      state.lassoFilter = action.payload;
    },
    setListSearch: (state, action: PayloadAction<string>) => {
      state.listSearch = action.payload;
    },
  },
  extraReducers: {},
});

export const { setPolygonsFilter, setDashboardPolygonsFilter, setLassoFilter, setListSearch } =
  filtersSlice.actions;

const reducer = filtersSlice.reducer;
export default reducer;
