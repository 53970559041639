import {
  CategoryType,
  ClickFetchType,
  ObjectInfoAttrsType,
  ObjectInfoType,
  PolygonSelectsType,
  PolygonsFilterType,
  PolygonType,
  SelectItemType,
  StatusType,
  UserType,
} from 'src/config/types';
import {
  arrHeight,
  coordsToGPS,
  formatDate,
  notice,
  numberWithSpaces,
  stringToCoords,
} from 'src/utils/index';
import { stateFromHTML } from 'draft-js-import-html';
import { setIsBurgerOpen, setIsNewPolygonFormOpen, setOpenedDropBlock } from 'src/store/common';
import { fetchDrawPolygonById, fetchReestrPolygonById } from 'src/store/polygons/actions';
import { setFlyTo } from 'src/store/map';
import { setNotSavedDrawFavoritePolygonId } from 'src/store/polygons';
import { CategoriesState } from 'src/store/categories/types';
import { Dispatch, SetStateAction } from 'react';
import { EditorState } from 'draft-js';
import { LatLng, LatLngExpression } from 'leaflet';
import { filterKeys, initialPolygon, polygonSelectFields, reestrDict } from 'src/config/data';

import ReestrService from 'src/services/ReestrService';

import { ReactComponent as FingerIcon } from 'src/assets/icons/kit/finger.svg';
import { ReactComponent as BoxIcon } from 'src/assets/icons/kit/box.svg';
import { ReactComponent as HomeIcon } from 'src/assets/icons/kit/home.svg';
import { ReactComponent as DecorationIcon } from 'src/assets/icons/kit/decoration.svg';

export const getPolygonName = (polygon: PolygonType): string =>
  polygon?.name ||
  polygon?.properties?.name ||
  (polygon?.cadaster_number || polygon?.properties?.cadaster_number
    ? `${polygon?.cadaster_number || polygon?.properties?.cadaster_number}${
        polygon?.address || polygon?.properties?.address
          ? ` - ${polygon?.address || polygon?.properties?.address}`
          : ''
      }`
    : 'Без названия');

export const calculateStatusesCount = (cluster, key: 'status' | 'blockStatus') => {
  const statusesCount = {};

  if (cluster._markers.length) {
    cluster._markers.forEach(marker => {
      const status = marker?.options?.icon?.options?.[key];

      if (!status) return;

      if (statusesCount?.[status]) {
        statusesCount[status] += 1;
      } else {
        statusesCount[status] = 1;
      }
    });
  }

  if (cluster._childClusters.length) {
    cluster._childClusters.forEach(childCluster => {
      const temp = calculateStatusesCount(childCluster, key);
      Object.keys(temp).forEach(key => {
        if (statusesCount?.[key]) {
          statusesCount[key] += temp[key];
        } else {
          statusesCount[key] = temp[key];
        }
      });
    });
  }

  return statusesCount;
};

export const isDrawing = () =>
  !!document.querySelector('.leaflet-draw-toolbar-button-enabled') ||
  !!document.querySelector('.leaflet-ruler-clicked');

export const geoJSONToCoords = (str: string): LatLngExpression[][] | LatLngExpression[][][] => {
  if (str.includes('MULTIPOLYGON') && !str.includes(')), ((')) {
    const newStr = str.replace('SRID=4326;MULTIPOLYGON (((', '').replace(')))', '');
    const split = newStr.split('), (');

    return split
      .map(el => el.split(', '))
      .map(el =>
        el.map(el => {
          const temp = el.split(' ');
          return [+temp[0], +temp[1]];
        }),
      );
  }

  if (str.includes('MULTIPOLYGON') && str.includes(')), ((')) {
    const newStr = str.replace('SRID=4326;MULTIPOLYGON (((', '').replace(')))', '');
    const split = newStr.split(')), ((');

    return split.map(el =>
      el.split('), (').map(el =>
        el.split(', ').map(el => {
          const temp = el.split(' ');
          return [+temp[0], +temp[1]];
        }),
      ),
    ) as LatLngExpression[][][];
  }

  const newStr = str.replace('SRID=4326;POLYGON ((', '').replace('))', '');
  const split = newStr.split('), (');

  return [
    split.map(el =>
      el.split(', ').map(el => {
        const temp = el.split(' ');
        return [+temp[0], +temp[1]];
      }),
    ),
  ] as LatLngExpression[][][];
};

export const coordsToGeoJSON = (coords?: number[][][][] | number[][][], isMulti?: boolean) => {
  if (!coords) return '';

  if (isMulti) {
    const temp = coords
      .map(el => el.map(el => el.map(el => el.join(' ')).join(', ')).join('), ('))
      .join(')), ((');
    return `SRID=4326;MULTIPOLYGON (((${temp})))`;
  }

  const temp = coords.map(el => el.map(el => el.join(' ')).join(', ')).join('), (');

  return `SRID=4326;POLYGON ((${temp}))`;
};

export const handleSelectPolygon = async (polygon: PolygonType, width: number, dispatch) => {
  if (!polygon?.id) return;
  addCnToUrl(polygon?.properties?.cadaster_number);

  if (polygon?.properties?.cadaster_number) {
    await dispatch(fetchReestrPolygonById(polygon.id.toString()));
  } else {
    await dispatch(fetchDrawPolygonById(polygon.id.toString()));
  }

  if (width >= 1024) {
    dispatch(setIsNewPolygonFormOpen(true));
    dispatch(setOpenedDropBlock({ info: true }));
  } else {
    dispatch(setOpenedDropBlock({}));
    dispatch(setIsBurgerOpen(false));
  }
  dispatch(setNotSavedDrawFavoritePolygonId(null));

  const coords = stringToCoords(polygon?.center || polygon?.properties?.center || '');
  dispatch(setFlyTo(coords));
};

export const calcLassoButtonPosition = (coords: LatLng[]): LatLngExpression => {
  let maxX = 0,
    maxY = 0;

  coords.forEach(coord => {
    if (maxX < +coord.lat) {
      maxX = coord.lat;
    }

    if (maxY < +coord.lng) {
      maxY = coord.lng;
    }
  });

  return [maxX, maxY];
};

export const getFetchReestrImageType = (selectedLayers: number[]): ClickFetchType => {
  let fetchType: ClickFetchType = 'getLand';

  if (selectedLayers.includes(3)) {
    fetchType = 'getBuildings';
  }

  if (selectedLayers.includes(7)) {
    fetchType = 'getBorders';
  }

  if (selectedLayers.includes(8)) {
    fetchType = 'getTerritoryZone';
  }

  if (selectedLayers.includes(1)) {
    fetchType = 'getSpecialZone';
  }

  return fetchType;
};

export const addDefs = (statuses: StatusType[]) => {
  if (!statuses.length) return;

  const mapSvg = document.querySelectorAll('svg.leaflet-zoom-animated');

  if (!mapSvg.length) return;

  mapSvg.forEach(svg => {
    if (!!svg.querySelector('#color-patterns')?.childNodes.length) return;

    const defs = generateDefs(statuses);
    svg?.['appendChild'](defs);
  });
};

export const generateDefs = (blockStatuses: StatusType[]) => {
  const xmlns = 'http://www.w3.org/2000/svg';
  const defs = document.createElementNS(xmlns, 'defs');

  defs.setAttributeNS(null, 'id', 'color-patterns');

  blockStatuses.forEach(status => {
    if (!status.color) return;

    const pattern = document.createElementNS(xmlns, 'pattern');
    const line = document.createElementNS(xmlns, 'line');
    const color = status.color.replace('#', '');

    pattern.setAttributeNS(null, 'id', `pattern-${color}`);
    pattern.setAttributeNS(null, 'height', '16');
    pattern.setAttributeNS(null, 'width', '16');
    pattern.setAttributeNS(null, 'patternUnits', 'userSpaceOnUse');
    pattern.setAttributeNS(null, 'patternTransform', 'rotate(-30)');

    line.setAttributeNS(null, 'x1', '0');
    line.setAttributeNS(null, 'x2', '9');
    line.setAttributeNS(null, 'y1', '8');
    line.setAttributeNS(null, 'y2', '8');
    line.setAttributeNS(null, 'stroke-width', '4');
    line.setAttributeNS(null, 'stroke', status.color);

    pattern.appendChild(line);
    defs.appendChild(pattern);
  });

  return defs;
};

export const calculateFiltersCount = (filter: PolygonsFilterType): number => {
  let count = 0;

  Object.keys(filter).forEach(key => {
    count += filter[key]?.length;
  });

  return count;
};

export const getSubStatusIcon = (statusColor: string, subStatusIcon: string) => {
  const style = {
    fill: statusColor,
  };
  const className = 'w-3 h-3 min-w-[0.75rem] ml-1 mr-1';

  const subStatusIcons = {
    finger: <FingerIcon className={className} style={style} />,
    box: <BoxIcon className={className} style={style} />,
    home: <HomeIcon className={className} style={style} />,
    decoration: <DecorationIcon className={className} style={style} />,
  };

  return subStatusIcons[subStatusIcon];
};

export const getPolygonFormSelectItem = (status: StatusType | CategoryType): SelectItemType => ({
  value: (status?.id || 0).toString(),
  label: status.name,
  ...(status?.['color'] && {
    icon: (
      <div
        className="w-4 h-4 min-w-[1rem] mr-2 rounded-sm"
        style={{ backgroundColor: status?.['color'] }}
      />
    ),
  }),
});

export const getPolygonFormSelectItemNew = (status: StatusType | CategoryType): SelectItemType => ({
  value: status.id || 0,
  label: status.name,
});

export const getPolygonSelectLists = (
  category: CategoriesState,
  polygonSelects: PolygonSelectsType,
): {
  categories: SelectItemType[];
  statuses: SelectItemType[];
  subStatuses: SelectItemType[];
  blockStatuses: SelectItemType[];
  owners: SelectItemType[];
  typeOwners: SelectItemType[];
} => {
  const categories = category.categories.map(el => getPolygonFormSelectItem(el));
  const statuses = category.statuses.map(status => getPolygonFormSelectItem(status));
  const blockStatuses = category.blockStatuses.map(status => getPolygonFormSelectItem(status));
  const owners = category.owners.map(status => getPolygonFormSelectItem(status));
  const typeOwners = category.typeOwners.map(status => getPolygonFormSelectItem(status));

  const currentStatus = category.statuses.find(
    el => el.id.toString() === polygonSelects?.status?.value?.toString(),
  );
  const subStatuses = currentStatus?.substatuses?.length
    ? currentStatus?.substatuses.map(status => getPolygonFormSelectItem(status))
    : [];

  return { categories, statuses, subStatuses, blockStatuses, owners, typeOwners };
};

export const getPolygonCategories = (
  polygon: PolygonType,
  categories: CategoriesState,
): {
  category?: CategoryType;
  status?: StatusType;
  subStatus?: StatusType;
  blockStatus?: StatusType;
  owner?: StatusType;
  typeOwner?: CategoryType;
} => {
  const category = categories.categories.find(
    el => el.id === polygon?.properties?.category || el.id === polygon?.category,
  );
  const status = categories.statuses.find(
    el => el.id === (polygon?.status || polygon?.properties?.status),
  );
  const subStatus = status?.substatuses?.find(
    el => el?.id === polygon?.properties?.substatus || el?.id === polygon?.substatus,
  );
  const blockStatus = categories.blockStatuses.find(
    el => el?.id === polygon?.properties?.block_status || el?.id === polygon?.block_status,
  );
  const owner = categories.owners.find(
    el => el?.id === polygon?.properties?.owner || el?.id === polygon?.owner,
  );
  const typeOwner = categories.typeOwners.find(
    el => el?.id === polygon?.properties?.type_owner || el?.id === polygon?.type_owner,
  );

  return { category, status, subStatus, blockStatus, owner, typeOwner };
};

export const selectPolygonSelects = (
  polygon: PolygonType | null,
  categories: CategoriesState,
  setPolygonSelects: Dispatch<SetStateAction<PolygonSelectsType>>,
  setValue: (name: string, value: string) => void,
  setComment?: Dispatch<SetStateAction<EditorState>>,
) => {
  if (!polygon) return;

  const { category, status, subStatus, blockStatus, owner, typeOwner } = getPolygonCategories(
    polygon,
    categories,
  );

  if (!status) return;

  setPolygonSelects({
    status: getPolygonFormSelectItem(status),
    subStatus: subStatus ? getPolygonFormSelectItem(subStatus) : null,
    category: category ? getPolygonFormSelectItem(category) : null,
    blockStatus: blockStatus ? getPolygonFormSelectItem(blockStatus) : null,
    owner: owner ? getPolygonFormSelectItem(owner) : null,
    typeOwner: typeOwner ? getPolygonFormSelectItem(typeOwner) : null,
  });
  setComment &&
    setComment(
      EditorState.createWithContent(
        stateFromHTML(polygon?.comments || polygon?.properties?.comments || ''),
      ),
    );

  // @ts-ignore
  polygonSelectFields.forEach(field => setValue(field, polygon?.[field]));
};

export const getLandPlotValue = (
  field: { name: string; arr: string[] },
  attrs?: ObjectInfoAttrsType,
): string => {
  if (!attrs) {
    return '';
  }

  attrs = {
    ...attrs,
    date_cost: attrs.date_cost ? formatDate(attrs.date_cost) : attrs.date_cost,
    application_date: attrs.application_date
      ? formatDate(attrs.application_date)
      : attrs.application_date,
    cc_date_entering: attrs.cc_date_entering
      ? formatDate(attrs.cc_date_entering)
      : attrs.cc_date_entering,
  };

  return field.arr
    .map(el => {
      if ('^()'.indexOf(el) !== -1) {
        return el;
      }

      if (!attrs?.[el]) {
        return '';
      }

      if (el === 'cad_cost' || el === 'area_value') {
        return numberWithSpaces(+(attrs?.[el] || 0));
      }

      return reestrDict?.[el]?.[attrs?.[el]] || attrs?.[el] || el;
    })
    .join('')
    .replaceAll('^', ' ');
};

const getReestrPolygonGeometry = async (cadastral_number: string, additional_numbers?: number) => {
  let geometry = '';
  try {
    const geoJSONCoords: {
      features: { geometry: { coordinates: any; type: string } }[];
    } = await ReestrService.getGeoJsonPolygon({
      cadastral_number,
    });

    const processCoordinates = (coords: any): number[][] => {
      if (typeof coords[0] === 'number') {
        const gpsCoords = coordsToGPS(coords) as [number, number];
        return gpsCoords && !isNaN(gpsCoords[0]) && !isNaN(gpsCoords[1])
          ? [gpsCoords.reverse()]
          : [];
      }
      return coords.flatMap(processCoordinates);
    };

    const processPolygon = (polygonCoords: number[][][]): number[][][] => {
      return polygonCoords.map(ring => processCoordinates(ring));
    };

    const isMulti = additional_numbers ? additional_numbers > 1 : false;
    const coordinates: number[][][][] = [];

    geoJSONCoords.features.forEach(feature => {
      const processedCoords: number[][][] = [];

      if (feature.geometry.type === 'Polygon') {
        const outerRing = processPolygon([feature.geometry.coordinates[0]]);
        const innerRings = feature.geometry.coordinates
          .slice(1)
          .map(ring => processCoordinates(ring));
        processedCoords.push(...outerRing, ...innerRings);
      } else if (feature.geometry.type === 'MultiPolygon') {
        feature.geometry.coordinates.forEach(polygon => {
          const outerRing = processPolygon([polygon[0]]);
          const innerRings = polygon.slice(1).map(ring => processCoordinates(ring));
          processedCoords.push(...outerRing, ...innerRings);
        });
      }

      coordinates.push(processedCoords);
    });

    geometry = coordsToGeoJSON(isMulti ? coordinates : coordinates[0], isMulti);
    return geometry;
  } catch (e) {
    notice({
      title: 'Ошибка!',
      message: 'Не удалось получить координаты полигона, попробуйте еще раз.',
      type: 'danger',
    });
    return geometry;
  }
};

export const getReestrPolygon = async (
  reestrInfo: ObjectInfoType,
  additional_numbers?: number,
): Promise<PolygonType | null> => {
  const attrs = reestrInfo?.feature?.attrs;
  const reestrCenter = reestrInfo?.feature?.center;
  const centerReversed = !reestrInfo?.info && coordsToGPS([reestrCenter.x, reestrCenter.y]);
  const center = [centerReversed?.[1], centerReversed?.[0]];

  const params = {
    ...initialPolygon,
    square: attrs?.area_value || 0,
    cadaster_number: attrs?.cn || '',
    address: attrs?.address || '',
    price: attrs?.cad_cost || 0,
    reestrInfo,
  };

  if (reestrInfo?.info) {
    const parent = reestrInfo?.feature?.extent_parent || reestrInfo?.feature?.extent;

    if (!parent) return null;

    const a = coordsToGPS([parent?.xmax, parent.ymax]);
    const b = coordsToGPS([parent?.xmin, parent.ymin]);
    return {
      ...params,
      center: `${(a?.[1] + b?.[1]) / 2},${(a?.[0] + b?.[0]) / 2}`,
      geometry: '',
    };
  }

  const geometry = await getReestrPolygonGeometry(attrs?.cn || '', additional_numbers);

  return {
    ...params,
    center: `${center[0]},${center[1]}`,
    geometry,
  };
};

export const addCnToUrl = (cn?: string) => {
  if (!cn) return;

  window.history.pushState('', '', `?cn=${cn}`);
};

export const removeQueryParams = () => window.history.pushState('', '', window.location.pathname);

export const getMyFilters = filters =>
  Object.keys(filters).map(key =>
    filters[key].length ? `&${filterKeys[key]}=${filters[key].join(',')}` : '',
  );

export const getFilters = (polygonsFilter, dashboardPolygonsFilter) => {
  const filters = getMyFilters(polygonsFilter);
  const dashboardFilters = Object.keys(dashboardPolygonsFilter).map(key => {
    if (dashboardPolygonsFilter?.[key] && typeof dashboardPolygonsFilter[key] === 'string') {
      return `&${key}=${dashboardPolygonsFilter[key]}`;
    } else {
      const arr = Object.keys(dashboardPolygonsFilter[key]).map(
        newKey => `&${key}_${newKey}=${dashboardPolygonsFilter[key][newKey]}`,
      );

      return arr.join('');
    }
  });

  return `${filters.join('')}${dashboardFilters.join('')}`;
};

export const getPolygonColors = (
  polygon: PolygonType,
  categories: CategoriesState,
  user: UserType,
): { fill: string; stroke: string; hatching: string } => {
  const { category, status, blockStatus } = getPolygonCategories(polygon, categories);
  const { is_colorpicker, isLeader } = user;
  let hatching = '';
  let fill = '#7AA0CE';
  let stroke = is_colorpicker ? '#AAAAAA' : '#47750B';

  // hatching
  if (!isLeader && status?.color) {
    hatching = status.color;
  }

  if (isLeader && blockStatus?.color) {
    hatching = blockStatus.color;
  }

  // fill
  if (!isLeader && blockStatus?.color) {
    fill = blockStatus.color;
  }

  if (isLeader && status?.color) {
    fill = status.color;
  }

  // stroke
  if (!isLeader && is_colorpicker && category?.color) {
    stroke = category.color;
  }

  if (!isLeader && !is_colorpicker && blockStatus?.color) {
    stroke = blockStatus.color;
  }

  if (isLeader && status?.color) {
    stroke = status.color;
  }

  return { fill, stroke, hatching };
};
