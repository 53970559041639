import { Api } from 'src/services/Api/Api';
import { request } from 'src/services/Api/Fetch';

const getAllImages = () => {
  return request(`/api/upload_image/`);
};

const saveImage = (params: FormData) => {
  return Api.post(`/api/upload_image/`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const deleteImageById = (params: { id: number }) => {
  return request(`/api/upload_image/${params.id}`, {}, 'DELETE');
};

const ImagesService = {
  getAllImages,
  saveImage,
  deleteImageById,
};

export default ImagesService;
