import { EncumbrancesType } from 'src/config/types';
import { notice } from 'src/utils';

export const getEncumbrancesList = (
  polygon?: number,
  encumbrances?: EncumbrancesType[],
): EncumbrancesType[] => {
  if (!encumbrances?.length || !polygon) {
    return [];
  }

  const res: EncumbrancesType[] = [];

  encumbrances.forEach(encumbrance => {
    if (
      encumbrance.encumbrance ||
      encumbrance.encumbrance_type ||
      encumbrance.comments ||
      encumbrance.area_of_part ||
      encumbrance.date_occurrence ||
      encumbrance.date_termination ||
      encumbrance.favor_whom ||
      encumbrance.foundation_documents
    ) {
      res.push({
        ...encumbrance,
        area_of_part: encumbrance.area_of_part ? +encumbrance.area_of_part : undefined,
        polygon,
      });
    } else {
      notice({
        message: 'Ошибка сохранения обременения. Форма не заполнена.',
        type: 'danger',
      });
    }
  });

  return res;
};
