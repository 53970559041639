import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { setOpenedDropBlock } from 'src/store/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';
import { Loader } from 'src/components/ui/Loader';
import { ScrollButton } from 'src/components/ScrollButton';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';
import cn from 'classnames';

interface DropBlockProps {
  onClick?: () => void;
  title: string;
  wrapperClassNames?: string;
  name: string;
  classNames?: string;
  countLoading?: boolean;
  count?: number;
  children?: ReactNode;
}

export const DropBlock: FC<DropBlockProps> = ({
  onClick,
  title,
  wrapperClassNames,
  name,
  count,
  countLoading,
  classNames,
  children,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();
  const { openedDropBlocks, isFavouritesActive } = useAppSelector(state => state.common);

  const isOpen = useMemo(() => Boolean(openedDropBlocks?.[name]), [openedDropBlocks, name]);

  const handelOpen = () => dispatch(setOpenedDropBlock({ [name]: !openedDropBlocks?.[name] }));

  const [showTopArrow, setShowTopArrow] = useState(false);

  const handleScroll = () => {
    if (!ref?.current) return;
    const { offsetHeight, scrollTop } = ref.current;

    setShowTopArrow(scrollTop > offsetHeight / 3);
  };

  const scrollTop = () => {
    if (!ref?.current) return;

    ref.current.scroll({ behavior: 'smooth', top: 0 });
  };

  useEffect(() => {
    if (!ref?.current) return;

    ref.current.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={cn(
        { 'dark:bg-blue-200': name === 'info' },
        'bg-white dark:bg-blue-800 overflow-hidden',
        wrapperClassNames,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'px-2 t-lg:px-4 flex gap-2 h-8 t-lg:h-11 items-center cursor-pointer',
          classNames,
        )}
        onClick={handelOpen}
      >
        <div className="text-white text-xs t-lg:text-base font-gothampro-600 mr-auto">{title}</div>

        {typeof count === 'number' ? (
          <div
            className={
              'rounded-xl t-lg:py-1 px-2 font-gothampro-500 t-lg:text-sm text-white bg-blue-800 ' +
              'text-center mr-4 h-6 t-lg:h-7 center text-xs'
            }
          >
            {countLoading ? (
              <Loader classNames="w-4 h-4" stroke="#FFFFFF" strokeWidth="8" />
            ) : (
              count
            )}
          </div>
        ) : null}
        <ArrowIcon
          className={cn(
            { '-rotate-180': !isOpen },
            'min-w-[1rem] w-4 h-4 transition-full stroke-path-white',
          )}
        />
      </div>

      <div
        ref={ref}
        className="transition-full overflow-y-auto overflow-x-hidden mr-10 w-full relative"
        style={{
          maxHeight: isOpen ? (width >= 1024 ? `calc(100dvh - 220px)` : '50vh') : '0',
          opacity: isOpen ? 1 : 0,
        }}
        {...(name === 'zones' && { id: 'zones-scroll' })}
      >
        {children}
      </div>

      <ScrollButton
        visible={isOpen && showTopArrow}
        onClick={scrollTop}
        classNames={cn({ 'bottom-[3.75rem]': isFavouritesActive })}
      />
    </div>
  );
};
