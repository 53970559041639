import { FC } from 'react';
import cn from 'classnames';

import { ReactComponent as ArrowIcon } from 'src/assets/icons/kit/arrow.svg';

interface ScrollButtonProps {
  visible?: boolean;
  variant?: 'top' | 'bottom';
  onClick: () => void;
  classNames?: string;
}

export const ScrollButton: FC<ScrollButtonProps> = ({
  visible,
  variant = 'top',
  onClick,
  classNames,
}) => {
  return (
    <div
      className={cn(
        {
          'opacity-0 pointer-events-none': !Boolean(visible),
          'opacity-1': Boolean(visible),
          'bottom-4': !classNames?.includes('bottom-'),
        },
        'w-8 h-8 rounded-full cursor-pointer bg-blue-100 center absolute',
        'right-4',
        classNames,
      )}
      onClick={onClick}
    >
      <ArrowIcon
        className={cn(
          { 'rotate-180 translate-y-0.5': variant === 'bottom' },
          'stroke-path-white w-4 h-4',
        )}
      />
    </div>
  );
};
