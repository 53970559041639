import { FC, ReactNode, useState } from 'react';
import { isTouchDevice, shadeColor } from 'src/utils';

import cn from 'classnames';

interface MapIconProps {
  icon: ReactNode;
  color: string;
  onClick: () => void;
  isActive?: boolean;
  isBlur?: boolean;
  count?: number;
  classNames?: string;
}

export const MapIcon: FC<MapIconProps> = ({
  icon,
  color,
  onClick,
  isActive,
  isBlur,
  count,
  classNames,
}) => {
  const isTouch = isTouchDevice();

  const [isHover, setIsHover] = useState(false);

  const darkerSmColor = shadeColor(color, -20);
  const darkerLgColor = shadeColor(color, -40);

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={cn(
        'center w-10 h-10 t-lg:w-11 t-lg:h-11 rounded-md relative overflow-hidden',
        'cursor-pointer transition-full',
        classNames,
      )}
      onClick={onClick}
      style={{
        backgroundColor: isHover && !isTouch ? darkerSmColor : color,
        boxShadow: isActive ? `3px 3px 3px 0px ${darkerLgColor} inset` : '',
      }}
    >
      {isBlur ? <div className="absolute inset-0 w-full h-full bg-white-rgba-100" /> : null}
      {icon}
      {count ? (
        <div
          className={
            'w-5 h-5 bg-pink-100 rounded-full center text-white text-extra-xs ' +
            'font-gothampro-500 text-white absolute top-0.5 right-0.5 ' +
            't-lg:top-1 t-lg:right-1'
          }
        >
          {count}
        </div>
      ) : null}
    </div>
  );
};
