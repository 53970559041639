import { FC } from 'react';
import { setSelectedPolygon } from 'src/store/polygons';
import { stringToCoords } from 'src/utils';
import { getPolygonName, removeQueryParams } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setOpenedDropBlock } from 'src/store/common';
import { Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import MarkerSelectedIcon from 'src/assets/icons/marker-selected.svg';

export const LeafletMapSelectedPolygonTooltip: FC = () => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);

  const dispatch = useAppDispatch();

  const handleClose = e => {
    e.stopPropagation();
    removeQueryParams();
    dispatch(setSelectedPolygon({ polygon: null }));
    dispatch(setOpenedDropBlock({ info: false }));
  };

  const myIcon = L.icon({
    iconUrl: MarkerSelectedIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
  });

  return selectedPolygon ? (
    <Marker
      zIndexOffset={500}
      icon={myIcon}
      position={stringToCoords(
        selectedPolygon?.center || selectedPolygon?.properties?.center || '',
      )}
    >
      <Tooltip
        eventHandlers={{
          click: e => {
            e.originalEvent.stopPropagation();
          },
        }}
        interactive
        direction="top"
        permanent
        className="my-tooltip"
      >
        <div onClick={handleClose} className="w-5 h-5 cursor-pointer absolute top-2 right-2 center">
          <CloseIcon className="fill-white w-2 h-2" />
        </div>
        {getPolygonName(selectedPolygon)}
      </Tooltip>
    </Marker>
  ) : null;
};
