import { FC, useState } from 'react';
import { Button } from 'src/components/ui/Button';
import { setOpenedDropBlock, setPolygonEdit, setResetForm } from 'src/store/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setDrawPolygons, setReestrPolygons, setSelectedPolygon } from 'src/store/polygons';
import { getPolygonName, removeQueryParams } from 'src/utils/helpers';
import { useAuth } from 'src/hooks/useAuth';
import { PolygonType } from 'src/config/types';
import ReestrService from 'src/services/ReestrService';
import PolygonsService from 'src/services/PolygonsService';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as TrashIcon } from 'src/assets/icons/kit/trash.svg';

interface FormButtonsProps {
  isLoading: boolean;
  setIsLoading: (val: boolean) => void;
  handleSelect: (polygon: PolygonType | null) => void;
  isOpen: boolean;
  notNew: boolean | null;
  handleOpen: () => void;
}

export const FormButtons: FC<FormButtonsProps> = ({
  isLoading,
  setIsLoading,
  handleSelect,
  isOpen,
  notNew,
  handleOpen,
}) => {
  const { polygonEdit } = useAppSelector(state => state.common);
  const {
    selectedPolygon,
    reestrPolygons,
    reestrPolygonsId,
    drawPolygons,
    notSavedDrawFavoritePolygonId,
  } = useAppSelector(state => state.polygons);
  const { user } = useAuth();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState<boolean>(false);

  const handleOpenRemoveModal = () => setIsOpenRemoveModal(true);
  const handleCloseRemoveModal = () => setIsOpenRemoveModal(false);

  const dispatch = useAppDispatch();

  const handleRedirectToReestr = async e => {
    e?.preventDefault();
    try {
      const searchedInfo = selectedPolygon?.reestrInfo;

      if (!searchedInfo) return;

      const res = await ReestrService.redirect({
        coords: `${searchedInfo?.feature?.center?.x},${searchedInfo?.feature?.center?.y}`,
        cadastral_number: searchedInfo?.feature?.attrs?.cn || '',
      });

      if (res?.url) {
        window.open(res.url, '_blank')?.focus();
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCancelNewPolygon = e => {
    e?.preventDefault();
    removeQueryParams();
    dispatch(setResetForm(true));
    dispatch(setSelectedPolygon({ polygon: null }));
    dispatch(setOpenedDropBlock({}));
    isOpen && handleOpen();
  };

  const handleCancelEditMode = e => {
    e?.preventDefault();
    selectedPolygon && handleSelect(selectedPolygon);
    dispatch(setPolygonEdit(false));
  };

  const handleDeletePolygon = async e => {
    if (isLoading) return;

    setIsLoading(true);
    e.preventDefault();
    removeQueryParams();
    if (!selectedPolygon) return;

    try {
      if (selectedPolygon.cadaster_number) {
        await PolygonsService.removeReestrPolygon({
          id: +(selectedPolygon?.id || ''),
        });
      } else {
        await PolygonsService.removeDrawPolygon({
          id: +(selectedPolygon?.id || ''),
        });
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      if (selectedPolygon.cadaster_number) {
        dispatch(
          setReestrPolygons({
            polygons: reestrPolygons.filter(polygon => polygon.id !== selectedPolygon.id),
            ids: reestrPolygonsId.filter(id => id !== selectedPolygon.id),
          }),
        );
      } else {
        dispatch(
          setDrawPolygons(drawPolygons.filter(polygon => polygon.id !== selectedPolygon.id)),
        );
      }
      dispatch(setPolygonEdit(false));
      dispatch(setSelectedPolygon({ polygon: null }));
      dispatch(setResetForm(true));
      setIsLoading(false);
      handleCloseRemoveModal();
    }
  };

  return (
    <>
      <div className="w-full flex gap-2 mt-2.5 t-sm:mt-auto flex-wrap m-md:flex-nowrap">
        {selectedPolygon?.reestrInfo?.feature?.center ? (
          <Button
            variant="orange"
            classNames="whitespace-nowrap min-w-full m-md:min-w-fit"
            onClick={handleRedirectToReestr}
          >
            Перейти в Росреестр
          </Button>
        ) : null}

        {!notNew && !notSavedDrawFavoritePolygonId ? (
          polygonEdit ? (
            <>
              <Button variant="grey" classNames="ml-auto" onClick={handleCancelEditMode}>
                Отмена
              </Button>
              <Button variant="blue">Сохранить</Button>
              <div
                className="w-6 h-6 t-lg:w-7 t-lg:h-7 center cursor-pointer bg-orange-100 rounded-md"
                onClick={handleOpenRemoveModal}
              >
                <TrashIcon className="w-3 h-3 fill-white" />
              </div>
            </>
          ) : (
            <>
              <Button variant="grey" classNames="ml-auto" onClick={handleCancelNewPolygon}>
                Отмена
              </Button>
              {user?.isManager ? (
                <Button
                  variant="blue"
                  onClick={e => {
                    e?.preventDefault();
                    dispatch(setPolygonEdit(true));
                  }}
                >
                  Редактировать
                </Button>
              ) : null}
            </>
          )
        ) : null}

        {notNew || !!notSavedDrawFavoritePolygonId ? (
          <>
            <Button variant="grey" classNames="ml-auto" onClick={handleCancelNewPolygon}>
              Отмена
            </Button>
            <Button variant="blue">Сохранить</Button>
          </>
        ) : null}
      </div>

      {isOpenRemoveModal ? (
        <div className="fixed w-full h-full flex center bg-black-rgba-200 z-2000 inset-0 p-4">
          <div
            className={
              'w-full max-w-[30rem] bg-white rounded-xl p-4 flex flex-col gap-5 t-sm:gap-8 ' +
              'dark:bg-blue-300'
            }
          >
            <CloseIcon
              className="ml-auto cursor-pointer w-4 h-4 dark:fill-white"
              onClick={handleCloseRemoveModal}
            />
            <div
              className={
                'font-gothampro-400 text-sm text-black-100 text-center t-sm:max-w-[80%] ' +
                'mx-auto dark:text-white'
              }
            >
              Вы действительно хотите удалить полигон{' '}
              {selectedPolygon ? getPolygonName(selectedPolygon) : ''}?
            </div>
            <div className="flex gap-4 w-fit mx-auto">
              <Button variant="grey" onClick={handleCloseRemoveModal}>
                Отмена
              </Button>
              <Button variant="blue" onClick={handleDeletePolygon}>
                Удалить
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
