import { FetchReestrPolygonsParamsParams, PolygonType } from 'src/config/types';
import { request } from 'src/services/Api/Fetch';

//reestr
const getAllReestrPolygons = async (
  params: FetchReestrPolygonsParamsParams,
  signal?: AbortSignal,
) => {
  const sort = params?.sort ? `&sort=${params.sort.field}:${params.sort.type}` : '';
  const fields = params?.fields?.length ? `&fields=${params?.fields?.join(',')}` : '';
  const page = params?.page ? `&page=${params.page}` : '';
  const filter = params?.filter ? params.filter : '';
  const ids = params?.ids ? `&ids=${params.ids.join(',')}` : '';
  const search = params?.search ? `&search=${params.search}` : '';
  const coords = params.coords
    ? `&xmin=${params.coords.xmin}&ymin=${params.coords.ymin}&xmax=${params.coords.xmax}&ymax=${params.coords.ymax}
`
    : '';
  const str = `${fields}${sort}${page}${filter}${coords}${ids}${search}`;
  return request(`/api/upload/${str ? `?${str}` : ''}`, {}, 'GET', {}, signal);
};

const insertReestrPolygon = (params: PolygonType) => {
  return request(`/api/upload/`, params, 'POST');
};

const removeReestrPolygon = (params: { id: number }) => {
  return request(`/api/upload/${params.id}/`, {}, 'DELETE');
};

const updateReestrPolygon = (params: PolygonType, id: number) => {
  return request(`/api/upload/${id}/`, params, 'PATCH');
};

const updateReestrPolygonEncumbrances = (params: PolygonType, id: number) => {
  return request(`/api/upload/${id}/encumbrances/`, params, 'PATCH');
};

const getReestrPolygonAllFilesById = (params: { id: number }) => {
  return request(`/yellow_combined/${params.id}/`);
};

const getReestrPolygonById = (params: { id: string }) => {
  return request(`/api/upload/${params.id}/`);
};

const getReestrPolygonHistoryById = (params: { id: string }) => {
  return request(`/api/upload/${params.id}/history/`);
};

const getReestrPolygonByCN = (params: { cn: string }) => {
  return request(`/api/upload/get_cad_numbers/?cadaster_number=${params.cn}`);
};

//draw
const getAllDrawPolygons = () => {
  return request(`/api/polygons/`);
};

const getDrawPolygonById = (params: { id: string }) => {
  return request(`/api/polygons/${params.id}`);
};

const insertDrawPolygon = (params: PolygonType) => {
  return request('/api/polygons/', params, 'POST');
};

const updateDrawPolygon = (params: PolygonType, id: number) => {
  return request(`/api/polygons/${id}/`, params, 'PATCH');
};

const removeDrawPolygon = (params: { id: number }) => {
  return request(`/api/polygons/${params.id}/`, {}, 'DELETE');
};

// favorite
const sendingToWork = params => {
  return request(`/api/favorite/`, params, 'POST');
};

const allPolygonsInWork = () => {
  return request(`/api/favorite/`);
};

const removeFavorite = (params: { id: number }) => {
  return request(`/api/favorite/${params.id}/`, {}, 'DELETE');
};

const updateFavorite = (params: PolygonType, id: number) => {
  return request(`/api/favorite/${id}/`, params, 'PATCH');
};

const bulkUpdate = (params: {
  owner?: number;
  square?: number;
  status?: number;
  substatus?: number;
  type_owner?: number;
  category?: number;
  polygons: number[];
}) => {
  return request(`/api/upload/bulk_update/`, params, 'PATCH');
};

const getPolygonsInfo = (params: { categoryIds: number[] }) => {
  return request(`/info_object/?category_ids=${params.categoryIds.join(',')}`);
};

const PolygonsService = {
  updateReestrPolygon,
  updateReestrPolygonEncumbrances,
  getAllReestrPolygons,
  getReestrPolygonById,
  insertReestrPolygon,
  removeReestrPolygon,
  getReestrPolygonAllFilesById,
  getReestrPolygonHistoryById,
  getReestrPolygonByCN,

  getAllDrawPolygons,
  insertDrawPolygon,
  getDrawPolygonById,
  updateDrawPolygon,
  removeDrawPolygon,

  sendingToWork,
  allPolygonsInWork,
  removeFavorite,
  updateFavorite,

  getPolygonsInfo,
  bulkUpdate,
};

export default PolygonsService;
