export const isDevApi = process.env.REACT_APP_BUILD === 'development';
const isProdApi = process.env.REACT_APP_BUILD === 'production';
// export const isDevApi = true;

export const BASE_URL = isDevApi
  ? 'https://cadaster-back-dev.bots.winsolutions.ru'
  : isProdApi
  ? 'https://back.maps.cyberlab23.ru'
  : 'https://cadaster-back-stage.drawbot.winsolutions.ru';

export const BASE_EXCEL_URL = isDevApi
  ? 'https://cadaster-import-dev.bots.winsolutions.ru'
  : isProdApi
  ? 'https://import.maps.cyberlab23.ru'
  : 'https://cadaster-import-stage.drawbot.winsolutions.ru';
