import { FC } from 'react';
import Select, { Props, StylesConfig } from 'react-select';
import { Control, useController } from 'react-hook-form';
import { useAppSelector } from 'src/hooks/useRedux';
import { SelectItemType } from 'src/config/types';

interface SelectNewProps extends Props {
  name: string;
  control: Control<any>;
  required?: boolean;
  handleChange?: (option: SelectItemType) => void;
}

export const SelectNew: FC<SelectNewProps> = ({
  name,
  control,
  required,
  options,
  handleChange,
  ...props
}) => {
  const {
    field: { value, onChange, ...rest },
  } = useController({
    name,
    control,
    rules: { required: !!required },
  });
  const { darkMode } = useAppSelector(state => state.common);

  if (!options?.length) {
    return <></>;
  }

  const colourStyles: StylesConfig = {
    container: styles => ({
      ...styles,
      width: '100%',
    }),
    control: styles => ({
      ...styles,
      backgroundColor: darkMode ? '#607486' : 'white',
      borderColor: 'transparent',
      height: '28px',
      minHeight: '28px',
      boxShadow: 'none',
      ':hover': {
        ...styles[':hover'],
        borderColor: 'transparent',
      },
    }),
    singleValue: styles => ({
      ...styles,
      color: darkMode ? 'white' : 'black',
      fontSize: '12px',
      fontFamily: "'GothamPro Regular', sans-serif",
    }),
    dropdownIndicator: styles => ({
      ...styles,
      padding: '4px',
    }),
    placeholder: styles => ({
      ...styles,
      color: '#8D8D8D',
      fontFamily: "'GothamPro Regular', sans-serif",
      fontSize: '12px',
    }),
    input: styles => ({
      ...styles,
      margin: 0,
      fontSize: '12px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: styles => ({
      ...styles,
      margin: 0,
      backgroundColor: darkMode ? '#607486' : 'white',
    }),
    menuList: styles => ({
      ...styles,
      padding: 0,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: darkMode ? 'white' : 'black',
      fontSize: '12px',
      fontFamily: "'GothamPro Regular', sans-serif",
      backgroundColor: isSelected ? (darkMode ? '#8D8D8D' : '#F3F6FC') : 'transparent',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: darkMode ? '#8D8D8D' : '#F3F6FC',
      },
    }),
  };

  return (
    <Select
      styles={colourStyles}
      options={options}
      value={value ? options.find(x => x?.['value'] === value) : value}
      onChange={option => {
        handleChange && handleChange(option);
        onChange(option ? option.value : option);
      }}
      {...rest}
      {...props}
    />
  );
};
