import { UserType } from 'src/config/types';

const getLocalRefreshToken = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }
  const user = JSON.parse(data);

  return user?.refresh;
};

const getLocalAccessToken = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }

  const user = JSON.parse(data);
  return user?.access;
};

const getLocalTokens = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }

  const user = JSON.parse(data);
  return {
    access: user?.access,
    refresh: user?.refresh,
  };
};

const getUser = () => {
  const data = localStorage.getItem('user');

  if (!data) {
    return null;
  }
  return JSON.parse(data);
};

const setUser = (user: UserType) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const setTokens = tokens => {
  const user = getUser();
  localStorage.setItem('user', JSON.stringify({ ...user, ...tokens }));
};

const removeUser = () => {
  localStorage.setItem('user', '');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalTokens,
  getUser,
  setUser,
  setTokens,
  removeUser,
};

export default TokenService;
