import { FC, Fragment } from 'react';
import { categoryFields, landPlotsFields } from 'src/config/data';
import { useAppSelector } from 'src/hooks/useRedux';
import { getLandPlotValue, getPolygonCategories } from 'src/utils/helpers';
import { AppToolTip } from 'src/components/ui/AppToolTip';
import { PolygonType } from 'src/config/types';
import parse from 'html-react-parser';
import { useWindowDimensions } from 'src/hooks/useWindowDimension';

interface DashboardSingleStatProps {
  polygon: PolygonType;
  classNames?: string;
}

export const DashboardSingleStat: FC<DashboardSingleStatProps> = ({ polygon, classNames }) => {
  const categories = useAppSelector(state => state.categories);
  const { width } = useWindowDimensions();

  const polygonCategory = getPolygonCategories(polygon, categories);

  const statLine = (label: string, value: string) =>
    value ? (
      <div
        key={`stat-line-${label}`}
        className={
          'text-xs t-lg:text-base flex flex-col m-md:flex-row m-md:items-center gap-1 m-md:gap-2 ' +
          'font-gothampro-400 mb-2.5'
        }
      >
        <div className="text-grey-500 m-md:mr-auto">{label}:</div>
        <AppToolTip
          classNames="dark:text-white m-md:text-right truncate"
          width={width >= 520 ? '60%' : '100%'}
          checkWidth
          text={parse(value)}
        />
      </div>
    ) : (
      <Fragment key={`stat-line-${label}`} />
    );

  return (
    <>
      <div className={classNames}>
        {landPlotsFields.map((field, i) => {
          const value = getLandPlotValue(field, polygon.reestrInfo?.feature.attrs);

          if (!value.length || value === ' ' || value === '  ()') {
            return <Fragment key={`object-info-line-${i}`} />;
          }

          if (value.includes('²')) {
            return statLine(field.name, value.replace('²', '<sup>2</sup>'));
          }
          return statLine(field.name, value);
        })}

        {categoryFields.map((el, i) => {
          if (!polygonCategory?.[el.field]) {
            return <Fragment key={`object-info-category-${i}`} />;
          }

          return statLine(el.label, polygonCategory?.[el.field]?.name);
        })}

        {polygon?.master_plan && statLine('Генеральный план', polygon?.master_plan)}
        {polygon?.pzz && statLine('ПЗЗ', polygon?.pzz)}
      </div>
    </>
  );
};
