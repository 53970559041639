import { fetchAllInfo, fetchEncumbranceType } from 'src/store/categories/actions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoriesState } from 'src/store/categories/types';
import { CategoryType, StatusType } from 'src/config/types';

const initialState: CategoriesState = {
  statuses: [],
  blockStatuses: [],
  categories: [],
  owners: [],
  typeOwners: [],
  encumbranceType: [],
  isLoadingAllInfo: false,
  error: '',
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<CategoryType[]>) => {
      state.categories = action.payload;
    },
  },
  extraReducers: {
    [fetchAllInfo.pending.type]: state => {
      state.isLoadingAllInfo = true;
      state.statuses = [];
      state.blockStatuses = [];
      state.categories = [];
      state.owners = [];
      state.typeOwners = [];
    },
    [fetchAllInfo.fulfilled.type]: (
      state,
      action: PayloadAction<{
        widthIcon: boolean;
        data: {
          categories: StatusType[];
          statuses: StatusType[];
          block_statuses: StatusType[];
          owners: StatusType[];
          type_owner: StatusType[];
        };
      }>,
    ) => {
      state.isLoadingAllInfo = false;
      state.statuses = action.payload.data.statuses;
      state.blockStatuses = action.payload.data.block_statuses;
      state.categories = !action.payload.widthIcon
        ? action.payload.data.categories.map(el => ({
            ...el,
            color: undefined,
          }))
        : action.payload.data.categories;
      state.owners = action.payload.data.owners;
      state.typeOwners = action.payload.data.type_owner;
      state.error = '';
    },
    [fetchAllInfo.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingAllInfo = false;
      state.error = action.payload;
    },

    [fetchEncumbranceType.pending.type]: state => {
      state.encumbranceType = [];
    },
    [fetchEncumbranceType.fulfilled.type]: (
      state,
      action: PayloadAction<{ id: number; name: string }[]>,
    ) => {
      state.encumbranceType = action.payload;
      state.error = '';
    },
    [fetchEncumbranceType.rejected.type]: (state, action: PayloadAction<string>) => {
      state.encumbranceType = [];
      state.error = action.payload;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

const reducer = categoriesSlice.reducer;
export default reducer;
