import { createSelector } from 'reselect';
import { PolygonType } from 'src/config/types';

const reestrPolygons = state => state.polygons.reestrPolygons;
const drawPolygons = state => state.polygons.drawPolygons;
const filters = state => state.filters.polygonsFilter;
const lassoFilter = state => state.filters.lassoFilter;
const listSearch = state => state.filters.listSearch;

export const getFilteredPolygons = createSelector(
  [reestrPolygons, drawPolygons, filters, lassoFilter],
  (reestrPolygons, drawPolygons, category, lassoFilter) => {
    const polygons: PolygonType[] = [
      ...(lassoFilter?.polygonIds?.length ? [] : drawPolygons),
      ...reestrPolygons,
    ];

    const { categories, blockStatuses, statuses, subStatuses, typeOwners, owners } = category;

    if (
      !categories?.length &&
      !blockStatuses?.length &&
      !statuses?.length &&
      !subStatuses?.length &&
      !typeOwners?.length &&
      !owners?.length
    ) {
      return polygons;
    }

    return polygons.filter(
      polygon =>
        (statuses?.length ? statuses?.includes(polygon?.properties?.status) : true) &&
        (blockStatuses?.length
          ? blockStatuses?.includes(polygon?.properties?.block_status)
          : true) &&
        (categories?.length ? categories?.includes(polygon?.properties?.category) : true) &&
        (subStatuses?.length ? subStatuses?.includes(polygon?.properties?.substatus) : true) &&
        (typeOwners?.length ? typeOwners?.includes(polygon?.properties?.type_owner) : true) &&
        (owners?.length ? owners?.includes(polygon?.properties?.owner) : true),
    );
  },
);

export const getFilteredDrawPolygons = createSelector(
  [drawPolygons, filters, lassoFilter, listSearch],
  (drawPolygons, category, lassoFilter, listSearch) => {
    if (lassoFilter?.polygonIds?.length) {
      return drawPolygons;
    }

    const { categories, blockStatuses, statuses, subStatuses, typeOwners, owners } = category;

    if (
      !categories?.length &&
      !blockStatuses?.length &&
      !statuses?.length &&
      !subStatuses?.length &&
      !typeOwners?.length &&
      !owners?.length &&
      !lassoFilter?.polygonIds?.length &&
      !listSearch
    ) {
      return drawPolygons;
    }

    return drawPolygons.filter(
      (polygon: PolygonType) =>
        (statuses?.length ? statuses?.includes(polygon?.properties?.status) : true) &&
        (blockStatuses?.length
          ? blockStatuses?.includes(polygon?.properties?.block_status)
          : true) &&
        (categories?.length ? categories?.includes(polygon?.properties?.category) : true) &&
        (subStatuses?.length ? subStatuses?.includes(polygon?.properties?.substatus) : true) &&
        (typeOwners?.length ? typeOwners?.includes(polygon?.properties?.type_owner) : true) &&
        (owners?.length ? owners?.includes(polygon?.properties?.owner) : true) &&
        (lassoFilter?.polygonIds?.length ? owners?.includes(polygon.id) : true) &&
        (listSearch
          ? polygon?.properties?.name?.toLowerCase()?.includes(listSearch.toLowerCase())
          : true) &&
        (listSearch
          ? polygon?.properties?.cadaster_number?.toLowerCase()?.includes(listSearch.toLowerCase())
          : true),
    );
  },
);
