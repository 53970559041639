import { FC } from 'react';
import { Button } from 'src/components/ui/Button';
import { download } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setImportExcelData } from 'src/store/common';
import { BASE_EXCEL_URL } from 'src/config';

import cn from 'classnames';
import ExcelService from 'src/services/ExcelService';

export const ImportExcelStatus: FC = () => {
  const dispatch = useAppDispatch();

  const { importExcelData } = useAppSelector(state => state.common);

  if (!importExcelData) {
    return null;
  }

  const { total, success, decline, url, statusId } = importExcelData;

  const handelDownload = () => download(`${BASE_EXCEL_URL}/files/${url}`, 'excel.xlsx');

  const handleClose = async () => {
    if (!statusId) return;

    dispatch(setImportExcelData(null));
    await ExcelService.importExcelSetShown(statusId);
  };

  return (
    <div
      className={cn(
        'top-16 t-lg:top-20 right-0 t-lg:right-8 w-80 m-md:w-96 p-4 fixed rounded bg-white ' +
          'dark:bg-blue-800 flex flex-col gap-4 dark:text-white font-gothampro-500 shadow-md',
      )}
    >
      <div className="flex items-center justify-between">
        <div>Загрузилось</div>
        <div>{`${success} из ${total}`}</div>
      </div>

      {decline ? (
        <div className="flex items-center justify-between">
          <div>Не загрузилось</div>
          <div>{`${decline} из ${total}`}</div>
        </div>
      ) : null}

      {url ? (
        <div className="cursor-pointer text-blue-100 underline" onClick={handelDownload}>
          Скачать файл ошибок
        </div>
      ) : null}

      <Button variant="blue" size="big" onClick={handleClose}>
        Закрыть
      </Button>
    </div>
  );
};
