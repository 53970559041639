import { BASE_URL } from 'src/config';
import axios from 'axios';
import TokenService from 'src/services/TokenService';
import AuthService from 'src/services/AuthService';

export const Api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  // @ts-ignore
  retryCount: 5,
  retryDelay: 1,
});

Api.interceptors.request.use(
  config => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (err?.response?.status === 401 && originalConfig.url.includes('api-auth/token/refresh')) {
      TokenService.removeUser();
      window.location.href = '/';
    }

    if (!originalConfig.url.includes('api-auth') && err.response) {
      if (err.response.status === 401 && !originalConfig?._retry) {
        originalConfig._retry = true;

        try {
          const res = await AuthService.refresh();
          const refresh = TokenService.getLocalRefreshToken();

          // @ts-ignore
          TokenService.setTokens({ access: res.data.access, refresh });
          return Api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    // if (err.response.status === 500) {
    //   originalConfig._retry = true;
    //   originalConfig.retryCount -= 1;
    //
    //   if (!originalConfig.retryCount) {
    //     return Promise.reject(err);
    //   }
    //
    //   try {
    //     await sleep(1000);
    //     return Api(originalConfig);
    //   } catch (_error) {
    //     return Promise.reject(_error);
    //   }
    // }

    return Promise.reject(err);
  },
);
