import { FC, ReactNode, useRef } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

interface ModalProps {
  onClose?: () => void;
  children?: ReactNode;
}

export const Modal: FC<ModalProps> = ({ onClose, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(ref, onClose);

  return (
    <div
      className={
        'fixed z-10000 inset-0 w-full h-full flex items-center justify-center ' +
        'bg-white-rgba-200 dark:bg-black-rgba-100'
      }
    >
      <div
        className={
          'w-full max-w-[80rem] bg-white dark:bg-blue-300 py-10 d-xl:px-32 mx-2 t-lg:px-8 ' +
          'px-3 rounded-xl relative'
        }
        ref={ref}
      >
        {onClose && (
          <div className="w-5 h-5 absolute top-4 right-4 cursor-pointer" onClick={onClose}>
            <CloseIcon className="fill-white" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
