import React, { useEffect, useState, useRef } from 'react';
import { useAppSelector } from 'src/hooks/useRedux';
import { useReactToPrint } from 'react-to-print';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import cn from 'classnames';

import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';

export const Screen = function ({ setScrIcon }) {
  const { isMobileButtonsOpened } = useAppSelector(state => state.map);

  const [pngSize, setPngSize] = useState<boolean>(true);
  const [screen, setScreen] = useState(<></>);

  const list = ['png', 'jpeg', 'pdf'];

  const componentRef = useRef(null);

  useEffect(() => {
    !isMobileButtonsOpened ? setScrIcon(false) : setScrIcon(true);
  }, [isMobileButtonsOpened]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    window.innerWidth > window.innerHeight ? setPngSize(true) : setPngSize(false);
  }, []);

  useEffect(() => {
    setScreen(<></>);
    const leafletButtons = document.querySelector('.leaflet-control-container');

    if (!leafletButtons) return;

    leafletButtons.className = cn({ hidden: isMobileButtonsOpened }, 'leaflet-control-container');
    const element = document.getElementById('screen');

    if (element) {
      domtoimage
        .toPng(element)
        .then(dataUrl => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'screenshot.png';
          document.body.appendChild(link);
          const scrImg = (
            <img width={'100%'} ref={componentRef} id={'screenImg'} src={dataUrl} alt="" />
          );

          const pdf = new jsPDF(pngSize ? 'landscape' : 'portrait');
          pdf.addImage(
            dataUrl,
            'PNG',
            !pngSize ? (210 - window.innerWidth / 4) / 2 : (297 - window.innerWidth / 8) / 2,
            pngSize ? (210 - window.innerHeight / 8) / 2 : (297 - window.innerHeight / 4) / 2,
            !pngSize ? window.innerWidth / 4 : window.innerWidth / 8,
            !pngSize ? window.innerHeight / 4 : window.innerHeight / 8,
          );

          setScreen(
            <div id="screenPage" className="w-[60vw] h-auto absolute">
              {scrImg}
              <div className="absolute top-12 bg-white text-black w-64 rounded shadow-blacks-100">
                <div className="px-4 py-2.5 text-xs font-gothampro-400 border-b border-grey-1100">
                  Выбрать тип файла
                </div>
                {list.map(el => (
                  <div
                    onClick={e => {
                      const x = link.download.split('.').shift();
                      link.download = x + '.' + el;
                      const target = e.currentTarget as HTMLDivElement;

                      if (target.className.includes('active')) return;

                      const items = document.querySelectorAll('.screen-item');

                      items.forEach(item => item.classList.remove('active'));
                      target.classList.add('active');
                    }}
                    className={cn(
                      { active: el === 'png' },
                      'px-4 py-2.5 flex items-center cursor-pointer hover:bg-blue-1500 screen-item',
                    )}
                    key={`screen-item-${el}`}
                  >
                    <div className="text-xs font-gothampro-400">{el.toUpperCase()}</div>
                    <div
                      className={cn('w-5 h-5 rounded border border-blue-100 ml-auto center icon')}
                    >
                      <CheckIcon className="w-4 h-4 transition-full fill-white opacity-0 check" />
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={() => {
                  link.download === 'screenshot.pdf' ? pdf.save('screenshot.pdf') : link.click();
                  setScreen(<></>);
                  leafletButtons.className = cn(
                    { hidden: !isMobileButtonsOpened },
                    'leaflet-control-container',
                  );
                }}
                className={'absolute top-0 p-2 bg-blue-100 text-white rounded   scr_Btn'}
              >
                Сохранить
              </button>
              <button
                onClick={handlePrint}
                className={
                  'absolute top-0 left-6  p-2 bg-blue-100 text-white rounded ml-20 scr_Btn'
                }
              >
                Печать
              </button>
              <CloseIcon
                onClick={() => {
                  setScreen(<></>);
                  leafletButtons.className = cn(
                    { hidden: !isMobileButtonsOpened },
                    'leaflet-control-container',
                  );
                }}
                className="w-6 h-6 p-1.5 rounded-full bg-white fill-gray-500 border-[1px] border-gray-400  absolute top-2 right-2 cursor-pointer hover:bg-blue-300"
              />
            </div>,
          );
        })
        .catch(error => {
          console.error('Error capturing screenshot:', error);
        });
    }
  }, []);

  return <>{screen}</>;
};
