import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { DropBlock } from 'src/components/DropBlock';
import { Zones } from 'src/components/Zones';
import { DropBlockSwipe } from 'src/components/DropBlockSwipe';
import { ObjectInfo } from 'src/components/ObjectInfo';
import { useAuth } from 'src/hooks/useAuth';
import { setOpenedDropBlock } from 'src/store/common';
import { ZonesFavorite } from 'src/components/ZonesFavorite';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { getFilteredDrawPolygons } from 'src/selectors/polygonSelectors';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import cn from 'classnames';

interface MapDropBlocksProp {
  lastClicked: number;
  setLastClicked: Dispatch<SetStateAction<number>>;
}

export const MapDropBlocks: FC<MapDropBlocksProp> = ({ lastClicked, setLastClicked }) => {
  const { openedDropBlocks, isFavouritesActive } = useAppSelector(state => state.common);
  const {
    filteredPolygonsCount,
    favoritePolygons,
    isLoadingFavoritePolygons,
    isLoadingReestrPolygons,
    isLoadingDrawPolygons,
  } = useAppSelector(state => state.polygons);
  const filteredDrawPolygons = useAppSelector(state => getFilteredDrawPolygons(state));

  const { user } = useAuth();
  const dispatch = useAppDispatch();

  const isInfoOpen = useMemo(() => Boolean(openedDropBlocks?.['info']), [openedDropBlocks]);

  const handelCloseInfo = () => dispatch(setOpenedDropBlock({ ...openedDropBlocks, info: false }));

  return (
    <>
      <div
        className={cn(
          {
            'z-2100': lastClicked !== 1,
            'z-2200': lastClicked === 1,
          },
          'absolute top-0 left-0 w-80 shadow-blacks-200 hidden t-lg:block rounded-b-md overflow-hidden',
        )}
      >
        <DropBlock
          onClick={() => setLastClicked(1)}
          classNames="bg-orange-100"
          wrapperClassNames="w-full"
          title="Объекты"
          name="zones"
          countLoading={isLoadingReestrPolygons || isLoadingDrawPolygons}
          count={filteredPolygonsCount + (filteredDrawPolygons?.length || 0)}
        >
          <Zones />
        </DropBlock>

        {isFavouritesActive && !user?.isUser ? (
          <DropBlock
            onClick={() => setLastClicked(1)}
            classNames="bg-yellow-100"
            wrapperClassNames="w-full"
            title="Необработанные объекты"
            name="new-zones"
            countLoading={isLoadingFavoritePolygons}
            count={favoritePolygons?.length || 0}
          >
            <ZonesFavorite />
          </DropBlock>
        ) : null}
      </div>

      <>
        <div
          className={cn(
            {
              'rounded-b-md right-0': !isInfoOpen,
              'right-8 w-[21rem] d-xs:w-[30rem] d-lg:w-[36rem]': isInfoOpen,
            },
            'hidden t-lg:flex cursor-pointer h-11 rounded-t-md bg-blue-100 px-5',
            'absolute top-0 z-2000 transition-full flex items-center',
          )}
          onClick={() => dispatch(setOpenedDropBlock({ info: !openedDropBlocks?.['info'] }))}
        >
          <div className="text-white font-gothampro-600">Информация об объекте</div>
          {isInfoOpen ? (
            <CloseIcon className="w-4 h-4 fill-white ml-auto" onClick={handelCloseInfo} />
          ) : null}
        </div>

        <DropBlockSwipe
          wrapperClassNames={cn(
            {
              'z-2100': lastClicked !== 2,
              'z-2200': lastClicked === 2,
            },
            'hidden t-lg:block',
          )}
          name="info"
          onClick={() => setLastClicked(2)}
        >
          <ObjectInfo />
        </DropBlockSwipe>
      </>
    </>
  );
};
