import { FC, Fragment } from 'react';
import { useLocation } from 'react-router';
import { protectedRoutes } from 'src/routes';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/useRedux';
import { RouteType } from 'src/config/types';
import { AppToolTip } from 'src/components/ui/AppToolTip';

import { ReactComponent as MapSchemaIcon } from 'src/assets/icons/kit/map-schema.svg';
import { ReactComponent as ListIcon } from 'src/assets/icons/kit/list.svg';
import cn from 'classnames';

export const Navigation: FC = () => {
  const { selectedPolygon } = useAppSelector(state => state.polygons);

  const location = useLocation();

  const getIcon = (route: RouteType, isActive: boolean) => {
    const iconsClassName = cn(
      { 'fill-white': isActive, 'fill-grey-200 dark:fill-blue-300': !isActive },
      't-lg:w-4 t-lg:h-4 w-3 h-3 transition-full group-hover:fill-white',
    );

    const wrapperClassName = cn(
      {
        'bg-orange-300 shadow-oranges-100': isActive,
        'bg-grey-1100 dark:bg-blue-200 hover:bg-orange-300': !isActive,
      },
      'cursor-pointer group t-lg:w-9 t-lg:h-9 t-lg:min-w-[2rem] rounded center ' +
        'transition-full w-7 h-7 min-w-[1.5rem]',
    );

    const icons = {
      map: (
        <AppToolTip text="Карта">
          <div className={wrapperClassName}>
            <MapSchemaIcon className={iconsClassName} />
          </div>
        </AppToolTip>
      ),
      list: (
        <AppToolTip text="Таблица">
          <div className={wrapperClassName}>
            <ListIcon className={iconsClassName} />
          </div>
        </AppToolTip>
      ),
      user: <></>,
    };

    return route.icon ? icons[route.icon] : '';
  };

  return (
    <>
      {protectedRoutes.map(route => {
        if (!route?.icon) {
          return <Fragment key={`protected-route-${route.path}`} />;
        }

        const isActive = route.path === location.pathname;

        return (
          <Link
            to={`${route.path}${
              selectedPolygon?.cadaster_number ? `?cn=${selectedPolygon.cadaster_number}` : ''
            }`}
            key={`protected-route-${route.path}`}
            className={cn({ 't-lg:hidden': route.path === '/user' }, 'ml-2 t-lg:ml-0 t-lg:mb-2')}
          >
            {getIcon(route, isActive)}
          </Link>
        );
      })}
    </>
  );
};
