import { FC } from 'react';

import { ReactComponent as CheckIcon } from 'src/assets/icons/kit/check.svg';
import cn from 'classnames';

interface CheckBoxProps {
  isActive: boolean;
  onClick?: () => void;
}

export const CheckBox: FC<CheckBoxProps> = ({ isActive, onClick }) => {
  const handleClick = e => {
    if (onClick) {
      e?.preventDefault();
      e?.stopPropagation();

      onClick && onClick();
    }
  };

  return (
    <div
      className={cn(
        {
          'border-blue-100 bg-blue-100': isActive,
          'border-blue-100 dark:border-blue-900 bg-white-200 dark:bg-blue-1200': !isActive,
        },
        'min-w-[1rem] w-4 h-4 t-lg:min-w-[1.25rem] t-lg:w-5 t-lg:h-5 rounded border center ' +
          'cursor-pointer',
      )}
      onClick={handleClick}
    >
      <CheckIcon
        className={cn(
          { 'opacity-0': !isActive, 'opacity-1': isActive },
          'w-2.5 h-2.5 t-lg:w-3 t-lg:h-3 fill-white',
        )}
      />
    </div>
  );
};
