import { ExcelExportType } from 'src/config/types';
import { ApiExcel } from 'src/services/Api/ApiExcel';

const checkImportLoad = () => {
  return ApiExcel.get(`/import`);
};

const checkExportLoad = () => {
  return ApiExcel.get(`/export`);
};

const importExcel = (params: FormData) => {
  return ApiExcel.post(`/import`, params, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const importExcelSetShown = (statusId: number) => {
  return ApiExcel.post(`/import/shown`, { statusId });
};

const exportExcel = (params: ExcelExportType) => {
  return ApiExcel.post(`/export`, params);
};

const ExcelService = {
  checkImportLoad,
  checkExportLoad,
  importExcel,
  importExcelSetShown,
  exportExcel,
};

export default ExcelService;
