import { CategoryType } from 'src/config/types';
import { request } from 'src/services/Api/Fetch';

const insertStatuses = (params: CategoryType[]) => {
  return request('/api/categories/', params, 'POST');
};

const insertOwners = (params: CategoryType[]) => {
  return request('/api/owner/', params, 'POST');
};

const getAllInfo = () => {
  return request('/all_info/');
};

const getEncumbranceType = () => {
  return request('/api/encumbrance_type/');
};

const CategoriesService = {
  getAllInfo,
  insertStatuses,
  insertOwners,
  getEncumbranceType,
};

export default CategoriesService;
