import { Fragment } from 'react';
import { calculateStatusesCount, handleSelectPolygon } from 'src/utils/helpers';
import { stringToCoords } from 'src/utils';
import { PolygonType, StatusType } from 'src/config/types';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import MarkerIcon from 'src/assets/icons/marker.svg';

const getStatusNode = (status: StatusType, count?: number) =>
  !count
    ? ''
    : `<div class='flex items-center gap-1'><div class='w-3 h-3' style='background-color: ${status.color}'></div><div class='font-gothampro-400 text-extra-xs whitespace-nowrap'>${status.name} - ${count}</div></div>`;

export const createClusterCustomIcon = function (
  cluster,
  statuses: StatusType[],
  blockStatuses: StatusType[],
) {
  const checkOnDom = document.querySelector(`#html-${cluster._leaflet_id}`);

  if (checkOnDom) {
    return L.divIcon({
      className: 'hover-z-index',
      html: checkOnDom as HTMLElement,
    });
  }

  const statusesCount: { [key: number]: number } = calculateStatusesCount(cluster, 'status');
  const blockStatusesCount: { [key: number]: number } = calculateStatusesCount(
    cluster,
    'blockStatus',
  );
  const statusesList: string[] = [...statuses].map(status =>
    getStatusNode(status, statusesCount?.[status.id]),
  );

  const blockStatusesList: string[] = [...blockStatuses]
    .map(blockStatus => getStatusNode(blockStatus, blockStatusesCount?.[blockStatus.id]))
    .filter(Boolean);

  const statusCount: number[] = [];
  const statusBbg: string[] = [];

  statuses.forEach(({ id, color }) => {
    if (!statusesCount?.[id]) return;
    statusCount.push(statusesCount[id]);
    if (color) {
      statusBbg.push(color);
    }
  });

  const blockStatusCount: number[] = [];
  const blockStatusBg: string[] = [];

  blockStatuses.forEach(({ id, color }) => {
    if (!blockStatusesCount?.[id]) return;
    blockStatusCount.push(blockStatusesCount[id]);
    if (color) {
      blockStatusBg.push(color);
    }
  });

  const html = `<div id='html-${
    cluster._leaflet_id
  }' class='my-canvas group t-lg:w-16 t-lg:h-16 w-10 h-10 rounded-full bg-white center font-gothampro-500 text-extra-xs t-lg:text-xs relative'>
                      <canvas id='status-${cluster._leaflet_id}' data-count='${statusCount.join(
    '-',
  )}' data-bg='${statusBbg.join('-')}' class='absolute status-charts-canvas'></canvas>
                       <div class='block-status-charts-canvas-wrapper'><canvas id='block-status-${
                         cluster._leaflet_id
                       }' data-count='${blockStatusCount.join('-')}' data-bg='${blockStatusBg.join(
    '-',
  )}' class='block-status-charts-canvas'></canvas></div>
                      <div class='z-120 relative ${
                        blockStatusesList?.length
                          ? 'w-3/4 h-3/4 t-lg:w-1/2 t-lg:h-1/2'
                          : 't-lg:w-4/6 t-lg:h-4/6 w-full h-full'
                      } center bg-white rounded-full'>${cluster.getChildCount()}</div>
                      <div class='z-500 absolute hidden group-hover:flex p-2.5 t-lg:left-16 left-10 top-0 bg-white rounded shadow-blacks-400 flex-col gap-1'>
                        <div class='absolute -left-0.5 w-2 h-2 bg-white rotate-45' style='top: calc(50% - 4px)'></div>
                        <div class='flex flex-col gap-0.5'><div class='font-gothampro-500 text-extra-xs'>Статусы</div>${statusesList.join(
                          '',
                        )}</div>
                        ${
                          blockStatusesList?.length
                            ? `<div class='flex flex-col gap-0.5 mt-1'>
                              <div class='font-gothampro-500 text-extra-xs'>Направления</div>
                              ${blockStatusesList.join('')}
                            </div>`
                            : ''
                        }
                      </div>
                    </div>
                   `;

  return L.divIcon({
    className: 'hover-z-index',
    html,
  });
};

const customIcon = (polygon: PolygonType) =>
  new L.Icon({
    iconUrl: MarkerIcon,
    iconRetinaUrl: MarkerIcon,
    popupAnchor: [0, -40],
    iconAnchor: [16, 40],
    iconSize: [32, 40],
    status: polygon?.properties?.status,
    ...(polygon?.properties?.block_status && { blockStatus: polygon?.properties?.block_status }),
    className: `marker-${polygon?.properties?.cadaster_number ? 'reestr' : 'draw'}-${polygon.id}`,
  });

export const renderMarker = (
  polygon: PolygonType,
  width: number,
  i: number,
  dispatch,
  lassoFilter,
) => {
  if (
    lassoFilter?.positions?.length &&
    !(lassoFilter?.polygonIds || []).includes(polygon?.id || 0)
  ) {
    return <Fragment key={`marker-${i}`} />;
  }

  return (
    <Marker
      eventHandlers={{
        click: async e => {
          e.originalEvent.preventDefault();
          await handleSelectPolygon(polygon, width, dispatch);
        },
      }}
      key={`marker-${i}`}
      position={stringToCoords(polygon?.properties?.center || '')}
      icon={customIcon(polygon)}
    />
  );
};
