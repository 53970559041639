import { createAsyncThunk } from '@reduxjs/toolkit';
import ReestrService from 'src/services/ReestrService';

export const fetchReestrList = createAsyncThunk(
  'reestr/fetchList',
  async (text: string, thunkAPI) => {
    try {
      return await ReestrService.getReestrList({ text });
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить список из реестра');
    }
  },
);

export const fetchSearchAddressList = createAsyncThunk(
  'reestr/fetchAddressList',
  async (text: string, thunkAPI) => {
    try {
      const response = await ReestrService.searchInfo({ text });

      if (response?.results) {
        return response;
      }

      if (response?.features?.[0]?.attrs?.cn) {
        const cn = response.features[0].attrs.cn;

        return {
          results: [
            {
              value: cn,
              title: cn,
            },
          ],
        };
      }

      return { results: [] };
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить список адресов');
    }
  },
);
