import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setIsLoadingUser, setUser } from 'src/store/common';
import { resetPolygons } from 'src/store/polygons';
import { LoginType, UserType } from 'src/config/types';
import { ROLES } from 'src/config/enums';
import TokenService from 'src/services/TokenService';
import AuthService from 'src/services/AuthService';

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(state => state.common);

  const startLoad = () => dispatch(setIsLoadingUser(true));
  const endLoad = () => dispatch(setIsLoadingUser(false));

  const getUser = async () => {
    try {
      startLoad();
      const curUser = TokenService.getUser();
      const user = await AuthService.getUser();
      if (!user) {
        endLoad();
        return;
      }

      handleSetUser({ ...curUser, ...user });
    } catch (e) {
      console.log(e.message);
      if (e.retry) {
        await getUser();
      }
    }
  };

  const login = async (params: LoginType) => {
    try {
      startLoad();

      const tokens = await AuthService.login(params);

      if (!tokens.refresh || !tokens.access) {
        endLoad();
        return;
      }

      TokenService.setTokens(tokens);

      const user = await AuthService.getUser();
      if (!user) {
        endLoad();
        return;
      }

      handleSetUser({ ...tokens, ...user });
      navigate('/map');
    } catch (e) {
      console.error('Login error:', e.message);
    }
  };

  const logout = async () => {
    try {
      startLoad();
      await AuthService.logout();
    } catch (e) {
      console.error('Logout error:', e.message);
    } finally {
      dispatch(resetPolygons());
      TokenService.removeUser();
      handleSetUser(null);
      navigate('/');
    }
  };

  const handleSetUser = (user: UserType | null) => {
    if (!user) {
      TokenService.removeUser();
      dispatch(setUser(null));
      endLoad();
      return;
    }

    const newUser: UserType = {
      ...user,
      isUser: user.groups.includes(ROLES.USER),
      isLeader: user.groups.includes(ROLES.LEADER),
      isManager: user.groups.includes(ROLES.MANAGER),
    };
    TokenService.setUser(newUser);
    dispatch(setUser(newUser));
    endLoad();
  };
  return { login, logout, getUser, user };
};
