import { ChangeEvent, FC, useRef, useState } from 'react';
import { InputType } from 'src/config/types';
import { FieldErrors, RegisterOptions } from 'react-hook-form';

import { ReactComponent as DatePickerIcon } from 'src/assets/icons/kit/date-picker.svg';

import cn from 'classnames';
import { DatePicker } from 'src/components/ui/DatePicker';

interface InputProps {
  data?: InputType;
  errors?: FieldErrors;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onDateChange?: (date: string) => void;
  register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  classNames?: string;
  registerOptions?: RegisterOptions;
  disabled?: boolean;
  defaultValue?: string;
  size?: 'big' | 'small' | 'extraSmall';
  type?: 'input' | 'password' | 'number' | 'text' | 'date';
  autoComplete?: 'on' | 'off';
}

export const Input: FC<InputProps> = ({
  data,
  registerOptions,
  errors,
  value,
  register,
  onChange,
  onDateChange,
  classNames,
  disabled,
  defaultValue,
  size = 'small',
  type = 'input',
  autoComplete,
}) => {
  const [date, setDate] = useState<string>('');

  const ref = useRef<HTMLDivElement>(null);
  const isError = !!errors?.[data?.name || ''];
  const error = errors?.[data?.name || '']?.message || '';

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputClassName = cn(
    {
      'border-pink-200': isError,
      'px-3 h-7 text-xs': size === 'small',
      'px-2 h-5 text-ultra-xs': size === 'extraSmall',
      'px-4 h-11 text-xs': size === 'big',
    },
    'w-full font-gothampro-400 rounded border border-grey-1700 placeholder-grey-200',
    'dark:bg-blue-200 dark:border-blue-200 dark:text-white bg-grey-1600',
  );

  return (
    <div className={cn('flex flex-col gap-1 w-full relative', classNames)} ref={ref}>
      <input
        placeholder={data?.placeholder}
        defaultValue={defaultValue}
        type={type}
        value={type === 'date' ? date : value}
        {...(onChange && { onChange: onChange })}
        {...(register &&
          register(data?.name || '', {
            required: data?.required,
            ...registerOptions,
          }))}
        disabled={disabled || type === 'date'}
        autoComplete={autoComplete}
        className={inputClassName}
      />
      {isError ? (
        <div className="font-gothampro-400 text-pink-200 absolute -bottom-3.5 text-extra-xs">
          {(error as string) || 'Поле не может быть пустым!'}
        </div>
      ) : null}

      {type === 'date' && (
        <DatePickerIcon
          className="w-4 h-4 fill-white absolute top-1.5 right-2 cursor-pointer"
          onClick={() => setIsOpen(prev => !prev)}
        />
      )}

      {isOpen && (
        <div className="absolute top-full right-0 w-80 z-120">
          <DatePicker
            onClose={() => setIsOpen(false)}
            markedDate={value ? new Date(value) : new Date()}
            onClick={date => {
              setIsOpen(false);

              const temp = `${date.getFullYear()}-${date.getMonth() < 10 ? 0 : ''}${
                date.getMonth() + 1
              }-${date.getDate() < 10 ? 0 : ''}${date.getDate()}`;
              setDate(temp);
              onDateChange && onDateChange(temp);
            }}
          />
        </div>
      )}
    </div>
  );
};
