import { Piechart } from 'src/utils/piechart';
import { addDefs } from 'src/utils/helpers';
import { StatusType } from 'src/config/types';

export const pieChartObserver = () => {
  const markerPane = document.querySelector('.leaflet-marker-pane');

  if (!markerPane) return;

  const markersObserver = new MutationObserver(mutations => {
    const childList = mutations?.[0]?.target?.childNodes;

    childList?.forEach(child => {
      if (child?.['tagName'] !== 'DIV') return;

      const canvases = (child as HTMLDivElement)?.querySelectorAll('canvas');

      if (!canvases.length) return;
      canvases.forEach(canvas => {
        const dataCount = canvas.getAttribute('data-count');
        const dataBg = canvas.getAttribute('data-bg');

        if (!dataBg || !dataCount) return;

        canvas.removeAttribute('data-bg');
        canvas.removeAttribute('data-count');

        const myPiechart = new Piechart({
          canvas: canvas,
          data: dataCount.split('-'),
          colors: dataBg.split('-'),
        });
        myPiechart.draw();
      });
    });
  });

  markersObserver.observe(markerPane, {
    subtree: true,
    childList: true,
  });
};

export const mapDefsObserver = (statuses: StatusType[]) => {
  addDefs(statuses);

  const map = document.querySelector('.my-map');

  if (!map) return;

  const observer = new MutationObserver(() => {
    addDefs(statuses);
  });

  observer.observe(map, {
    subtree: true,
    childList: true,
  });
};
