import { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { setSelectedLayers } from 'src/store/map';
import { layersList } from 'src/config/data';

import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import cn from 'classnames';

interface ActiveLayersProps {
  classNames?: string;
}

export const ActiveLayers: FC<ActiveLayersProps> = ({ classNames }) => {
  const { selectedLayers } = useAppSelector(state => state.map);
  const dispatch = useAppDispatch();

  const layersToShowList = useMemo(
    () => layersList.filter(layer => selectedLayers.find(layerId => layerId === layer.id)),
    [selectedLayers],
  );

  if (!layersToShowList.length) return null;

  const handleRemoveLayer = id =>
    dispatch(setSelectedLayers(selectedLayers.filter(el => el !== id)));

  const handleResetLayers = () => dispatch(setSelectedLayers([]));

  return (
    <div
      id="layers-container"
      className={cn('flex gap-2 px-2.5 d-xl:px-8 pb-3 flex-wrap', classNames)}
    >
      {layersToShowList.map((layer, i) => (
        <div
          key={`layer-on-head-${i}`}
          className={
            'flex gap-1 items-center border py-1 px-2 rounded border-blue-100 hover:bg-blue-100 ' +
            'dark:border-orange-100 relative group dark:hover:bg-orange-100'
          }
        >
          <div
            className={
              'font-gothampro-500 text-extra-xs text-blue-100 dark:text-orange-100 ' +
              'truncate max-w-[8rem] group-hover:text-white'
            }
          >
            {layer.label}
          </div>
          <CloseIcon
            className={
              'fill-blue-100 dark:fill-orange-100 w-2 h-2 group-hover:fill-white cursor-pointer'
            }
            onClick={() => handleRemoveLayer(layer.id)}
          />

          {layer.layerInfo?.length ? (
            <div
              className={
                'hidden t-lg:group-hover:flex flex-col gap-2 absolute top-8 p-2.5 rounded-xl ' +
                'max-w-[12rem] bg-white w-max shadow-blacks-400 dark:bg-blue-200 z-2000'
              }
            >
              <div className="absolute -top-1.5 w-full left-0 flex justify-center">
                <div className="w-3 h-3 rotate-45 bg-white dark:bg-blue-200" />
              </div>

              {layer.layerInfo.map((el, z) => (
                <div key={`layer-info-item-${i}-${z}`} className="flex gap-1">
                  <div
                    className="w-4 h-4 min-w-[1rem] border"
                    style={{
                      borderColor: el?.borderColor || 'transparent',
                      backgroundColor: el?.color || 'transparent',
                    }}
                  />
                  <div
                    className={
                      'text-black-100 dark:text-white font-gothampro-400 text-extra-xs mt-0.5'
                    }
                  >
                    {el.name}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}

      <div
        className={
          'flex gap-1 items-center border py-1 px-2 rounded border-blue-100 bg-blue-100 ' +
          'relative dark:bg-orange-100 cursor-pointer dark:border-orange-100'
        }
        onClick={handleResetLayers}
      >
        <div className="font-gothampro-500 text-extra-xs truncate max-w-[8rem] text-white">
          Очистить все слои
        </div>
      </div>
    </div>
  );
};
